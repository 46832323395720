<template>
  <div>
    <mailSend />
  </div>
</template>

<script>
import mailSend from '../components/mailSend.vue';

export default {
  components: {
    mailSend
  }
}
</script>