import httpClient from '@/common/http-client';

const selectPrdUnitInfoList = (params) => {
  return httpClient.post('/api/public/main/selectPrdUnitInfoList', params, { headers: {"show-layer": "Yes"} });
};
const selectCodeList = (params) => {
  return httpClient.post('/api/public/main/selectCodeList', params, { headers: {"show-layer": "Yes"} });
};
const noticeSearch = (params) => {
  return httpClient.post('/api/public/main/noticeSearch', params, { headers: {"show-layer": "Yes"} });
};
const faqSearch = (params) => {
  return httpClient.post('/api/public/main/faqSearch', params, { headers: {"show-layer": "Yes"} });
};
const librarySearch = (params) => {
  return httpClient.post('/api/public/main/librarySearch', params, { headers: {"show-layer": "Yes"} });
};
const selectFaqList = (params) => {
  return httpClient.post('/api/public/main/selectFaqList', params, { headers: {"show-layer": "Yes"} });
};
const selectNoticeList = (params) => {
  return httpClient.post('/api/public/main/selectNoticeList', params, { headers: {"show-layer": "Yes"} });
};
const insertQuestBoard = (params) => {
  return httpClient.post('/api/public/main/insertQuestBoard', params, { headers: {
    "Content-Type" : "multipart/form-data",
    "show-layer": "Yes"
  } });
};
const selectLibraryList = (params) => {
  return httpClient.post('/api/public/main/selectLibraryList', params, { headers: {"show-layer": "Yes"} });
};
const procDownloadLibraryFile = (params) => {
  return httpClient.post('/api/public/main/procDownloadLibraryFile', params, { headers: {"show-layer": "Yes"}, responseType: 'arraybuffer' })
  .then((response) => fnDownCallback(response));
};
const selectCmUseTermsHistList = (params) => {
  return httpClient.post('/api/public/main/selectCmUseTermsHistList', params, { headers: {"show-layer": "Yes"} });
};
const selectCmUseTermsInfo = (params) => {
  return httpClient.post('/api/public/main/selectCmUseTermsInfo', params, { headers: {"show-layer": "Yes"} });
};
const selectUserGuideUrl = (params) => {
	return httpClient.post('/api/public/selectUserGuideUrl', params, { headers: { "show-layer": "Yes" } });
};

export default {
  selectPrdUnitInfoList,
  selectCodeList,
  noticeSearch,
  faqSearch,
  librarySearch,
  selectFaqList,
  selectNoticeList,
  insertQuestBoard,
  selectLibraryList,
  procDownloadLibraryFile,
  selectCmUseTermsHistList,
  selectCmUseTermsInfo,
  selectUserGuideUrl
};

function fnDownCallback(response){
  try {
    let blob = new Blob([response.data], { type: response.headers['content-type'] });
    let fileName = fnGetFileName(response.headers['content-disposition']);
    fileName = decodeURI(fileName);

    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else { // not IE
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_self';
      if (fileName) link.download = fileName;
      link.click();
    }
  } catch (e) {
    console.error(e);
  }
}

function fnGetFileName (contentDisposition) {
  let fileName = contentDisposition
    .split(';')
    .filter((ele) => {
      return ele.indexOf('filename') > -1
    })
    .map((ele) => {
      return ele
        .replace(/"/g, '')
        .split('=')[1]
    })
  return fileName[0] ? fileName[0] : null
}

