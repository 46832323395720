<template>
	
	<div v-if="layerView" class="layerPopup modalStyle">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<div style="margin-top: 20px;">
						<h4 class="inline-block">문자 발신번호 사전등록 안내</h4>

						<div class="row consolMarginTop">
							<div class="col-xs-12">
								<div class="menuBox">						
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 전기통신사업법 제84조에 따라 2015년 10월 16일부로 <strong>'발신번호 사전등록제'</strong>가 시행되었습니다.</p>
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> '발신번호 사전 등록제'란, 거짓으로 표시된 전화번호로 인한 이용자 피해 예방을 위한 것으로, <strong>문자 메시지 발송 시 사전 인증된 발신 번호로만 사용할 수 있도록 등록하는 제도</strong> 입니다.</p>
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 이에 따라, 전기통신사업법 제 84조 2항(전화번호의 거짓 표시 금지 및 이용자 보호)에 위반되지 않도록 자신의 발신번호를 사전에 등록하고, 등록된 번호로만 문자 메시지를 발송해야 합니다.</p>
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 또한, 발신 번호는 기업, 기관 혹은 본인이 소유한 번호 여야 하며, 등록된 발신 번호로 전송한 메시지가 발신 번호 변조로 의심되어 KISA(한국인터넷진흥원)로부터 소명 요청을 받을 경우, 해당 고객의 발신 번호 소유를 증명할 수 있어야 합니다.</p>
								</div>
							</div>
						</div>

						<div style="margin-top: 30px;">
							<h4 class="inline-block">문자 발신번호 등록 안내</h4>
							<p class="color3 inline-block float-right mt30">* 통신서비스 이용증명원 <i class="fas fa-question-circle toolTip toolRight"><span class="toolTipText" style="width:320px">문자 메시지처럼 문자 형식의 텍스트를 입력할 수 있습니다.<br>에뮬레이터에 노출되는 이미지는 변경되지 않습니다.<br>최대 90자까지(공백포함) 입력이 가능합니다.</span></i></p>				
						</div>

						<div class="row consolMarginTo">
							<div class="col-xs-12">
								<table class="table_skin1 pdWide tbl-striped font-size13" style="width:100%">

										<tr>
											<td class="text-left vertical-middle">
												<ol class="">
													<li>휴대폰 번호 등록</li>
												</ol>
											</td>
											<td class="text-left vertical-middle">
												<ol class="">
													<li><b>휴대폰에 설치된 PASS로 본인인증 후 아래 서류를 첨부하시면, 고객센터에서 검토 후 발신번호가 등록됩니다. (1~3일 소요)</b></li>
													<li>제출서류</li>
													<li>- 휴대폰 소유자 재직증명서 (대표자는 제외)</li>
													<li>- 사업자 등록증</li>
													<li>- 통신서비스 가입증명원</li>
												</ol>
											</td>
										</tr>
										<tr>
											<td class="text-left vertical-middle">
												<ol class="">
													<li>유선 번호 등록</li>
												</ol>
											</td>
											<td class="text-left vertical-middle">
												<ol class="">
													<li><b>유선 번호 입력 후 아래 서류를 첨부하시면, 고객센터에서 검토 후 발신번호가 등록됩니다. (1~3일 소요)</b></li>
													<li>제출서류</li>
													<li>- 통신서비스 가입증명원</li>
												</ol>
											</td>
								
										</tr>
										<tr>
											<td class="text-left vertical-middle">
												<ol class="">
													<li>타인/타사 번호 등록</li>
												</ol>
											</td>
											<td class="text-left vertical-middle">
												<ol class="">
													<li><b>타인/타사 번호 등록 신청 시 아래 서류를 첨부하시면, 고객센터에서 검토 후 발신번호가 등록됩니다. (1~3일 소요)</b></li>
													<li>제출서류</li>
													<li>- 신분증 사본 (위임업체 대리인 / 수임업체 대리인)</li>
													<li>- 재직증명서 (위임업체 대리인 / 수임업체 대리인)</li>
													<li>- 인감증명서 (위임업체 / 수임업체)</li>
													<li>- 인감이 날인된 위임장</li>
													<li>- 위임-수임관계 확인 서류</li>
													<li>- 통신서비스 가입증명원</li>
												</ol>
											</td>

										</tr>
								</table>
							</div>
						</div>
						<div class="text-center mt40">
							<!-- <a @click="$emit('fnMoveTab', '2')" class="btnStyle2 borderGray ml10">문자 발신번호 등록</a> -->
							<a @click="fnCloseLayer()" class="btnStyle2 borderGray ml10" title="취소">취소</a>
						</div>
						<!-- //본문 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'RegisterLayer',
	props: {
		layerView: {
			type: Boolean,
			require: true,
			default: false
		},
		title: {
			type: String,
			require: false
		},
		registerLayerOpen: {
			type: Boolean,
			require: true,
			default: false
		},
	},
	methods: {
		// 닫기
		fnCloseLayer(){
			this.$emit('update:layerView', false);
		},
	}
}
</script>