<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle cx="8" cy="8" r="8" fill="#FF594F"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.9248 3.03125C8.33902 3.03125 8.6748 3.36704 8.6748 3.78125L8.6748 10.3857C8.6748 10.7999 8.33902 11.1357 7.9248 11.1357C7.51059 11.1357 7.1748 10.7999 7.1748 10.3857L7.1748 3.78125C7.1748 3.36704 7.51059 3.03125 7.9248 3.03125Z" fill="white"/>
    <path d="M7.9248 15.3848C8.54613 15.3848 9.0498 14.8811 9.0498 14.2598C9.0498 13.6384 8.54613 13.1348 7.9248 13.1348C7.30348 13.1348 6.7998 13.6384 6.7998 14.2598C6.7998 14.8811 7.30348 15.3848 7.9248 15.3848Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconInfoRed',
  };
</script>