<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
    <path d="M20.6667 31.1654H24.3333V29.332H26.1667C26.6861 29.332 27.1215 29.1563 27.4729 28.8049C27.8243 28.4536 28 28.0181 28 27.4987V21.9987C28 21.4793 27.8243 21.0438 27.4729 20.6924C27.1215 20.3411 26.6861 20.1654 26.1667 20.1654H20.6667V18.332H28V14.6654H24.3333V12.832H20.6667V14.6654H18.8333C18.3139 14.6654 17.8785 14.8411 17.5271 15.1924C17.1757 15.5438 17 15.9793 17 16.4987V21.9987C17 22.5181 17.1757 22.9536 17.5271 23.3049C17.8785 23.6563 18.3139 23.832 18.8333 23.832H24.3333V25.6654H17V29.332H20.6667V31.1654ZM7.83332 36.6654C6.82499 36.6654 5.9618 36.3063 5.24374 35.5883C4.52568 34.8702 4.16666 34.007 4.16666 32.9987V10.9987C4.16666 9.99036 4.52568 9.12717 5.24374 8.40911C5.9618 7.69106 6.82499 7.33203 7.83332 7.33203H37.1667C38.175 7.33203 39.0382 7.69106 39.7562 8.40911C40.4743 9.12717 40.8333 9.99036 40.8333 10.9987V32.9987C40.8333 34.007 40.4743 34.8702 39.7562 35.5883C39.0382 36.3063 38.175 36.6654 37.1667 36.6654H7.83332ZM7.83332 32.9987H37.1667V10.9987H7.83332V32.9987Z" fill="#7C7DFA"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconAtm',
  };
</script>