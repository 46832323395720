import alarm from '../views/bm-alarm'
import recipient from '../views/bm-recipient'
import receptGroup from '../views/bm-receptGroup'
import alarmSuccess from '../views/bm-alarmSuccess'
import alarmInflow from '../views/bm-alarmInflow'
import alarmIP from '../views/bm-alarmIP'
import alarmPrepayment from '../views/bm-alarmPrepayment'
import alarmLimits from '../views/bm-alarmLimits'
import alarmAverage from '../views/bm-alarmAverage'

export default [
	{
		path: '/ac/alarm/alarm',
		name: 'alarm',
		meta: { menu: 'AC_AL_ALARM'},
		component: alarm
	},
	{
		path: '/ac/alarm/receptGroup',
		name: 'receptGroup',
		meta: { menu: 'AC_AL_RECEPT_GRP'},
		component: receptGroup
	},
	{
		path: '/ac/alarm/recipient',
		name: 'recipient',
		meta: { menu: 'AC_AL_RECIPIENT'},
		component: recipient
	},
	// 나중에 BO에서 권한, 메뉴 추가 하기
	{
		path: '/ac/alarm/alarm/success',
		name: 'alarmSuccess',
		meta: { menu: 'AC_AL_ALARMSUCCESS'},
		component: alarmSuccess
	},
	{
		path: '/ac/alarm/alarm/inflow',
		name: 'alarmInflow',
		meta: { menu: 'AC_AL_INFLOW'},
		component: alarmInflow
	},
	{
		path: '/ac/alarm/alarm/ip',
		name: 'alarmIP',
		meta: { menu: 'AC_AL_IP'},
		component: alarmIP
	},
	{
		path: '/ac/alarm/alarm/prepayment',
		name: 'alarmPrepayment',
		meta: { menu: 'AC_AL_PREPAYMENT'},
		component: alarmPrepayment
	},
	{
		path: '/ac/alarm/alarm/limits',
		name: 'alarmLimits',
		meta: { menu: 'AC_AL_LIMITS'},
		component: alarmLimits
	},
	{
		path: '/ac/alarm/alarm/average',
		name: 'alarmAverage',
		meta: { menu: 'AC_AL_AVERAGE'},
		component: alarmAverage
	}
]