<template>
  <div>
    <BcMessageStatus />
  </div>
</template>

<script>
import BcMessageStatus from '../components/bc-message-status.vue';

export default {
  components: {
    BcMessageStatus
  }
}
</script>