<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M8 2V11.5" stroke="#374151" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.5 7L8 11.5L12.5 7" stroke="#374151" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 13.5H13.5" stroke="#374151" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  </template>
  
  <script>
    export default {
      name: 'IconDownload',
    };
  </script>