import spam from '../views/bm-sendLimit-spam'
import sendLimitAmount from '../views/bm-sendLimit-sendLimitAmount'
import rejct080Num from '../views/bm-rejct080Num.vue'

export default [
    {
        path: '/ac/sendLimit/spam',
        name: 'sendLimit-spam',
        props: true,
        meta: { menu: 'AC_ADD_FUN1' , title: '발신제한'},
        component: spam
    },
    {
        path: '/ac/sendLimit/sendLimitAmount',
        name: 'sendLimit-sendLimitAmount',
        props: true,
        meta: { menu: 'AC_ADD_FUN2' , title: '발신제한'},
        component: sendLimitAmount
    },
    {
		path: '/ac/sendLimit/rejct080Num',
		name: 'rejct080Num',
		meta: { menu: 'AC_ADD_FUN3', title: '발신제한'},
		component: rejct080Num,
		props: true
	},
]