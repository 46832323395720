<template>
  <div class="message-tabs-wrap">
    <div class="message-tabs">
      <div :class="['tab', isActive('/ac/channel/rcs')]">
        <router-link to="/ac/channel/rcs">RCS</router-link>
      </div>
      <div :class="['tab', isActive('/ac/channel/kakao')]">
        <router-link to="/ac/channel/kakao">카카오톡</router-link>
      </div>
    </div>
    <p class="breadcrumb f-body5 c-gray700">채널관리 > {{breadCrumbTitle}}</p>
  </div>
</template>

<script>
import '@/assets/scss/service/messageTabs.scss'

export default {
  name: "ChannelTabs",
  computed: {
    breadCrumbTitle() {
      switch (this.$route.path) {
        case '/ac/channel/rcs':
          return 'RCS';
        case '/ac/channel/kakao':
          return '카카오톡';
        default:
          return ''
      }
    },
  },
  methods: {
    isActive(link) {
      return window.location.pathname === link ? 'active' : '';
    }
  }
}
</script>