<template>
  <div class="chart-container">
    <doughnut-chart :chart-data="chartData" :chart-options="chartOptions"></doughnut-chart>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// Chart.js에 필요한 컴포넌트 등록
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  name: 'Chart',
  components: {
    'doughnut-chart': Doughnut
  },
  data() {
    return {
      chartData: {
        labels: ['SMS', 'LMS', 'MMS', 'KAKAO', 'RCS', 'PUSH', '기타'],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: ['#C2F1F7', '#9AE7D9', '#87F3BF', '#B2E889', '#D2E82D', '#F2E3BE', '#EED2EB'],
            data: [40, 20, 12, 39, 10, 40, 39]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        cutout: '50%', // 도넛 차트의 구멍 크기를 50%로 설정
        plugins: {
          legend: {
            position: 'left', // 범례 위치 설정
          },
          datalabels: {
            color: '#fff',
            anchor: 'center',
            align: 'center',
            formatter: (value, context) => {
              return value;
            },
            font: {
              weight: 'bold'
            }
          }
        }
      }
    }
  }
}

</script>

<style scoped>
.chart-container {
  position: relative;
  margin: auto;
  height: 400px;
  width: 400px;
}
</style>