<template>
	<div>
		<div class="row" style="margin-top: 10px;">
			<div class="col-xs-12">
				<div class="menuBox">						
					<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 전기통신사업법 제 84조 <strong>'SMS 발신 번호 사전 등록제'</strong> 가 2015년 10월 16일부로 시행됨에 따라 SMS를 발송하려면 발신 번호를 먼저 등록하셔야 합니다.</p>
					<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> <strong> RCS 발신번호를 등록하면 문자 발신번호까지 동시</strong>에 등록됩니다.</p>
				</div>
			</div>
		</div>
		<!-- <div class="clear" style="margin-top: 30px;">
			<h4 class="inline-block">RCS 문자 발신번호 등록</h4>	
		</div> -->
		<!-- <div class="row consolMarginTop">
			<div class="col-xs-12">
				<div class="menuBox">						
					<div class="of_h vertical-top">
						<div class="float-left" style="width:20%"><h4>등록방법 선택</h4></div>
						<div class="float-left" style="width:80%">
							<input type="radio" id="selType_1" name="selType" value="1" class="blank2" v-model="selType">
							<label for="selType_1" class="mr30" style="margin-left: 7px;">휴대폰 번호 등록</label>
							<input type="radio" id="selType_2" name="selType" value="2" style="margin-left: 37px;" class="blank2" v-model="selType">
							<label for="selType_2" class="mr30" style="margin-left: 7px;">유선 번호 등록</label>
							<input type="radio" id="selType_3" name="selType" value="3" style="margin-left: 37px;" class="blank2" v-model="selType">
							<label for="selType_3" class="mr30" style="margin-left: 7px;">타인/타사 등록</label>
							<input type="radio" id="selType_4" name="selType" value="4" style="margin-left: 37px;" class="blank2" v-model="selType">
							<label for="selType_4" style="margin-left: 7px;">대리인</label>
						</div>
					</div>
				</div>
			</div>
		</div> -->
		<div class="row">
			<div class="col-xs-12">
				<!-- <div style="margin-top: 30px;">
					<h4 class="inline-block">RCS 문자 발신번호 등록 방법</h4>
				</div>
				<div class="container">
					<div class="progress-container" style="margin-top: 70px;">
						<div class="progress-step active">
							<div class="step-number">1</div>
							<div class="step-title">RCS 발신번호 등록</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">2</div>
							<div class="step-title">발신번호명 설정</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">3</div>
							<div class="step-title">승인요청</div>
						</div>
					</div>
				</div> -->
				<table class="table_skin1 consolMarginTop" style="width:100%; margin-top: 30px;">
					<tr>
						<td class="text-center vertical-middle bb2" style="width:30%">
							<h5 class="topH4" style="text-align: left;">RCS 발신번호 등록</h5>
							<ul class="text-left dotList" style="margin-top: 20px;">
								<li> RCS Biz Center 에서 브랜드를 생성해야 RCS 발신번호 등록이 가능합니다.</li>
								<li> 등록된 RCS 발신번호는 선택한 브랜드에 종속됩니다.</li>
								<li> 등록된 RCS 발신번호는 문자 발신번호에 동시 등록됩니다.</li>
								<li> RCS 발신번호를 여러 개 등록할 경우, 기존에 등록된 발신번호가 있다면 해당 발신번호는 다음 단계에서 표시되지 않습니다.</li>
								<li> 입력한 RCS 발신번호가 기존에 모두 등록되어 있다면 다음 단계로 넘어가지 않습니다.</li>
								<li> 대표번호 문자 수신 서비스(MO)를 사용하실 경우 MO 체크박스를 체크해 주세요.</li>
								<li> RBC에서 등록한 브랜드에 "LG유플러스" 대행사 지정을 반드시 해 주세요.</li>
							</ul>
						</td>
						<td class="text-center vertical-middle end"  style="width:70%">
							<div class="row">
								<div class="col-xs-12 consoleCon">				
									<div class="of_h">
										<h5 class="inline-block" style="width:22%">브랜드</h5>
										<div class="float-right" style="width:76%">
										<select id="cate1" class="selectStyle2" style="width:100%" v-model="brandId">
											<option v-for="(option, i) in brandList" v-bind:value="option.brandId" v-bind:key="i">
											{{ option.brandName }}
											</option>
										</select>
										<p class="txtCaption color3">발신번호 등록은 브랜드가 먼저 등록되어야 하며, 승인완료 된 브랜드만 보여집니다.</p>
										</div>
									</div>
									<div class="of_h consolMarginTop">
									<h5 class="inline-block" style="width:22%">RCS 발신번호</h5>
									<div class="float-right" style="width:76%">
										<p class="txtCaption color3">대표번호문자수신서비스(MO)를 사용하실 경우 MO체크박스에 체크해주세요.</p>
										<table class="table_skin1 consolMarginTop" style="width:100%">
										<colgroup>
											<col style="width:40%">
											<col style="width:40%">
											<col style="width:10%">
											<col style="width:10%">
										</colgroup>
										<thead>
										<tr>
											<th class="text-center end">발신번호</th>
											<th class="text-center end">발신번호명</th>
											<th class="text-center end">MO</th>
											<th class="text-center end"></th>
										</tr>
										</thead>
										<tbody class="of_h">
											<tr v-for="(item, i) in chatbots" :key="i">	
											<td class="end"><input v-model="chatbots[i].mdn" :id="'mdn' + i"  type="text" class="inputStyle" style="width:100%" @input="fnHpNumberAddDash($event)"></td>
											<td class="end"><input v-model="chatbots[i].subTitle" :id="'subTitle' + i" type="text" class="inputStyle" style="width:100%"></td>
											<td class="end"><div class="text-center"><input type="checkbox" id="MO01" class="checkStyle2" value="MO01"><label for="MO01"></label></div></td>
											<td class="end"><a @click="fnDeleteChatbotTr" class="btnStyle1 borderGray ml10" style="padding: 0 10px"><i class="far fa-minus"></i></a></td>
											</tr>
											<tr>
											<td class="end"></td>															
											<td class="end"></td>															
											<td class="end"></td>															
											<td class="end"><a @click="fnAddChatbotTr" class="btnStyle1 borderGray ml10" style="padding: 0 10px"><i class="far fa-plus"></i></a></td>															
											</tr>								  
										</tbody>
										</table>
									</div>	
									</div>
									<div class="of_h consolMarginTop">
										<h5 class="inline-block vertical-middle" style="width:22%">통신서비스<br>가입증명원 *</h5>
										<div class="float-right" style="width:76%">
											<input id="certiImgFile" type="file" class="btnStyle7 minWidthAuto float float-right" style="width:100%" ref="certiImgFile"/>
										</div>
									</div>
									<p class="txtCaption mt20">첨부 파일 확장자는 <strong>png, jpg, pdf, zip, tif, tiff</strong>만 허용됩니다.</p>
									<p class="txtCaption color3">(5M 이하 파일 가능)</p>
								</div>
							</div>

						</td>
					</tr>
				</table>
				<div class="text-center mt40">
					<a @click="fnApproval" class="btnStyle2 borderGray ml10" activity="SAVE" >승인요청</a>
					<a @click="fnCallParent()" class="btnStyle2 borderGray ml10">목록</a>
				</div>
			</div>
		</div>
		

	</div>
</template>

<script>
import axios from 'axios'
import confirm from '@/modules/commonUtil/service/confirm';
import sendInfoApi from '../service/sendInfoApi'
import tokenSvc from '@/common/token-service';
// import commonUtilApi from "@/modules/commonUtil/service/commonUtilApi";

export default {
  components: {
	//   layerPopup,
	//   layerPopupDoc,
	//   layerPopupProxy,
	//   layerPopupAll
  },
  data() {
    return {
	brandId   : "",
	brandList : [
	{
		// 임시용
		"brandId" : "BR.xphojy",
		"brandName" : "brandName2"
	}
	],
	chatbotCnt : 0,
	chatbots  : [],
	mainMdn   : "",
	mainTitle : "",
	rcsReply  : "",
	rcsReplyYn : "",
    }
  },
  mounted() {
	this.fnBrandList();
  }, 
  props: {
    srcProjectId : {
      type: String,
      require: true
    },
    detailCnt : {
      type : Number,
      require : true
    }
  },
	watch: {
    srcProjectId(){
		this.fnBrandList();
    },
    detailCnt (){
		this.chatbotCnt = 0;
		this.chatbots = [];
		jQuery("#certiImgFile").val("");
    }
  },
  methods: {
	fnBrandList(){
		var params = { "srcProjectId" : this.srcProjectId }
		sendInfoApi.selectApprovalBrandList(params).then(response =>{
		this.brandList = response.data.data;
		this.brandId = this.brandList[0].brandId;
		});
    },
    // 발신번호추가
    fnAddChatbotTr(){
		this.chatbotCnt++;
		this.chatbots.push({
			"mdn"       : "",		  // 발신번호
			"rcsReply"	: "1",		// 대표번호문자 수신서비스 0 = x / 1 = o
			"subTitle"	: "",		  // 발신번호명
			"service"   : "a2p",	// a2p 고정값
			"display"   : "10"		// '10' 고정값
		});
    },
    // 발신번호삭제
    fnDeleteChatbotTr(){
		this.chatbotCnt--;
		this.chatbots.splice(-1);
    },
    //전화번호 포맷
    fnHpNumberAddDash(event){
		var arrId = event.target.id.substr(3);
		this.chatbots[arrId].mdn = this.$gfnCommonUtils.hpNumberAddDash(event.target.value.replace(/[^0-9]/g, ''));  
    },
    // 승인요청
    async fnApproval(){

		if(this.chatbots.length == 0) {
			confirm.fnAlert("", "추가 발신번호를 하나 이상 입력해주세요.");
			return;
		}
		var isMdn = true;
		var isSubTitle = true;
		this.chatbots.map(function(value) {
		if(value.mdn == '') {
			isMdn = false;
			return;
		}
		if(value.subTitle == '') {
			isSubTitle = false;
			return;
		}
		})

		if (isMdn == false) {
			confirm.fnAlert("", "등록할 발신번호를 입력해주세요.");
			return;
		}
		if (isSubTitle == false) {
			confirm.fnAlert("", "등록할 발신번호명을 입력해주세요.");
			return;
		}
		
		if(this.$refs.certiImgFile.files[0] === null || this.$refs.certiImgFile.files[0] === undefined ){
			confirm.fnAlert("", "통신서비스 가입증명원 파일을 등록해주세요.");
		return;
		}

		var fd = new FormData();
		fd.append('sts'			    , "C");
		fd.append('saveCorpId'	, tokenSvc.getToken().principal.corpId);
		fd.append('projectId'		, this.srcProjectId);
		fd.append('brandId'			, this.brandId);
		fd.append('chatbotId'		, '');
		fd.append('mainMdn'			, this.mainMdn);
		fd.append('mainTitle'		, this.mainTitle);

		// 첨부파일 정리
		fd.append('certiFile'		, this.$refs.certiImgFile.files[0]);

		// 챗봇(발신번호) 정리
		var list = [];

		for( var i = 0; i < this.chatbots.length; i++ ){
		var chatbot = Object.assign({}, this.chatbots[i]);
		chatbot.mdn = this.$gfnCommonUtils.hpNumberRemoveDash(chatbot.mdn);
		var obj = JSON.stringify(chatbot);
		// var obj = JSON.stringify(this.chatbots[i]);    //원본
		list.push(obj);
		}
		var listString = list.join(", ");
		fd.append('chatbots'		, listString) ;
		
		await axios.post('/sendInfoApi/manage/saveRcsChatbotReqForApi',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
		var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			// 목록 버튼
			jQuery("#regPop").modal("hide");
		} else {
			confirm.fnAlert("", result.message);
		}
		}).catch(function () {
		confirm.fnAlert("", "저장에 실패했습니다.");
		});
		},
		fnCallParent() {
			this.$emit('fnMoveMain');
		}	
	}
}
</script>
<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 80%;
  max-width: 900px;
  text-align: center;
}

h1 {
  margin-bottom: 40px;
  color: #333;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.progress-step:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ccc;
  top: 50%;
  left: 50%;
  z-index: -1;
}

.progress-step:not(:first-child)::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ccc;
  top: 50%;
  right: 50%;
  z-index: -1;
}

.step-number {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ccc;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 1;
}

.step-title {
  color: #ccc;
  font-size: 14px;
}

.progress-step.active .step-number {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

.progress-step.active ~ .progress-step .step-number {
  background-color: #fff;
  color: #ccc;
  border-color: #ccc;
}

.progress-step.active .step-title {
  color: #333;
}

.progress-step.active ~ .progress-step .step-title {
  color: #ccc;
}
</style>