<template>
    <div class="multiMain">
        <h2 class="consoleMainTitle">발송</h2>
        <messageSendHeader></messageSendHeader>


        <div class="multiContent">
            <div>
                <div class="multiButton">
                    <ul class="multiMessageTabs">
                        <li class="backgroundNonButton" id="tapButtonBlue">RCS</li>
                        <li class="backgroundNonButton" id="tapButtonBlue">SMS</li>
                        <li class="backgroundNonButton" id="tapButtonBlue">카카오톡</li>
                    </ul>
                </div>
                <phonMockup viewBox="0 0 32 32" width="24" height="24" iconColor="black" iconName="phone"
                    class="phoneMockup">
                </phonMockup>
            </div>

            <div class="formContainer">
                <div class="formGroup">
                    <label class="multiSendTitle">01 템플릿</label>
                    <label class="multiOption">기본,정보성</label>
                    <input class="mutiTemplateName" placeholder="RCS test 템플릿">
                </div>
                <div class="console_line"></div>
                <div class="receivingNumberAddButton">
                    <label>02 수신번호</label>
                    <select class="receivingGroup">
                        <option> 전송그룹을 선택하세요.</option>
                    </select>
                </div>
                <div class="formGroup2">
                    <li class="backgroundBlueButton" id="receivingNumberAddButton">직접입력추가</li>
                    <li class="backgroundBlueButton" id="receivingNumberAddButton">주소록에서추가</li>
                    <li class="backgroundBlueButton" id="receivingNumberAddButton">파일로추가</li>
                </div>
                <div class="recevingPhoneNumberGroup">
                    <div class="recevingPhoneNumbercheckboxItem">
                        <input type="checkbox" id="checkbox1">
                        <label class="receivePhoneNum" for="checkbox1">핸드폰 번호</label>
                    </div>
                    <div class="checkboxItem">
                        <input type="checkbox" id="checkbox2">
                        <input class="receivePhoneNum" type="text" placeholder="핸드폰 번호">
                    </div>
                    <div class="reciverAddWrapper">
                        <div>총0건</div>
                        <a class="backgroundBlueButton" id="reciverAdd">수신자 삭제</a>
                    </div>
                </div>
                <div class="console_line"></div>

                <div class="formGroup">
                    <label class="multiSendTitle">03 발송 옵션 선택</label>
                    <div class="scheduledSending">예약발송</div>
                    <div class="scheduledRadioButton">
                        <label class="buttonType">즉시</label>
                        <input type="radio">
                        <label class="buttonType">예약</label>
                        <input type="radio">
                    </div>
                    <div class="sendData">
                        <input type="date" id="searchStartDate" class="datePickerInput">
                        <input type="time" class="sendTime">
                    </div>
                </div>


                <div class="console_line"></div>

                <div class="formGroup">
                    <label class="multiSendTitle">04 발신번호</label>
                    <input class="multiPostNum" placeholder="02-1234-5678(마케팅)">
                </div>
            </div>

        </div>
    </div>

</template>

<script>
import messageSendHeader from "../components/messageSendHeader.vue";
import phonMockup from "@/components/phoneMockup.vue"
export default {
    components: {
        phonMockup,
        messageSendHeader
    },


}

</script>
