<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M2.5 8H13.5" stroke="#6D6EFA" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8 2.5V13.5" stroke="#6D6EFA" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconPlus',
  };
</script>