import rsc from '../views/bm-chan-rsc'
import kakao from '../views/bm-chan-kakao'

export default [
    {
        path: '/ac/channel/rcs',
        name: 'chan-rcs',
        props: true,
        meta: { menu: 'AC_CHM_RCS' },
        component: rsc
    },
    {
        path: '/ac/channel/kakao',
        name: 'chan-kakao',
        props: true,
        meta: { menu: 'AC_CHM_KKO' },
        component: kakao
    },
]