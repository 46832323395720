<template>
  <div>
    <BpWebSendDetail />
  </div>
</template>

<script>
import BpWebSendDetail from '../components/bp-web-send-detail.vue';

export default {
  components: {
    BpWebSendDetail
  }
}
</script>