<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M15.625 4.375L4.375 15.625" stroke="#374151" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M15.625 15.625L4.375 4.375" stroke="#374151" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconClose',
  };
</script>