import httpClient from '@/common/http-client';

const selectSendByList = (params) => {
	return httpClient.post('/statisticsUserApi/list/selectSendByList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectSendByCntList = (params) => {
	return httpClient.post('/statisticsUserApi/list/selectSendByCntList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectStatisList = (params) => {
	return httpClient.post('/statisticsAdminApi/list/selectStatisList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectChTotCntInfo = (params) => {
	return httpClient.post('/api/home/selectChTotCntInfo', params, { headers: { "show-layer": "Yes" } });
};

const selectChSuccFailCntList = (params) => {
	return httpClient.post('/api/home/selectChSuccFailCntList', params, { headers: { "show-layer": "Yes" } });
};

const selectChFailCodeList = (params) => {
	return httpClient.post('/api/home/selectChFailCodeList', params, { headers: { "show-layer": "Yes" } });
};

const selectRtUsedTimeLineList = (params) => {
	return httpClient.post('/api/home/selectRtUsedTimeLineList', params, { headers: { "show-layer": "Yes" } });
};

const selectRtUsedDataList = (params) => {
	return httpClient.post('/api/home/selectRtUsedDataList', params, { headers: { "show-layer": "Yes" } });
};

const selectChMinList = (params) => {
	return httpClient.post('/api/home/selectChMinList', params, { headers: { "show-layer": "Yes" } });
};

const selectRealTimeList = (params) => {
	return httpClient.post('/statisticsUserApi/list/selectRealTimeList', params, { headers: {"show-layer": "Yes", "activity":"READ"}});
};


const excelDownloadStatisList = (params) => {
	return httpClient.post('/statisticsAdminApi/list/excelDownloadStatisList', params, { headers: {"show-layer": "No", "activity":"READ"}, responseType: 'arraybuffer' }).then(function(response) {
		try {
			let blob = new Blob([response.data], { type: response.headers['content-type'] })
			let fileName = getFileName(response.headers['content-disposition'])
			fileName = decodeURI(fileName)

		if (window.navigator.msSaveOrOpenBlob) { // IE 10+
			window.navigator.msSaveOrOpenBlob(blob, fileName)
		} else { // not IE
				let link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.target = '_self'
				if (fileName) link.download = fileName
				link.click()
			}
		} catch (e) {
			console.error(e)
		}
	});
};

export default {
	selectSendByList,
	selectSendByCntList,
	selectStatisList,
	selectChTotCntInfo,
	selectChSuccFailCntList,
	selectChFailCodeList,
	selectRtUsedTimeLineList,
	selectRtUsedDataList,
	selectChMinList,
	excelDownloadStatisList,
	selectRealTimeList
};

function getFileName (contentDisposition) {
	let fileName = contentDisposition
		.split(';')
		.filter((ele) => {
		return ele.indexOf('filename') > -1
	})
	.map((ele) => {
		return ele
			.replace(/"/g, '')
			.split('=')[1]
	})
	return fileName[0] ? fileName[0] : null
}