<template>
    <div class="consoleMain" :style="mainStyles">
        <h2 class="consoleMainTitle">조회</h2>
        <div class="tabContainer">
            <ul class="tabList">
                <li :class="{active:(tabButton == 1)}" @click="tabButton=1"><a>발송현황</a></li>
                <li :class="{active:(tabButton == 2)}" @click="tabButton=2"><a>발송내역</a></li>
            </ul>
            <div class="tabLine"></div>
        </div>
        <shippingStatus v-if="tabButton == 1"></shippingStatus>
        <shippingHistory v-if="tabButton == 2"></shippingHistory>

        <div class="pagination">
            <a href="#">&laquo;</a>
            <a href="#" class="active">1</a>
            <a href="#">2</a>
            <a href="#">3</a>
            <a href="#">4</a>
            <a href="#">5</a>
            <a href="#">6</a>
            <a href="#">7</a>
            <a href="#">8</a>
            <a href="#">9</a>
            <a href="#">10</a>
            <a href="#">&raquo;</a>
        </div>
    </div>
</template>

<script>

import shippingStatus from "../components/shippingStatus.vue"
import shippingHistory from "../components/shippingHistory.vue"
import { mapState } from 'vuex';

export default {
    components:{
        shippingStatus,
        shippingHistory
    },
    data(){
        return{
            tabButton : 1
        }
    },
    computed: {
    ...mapState(['isSidebarOpen']),
    mainStyles() {
      return {
        width: this.isSidebarOpen ? '82%' : '100%',
        transition: 'width 0.5s ease',
        marginLeft: this.isSidebarOpen ? '18%' : '0', // 사이드바의 특정 너비가 있다면 조정
      };
    }
  }
};

</script>
