<template>
	<article class="content">
		<section class="userSection width540 mt70">
		<h2 class="text-center user_join_h2">인증에 실패하였습니다.</h2>
		<p class="text-center font-size13 mt60">이미 만료되었거나 유효하지 않은 요청입니다.<br>인증 메일을 다시 전송하여 인증해 주세요.</p>
	</section>
	</article>
</template>

<script>

export default {
	data: function() {
	},
	created() {
	},
	mounted() {
	},
	methods: {
	}
};
</script>