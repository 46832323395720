<template>
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
  <circle cx="6" cy="6" r="5.5" stroke="#9CA3AF"/>
  <path d="M6 10C6.41421 10 6.75 9.66421 6.75 9.25C6.75 8.83579 6.41421 8.5 6 8.5C5.58579 8.5 5.25 8.83579 5.25 9.25C5.25 9.66421 5.58579 10 6 10Z" fill="#9CA3AF"/>
  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.9999 2.39844C6.33127 2.39844 6.5999 2.66707 6.5999 2.99844L6.5999 6.99844C6.5999 7.32981 6.33127 7.59844 5.9999 7.59844C5.66853 7.59844 5.3999 7.32981 5.3999 6.99844L5.3999 2.99844C5.3999 2.66707 5.66853 2.39844 5.9999 2.39844Z" fill="#9CA3AF"/>
</svg>
</template>

<script>
  export default {
    name: 'IconInfoLine',
  };
</script>