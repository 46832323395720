<template>
	
	<div v-if="layerView" class="layerPopup modalStyle">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<div style="margin-top: 20px;">
						<h4 class="inline-block">RCS 발신번호 사전등록 안내</h4>

						<div class="row consolMarginTop">
							<div class="col-xs-12">
								<div class="menuBox">						
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 전기통신사업법 제84조에 따라 2015년 10월 16일부로 <strong>'발신번호 사전등록제'</strong>가 시행되었습니다.</p>
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> '발신번호 사전 등록제'란, 거짓으로 표시된 전화번호로 인한 이용자 피해 예방을 위한 것으로, <strong>문자 메시지 발송 시 사전 인증된 발신 번호로만 사용할 수 있도록 등록하는 제도</strong> 입니다.</p>
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 이에 따라, 전기통신사업법 제 84조 2항(전화번호의 거짓 표시 금지 및 이용자 보호)에 위반되지 않도록 자신의 발신번호를 사전에 등록하고, 등록된 번호로만 문자 메시지를 발송해야 합니다.</p>
									<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 또한, 발신 번호는 기업, 기관 혹은 본인이 소유한 번호 여야 하며, 등록된 발신 번호로 전송한 메시지가 발신 번호 변조로 의심되어 KISA(한국인터넷진흥원)로부터 소명 요청을 받을 경우, 해당 고객의 발신 번호 소유를 증명할 수 있어야 합니다.</p>
								</div>
							</div>
						</div>

						<div style="margin-top: 30px;">
							<h4 class="inline-block">RCS 발신번호 등록 안내</h4>
						</div>

						<div class="row consolMarginTo" style="margin-top: 20px;">
							<div class="col-xs-12">
								<table class="table_skin1 pdWide tbl-striped font-size13" style="width:100%">

										<tr style="height: 100px;">
											<td class="text-left vertical-middle">
												<ol class="">
													<li>1. RCS 가입 및 브랜드 생성</li>
												</ol>
											</td>
											<td class="text-left vertical-middle">
												<ol class="">
													<li><b>RCS 발신번호 등록을 위해서는 RCS Biz Center 가입 후 , 브랜드를 생성해야 합니다.</b></li>
													<li><b>사업자등록증 사본이 필요합니다.</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.rcsbizcenter.com" target="_blank" class="btnStyle1 borderGray ml10" sty>RCS Biz Center 바로가기</a></li>
												</ol>
											</td>
										</tr>
										<tr style="height: 100px;">
											<td class="text-left vertical-middle">
												<ol class="">
													<li>2. RCS 발신번호 등록 요청</li>
												</ol>
											</td>
											<td class="text-left vertical-middle">
												<ol class="">
													<li><b>RBC 로그인 ID , RBC API Key 가 필요합니다.</b></li>
													<li><b>통신서비스 이용증명원이 필요합니다.</b></li>
													<li><b>RCS 발신번호는 RBC의 승인과정을 통해 등록됩니다.(1~3일 소요)</b></li>
												</ol>
											</td>
								
										</tr>
										<tr style="height: 100px;">
											<td class="text-left vertical-middle">
												<ol class="">
													<li>3. RCS 발신번호 관리</li>
												</ol>
											</td>
											<td class="text-left vertical-middle">
												<ol class="">
													<li><b>RCS 발신번호가 등록되면 문자 발신번호도 동시에 등록됩니다.</b></li>
													<li><b>발신번호는 브랜드 1개당 N개 추가 할 수 있습니다.</b></li>
												</ol>
											</td>

										</tr>
								</table>
							</div>
						</div>
						<div class="text-center mt40">
							<!-- <a @click="$emit('fnMoveTab', '2')" class="btnStyle2 borderGray ml10">RCS 문자 발신번호 등록</a> -->
							<a @click="fnCloseLayer()" class="btnStyle2 borderGray ml10" title="취소">취소</a>
						</div>
						<!-- //본문 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'RegisterLayer',
	props: {
		layerView: {
			type: Boolean,
			require: true,
			default: false
		},
		title: {
			type: String,
			require: false
		},
		registerLayerOpen: {
			type: Boolean,
			require: true,
			default: false
		},
	},
	methods: {
		// 닫기
		fnCloseLayer(){
			this.$emit('update:layerView', false);
		},
	}
}
</script>