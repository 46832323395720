<template>
  <div class="uc-rcs-template-manage">
        <div class="preview-slide">
          <input type="radio" name="slide" id="slide01" checked>
          <input type="radio" name="slide" id="slide02">
          <input type="radio" name="slide" id="slide03">
          <input type="radio" name="slide" id="slide04">
          <input type="radio" name="slide" id="slide05">
          <input type="radio" name="slide" id="slide06">

          <div class="slidewrap">
            <ul class="slidelist">
              <li>
                <a>
                  <PreviewDefault>
                  <p class="mt-0 mb-1 pb-2 f-body1 c-gray700">썸네일형(세로)</p>
                  <div class="preview-section rcs">
                    <p class="rcs-title">[Web 발신]</p>
                    <div class="preview-img">
                      <div class="empty-img">
                        <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                        <p class="m-0 mt-1 c-caption2 c-gray500">작성페이지에서 이미지를<br/>
                          삽입할 수 있습니다.</p>
                      </div>
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="rcs-content rcs-type">
                      <div class="icon">
                        <IconShoppingCart />
                      </div>
                      <span>출고</span>
                    </div>
                    <div class="rcs-content rcs-col-content">
                      <p class="rcs-col-content-title">썸네일형</p>
                      <div>
                        <p>내용 입력 예시</p>
                        <div class="row">
                          <p class="col">예시1</p>
                          <p class="col">예시2</p>
                        </div>
                      </div>
                    </div>
                    <div class="rcs-content rcs-template-content">
                      <p class="rcs-template-content-title">예시 제목</p>
                      <p class="rcs-template-content-desc">예시 내용</p>
                    </div>
                    <div class="rcs-content rcs-thumbnail">
                      <ul>
                        <li>
                          <div class="thumb-img">
                            <img src="/" alt="">
                          </div>
                          <p>썸네일 내용 영역입니다. 썸네일 내용 영역입니다. 썸네일 내용 영역입니다. </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rcs-content rcs-button">
                      <b-button variant="secondary">버튼 영역</b-button>
                    </div>
                    <div class="rcs-content m-0">
                      <p class="m-0 f-cpation2 c-gray700">무료 수신거부 <span class="ml-1 pl-2 text-underline text-primary">010-0000-0000</span></p>
                    </div>
                  </div>
                  </PreviewDefault>
                </a>
              </li>
              <li>
                <a>
                  <PreviewDefault>
                  <p class="mt-0 mb-1 pb-2 f-body1 c-gray700">썸네일형(세로)</p>
                  <div class="preview-section rcs">
                    <p class="rcs-title">[Web 발신]</p>
                    <div class="preview-img">
                      <div class="empty-img">
                        <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                        <p class="m-0 mt-1 c-caption2 c-gray500">작성페이지에서 이미지를<br/>
                          삽입할 수 있습니다.</p>
                      </div>
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="rcs-content rcs-type">
                      <div class="icon">
                        <IconShoppingCart />
                      </div>
                      <span>출고</span>
                    </div>
                    <div class="rcs-content rcs-col-content">
                      <p class="rcs-col-content-title">썸네일형</p>
                      <div>
                        <p>내용 입력 예시</p>
                        <div class="row">
                          <p class="col">예시1</p>
                          <p class="col">예시2</p>
                        </div>
                      </div>
                    </div>
                    <div class="rcs-content rcs-template-content">
                      <p class="rcs-template-content-title">예시 제목</p>
                      <p class="rcs-template-content-desc">예시 내용</p>
                    </div>
                    <div class="rcs-content rcs-thumbnail">
                      <ul>
                        <li>
                          <div class="thumb-img">
                            <img src="/" alt="">
                          </div>
                          <p>썸네일 내용 영역입니다. 썸네일 내용 영역입니다. 썸네일 내용 영역입니다. </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rcs-content rcs-button">
                      <b-button variant="secondary">버튼 영역</b-button>
                    </div>
                    <div class="rcs-content m-0">
                      <p class="m-0 f-cpation2 c-gray700">무료 수신거부 <span class="ml-1 pl-2 text-underline text-primary">010-0000-0000</span></p>
                    </div>
                  </div>
                  </PreviewDefault>
                </a>
              </li>
              <li>
                <a>
                  <PreviewDefault>
                  <p class="mt-0 mb-1 pb-2 f-body1 c-gray700">썸네일형(세로)</p>
                  <div class="preview-section rcs">
                    <p class="rcs-title">[Web 발신]</p>
                    <div class="preview-img">
                      <div class="empty-img">
                        <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                        <p class="m-0 mt-1 c-caption2 c-gray500">작성페이지에서 이미지를<br/>
                          삽입할 수 있습니다.</p>
                      </div>
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="rcs-content rcs-type">
                      <div class="icon">
                        <IconShoppingCart />
                      </div>
                      <span>출고</span>
                    </div>
                    <div class="rcs-content rcs-col-content">
                      <p class="rcs-col-content-title">썸네일형</p>
                      <div>
                        <p>내용 입력 예시</p>
                        <div class="row">
                          <p class="col">예시1</p>
                          <p class="col">예시2</p>
                        </div>
                      </div>
                    </div>
                    <div class="rcs-content rcs-template-content">
                      <p class="rcs-template-content-title">예시 제목</p>
                      <p class="rcs-template-content-desc">예시 내용</p>
                    </div>
                    <div class="rcs-content rcs-thumbnail">
                      <ul>
                        <li>
                          <div class="thumb-img">
                            <img src="/" alt="">
                          </div>
                          <p>썸네일 내용 영역입니다. 썸네일 내용 영역입니다. 썸네일 내용 영역입니다. </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rcs-content rcs-button">
                      <b-button variant="secondary">버튼 영역</b-button>
                    </div>
                    <div class="rcs-content m-0">
                      <p class="m-0 f-cpation2 c-gray700">무료 수신거부 <span class="ml-1 pl-2 text-underline text-primary">010-0000-0000</span></p>
                    </div>
                  </div>
                  </PreviewDefault>
                </a>
              </li>
              <li>
                <a>
                  <PreviewDefault>
                  <p class="mt-0 mb-1 pb-2 f-body1 c-gray700">썸네일형(세로)</p>
                  <div class="preview-section rcs">
                    <p class="rcs-title">[Web 발신]</p>
                    <div class="preview-img">
                      <div class="empty-img">
                        <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                        <p class="m-0 mt-1 c-caption2 c-gray500">작성페이지에서 이미지를<br/>
                          삽입할 수 있습니다.</p>
                      </div>
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="rcs-content rcs-type">
                      <div class="icon">
                        <IconShoppingCart />
                      </div>
                      <span>출고</span>
                    </div>
                    <div class="rcs-content rcs-col-content">
                      <p class="rcs-col-content-title">썸네일형</p>
                      <div>
                        <p>내용 입력 예시</p>
                        <div class="row">
                          <p class="col">예시1</p>
                          <p class="col">예시2</p>
                        </div>
                      </div>
                    </div>
                    <div class="rcs-content rcs-template-content">
                      <p class="rcs-template-content-title">예시 제목</p>
                      <p class="rcs-template-content-desc">예시 내용</p>
                    </div>
                    <div class="rcs-content rcs-thumbnail">
                      <ul>
                        <li>
                          <div class="thumb-img">
                            <img src="/" alt="">
                          </div>
                          <p>썸네일 내용 영역입니다. 썸네일 내용 영역입니다. 썸네일 내용 영역입니다. </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rcs-content rcs-button">
                      <b-button variant="secondary">버튼 영역</b-button>
                    </div>
                    <div class="rcs-content m-0">
                      <p class="m-0 f-cpation2 c-gray700">무료 수신거부 <span class="ml-1 pl-2 text-underline text-primary">010-0000-0000</span></p>
                    </div>
                  </div>
                  </PreviewDefault>
                </a>
              </li>
              <li>
                <a>
                  <PreviewDefault>
                  <p class="mt-0 mb-1 pb-2 f-body1 c-gray700">썸네일형(세로)</p>
                  <div class="preview-section rcs">
                    <p class="rcs-title">[Web 발신]</p>
                    <div class="preview-img">
                      <div class="empty-img">
                        <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                        <p class="m-0 mt-1 c-caption2 c-gray500">작성페이지에서 이미지를<br/>
                          삽입할 수 있습니다.</p>
                      </div>
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="rcs-content rcs-type">
                      <div class="icon">
                        <IconShoppingCart />
                      </div>
                      <span>출고</span>
                    </div>
                    <div class="rcs-content rcs-col-content">
                      <p class="rcs-col-content-title">썸네일형</p>
                      <div>
                        <p>내용 입력 예시</p>
                        <div class="row">
                          <p class="col">예시1</p>
                          <p class="col">예시2</p>
                        </div>
                      </div>
                    </div>
                    <div class="rcs-content rcs-template-content">
                      <p class="rcs-template-content-title">예시 제목</p>
                      <p class="rcs-template-content-desc">예시 내용</p>
                    </div>
                    <div class="rcs-content rcs-thumbnail">
                      <ul>
                        <li>
                          <div class="thumb-img">
                            <img src="/" alt="">
                          </div>
                          <p>썸네일 내용 영역입니다. 썸네일 내용 영역입니다. 썸네일 내용 영역입니다. </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rcs-content rcs-button">
                      <b-button variant="secondary">버튼 영역</b-button>
                    </div>
                    <div class="rcs-content m-0">
                      <p class="m-0 f-cpation2 c-gray700">무료 수신거부 <span class="ml-1 pl-2 text-underline text-primary">010-0000-0000</span></p>
                    </div>
                  </div>
                  </PreviewDefault>
                </a>
              </li>
              <li>
                <a>
                  <PreviewDefault>
                  <p class="mt-0 mb-1 pb-2 f-body1 c-gray700">썸네일형(세로)</p>
                  <div class="preview-section rcs">
                    <p class="rcs-title">[Web 발신]</p>
                    <div class="preview-img">
                      <div class="empty-img">
                        <img src="@/assets/images/service/preview/preview-img.svg" alt="" class="img-icon">
                        <p class="m-0 mt-1 c-caption2 c-gray500">작성페이지에서 이미지를<br/>
                          삽입할 수 있습니다.</p>
                      </div>
                      <!-- <img src="" alt=""> -->
                    </div>
                    <div class="rcs-content rcs-type">
                      <div class="icon">
                        <IconShoppingCart />
                      </div>
                      <span>출고</span>
                    </div>
                    <div class="rcs-content rcs-col-content">
                      <p class="rcs-col-content-title">썸네일형</p>
                      <div>
                        <p>내용 입력 예시</p>
                        <div class="row">
                          <p class="col">예시1</p>
                          <p class="col">예시2</p>
                        </div>
                      </div>
                    </div>
                    <div class="rcs-content rcs-template-content">
                      <p class="rcs-template-content-title">예시 제목</p>
                      <p class="rcs-template-content-desc">예시 내용</p>
                    </div>
                    <div class="rcs-content rcs-thumbnail">
                      <ul>
                        <li>
                          <div class="thumb-img">
                            <img src="/" alt="">
                          </div>
                          <p>썸네일 내용 영역입니다. 썸네일 내용 영역입니다. 썸네일 내용 영역입니다. </p>
                        </li>
                      </ul>
                    </div>
                    <div class="rcs-content rcs-button">
                      <b-button variant="secondary">버튼 영역</b-button>
                    </div>
                    <div class="rcs-content m-0">
                      <p class="m-0 f-cpation2 c-gray700">무료 수신거부 <span class="ml-1 pl-2 text-underline text-primary">010-0000-0000</span></p>
                    </div>
                  </div>
                  </PreviewDefault>
                </a>
              </li>
              <div class="slide-control">
                <div class="control01">
                  <label for="slide06" class="left"></label>
                  <label for="slide02" class="right"></label>
                </div>
                <div class="control02">
                  <label for="slide01" class="left"></label>
                  <label for="slide03" class="right"></label>
                </div>
                <div class="control03">
                  <label for="slide02" class="left"></label>
                  <label for="slide04" class="right"></label>
                </div>
                <div class="control04">
                  <label for="slide03" class="left"></label>
                  <label for="slide05" class="right"></label>
                </div>
                <div class="control05">
                  <label for="slide04" class="left"></label>
                  <label for="slide06" class="right"></label>
                </div>
                <div class="control06">
                  <label for="slide05" class="left"></label>
                  <label for="slide01" class="right"></label>
                </div>
              </div>
            </ul>
            <ul class="slide-pagelist">
              <li>
                <label for="slide01"></label>
              </li>
              <li>
                <label for="slide02"></label>
              </li>
              <li>
                <label for="slide03"></label>
              </li>
              <li>
                <label for="slide04"></label>
              </li>
              <li>
                <label for="slide05"></label>
              </li>
              <li>
                <label for="slide06"></label>
              </li>
            </ul>
          </div>
        </div>
      </div>
</template>

<script>
import PreviewDefault from '@/components/service/preview/PreviewDefault.vue'
import IconShoppingCart from '@/components/service/icons/IconShoppingCart.vue';

export default {
  components: { PreviewDefault, IconShoppingCart, },
  name: 'RcsPreview',
}
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@use '@/assets/scss/service/message.scss';
@use '@/assets/scss/service/template.scss';

// 슬라이드 CSS
.preview-slide input[id*="slide"] {
  display: none;
}
.preview-slide .slidewrap {
  max-width: 370px;
  margin: 0 auto;
  overflow: hidden;
}
.preview-slide .slidelist {
  white-space: nowrap;
  font-size: 0;
}
.preview-slide ul {
  padding: 0;
  margin: 0;
}
.preview-slide .slidelist > li {
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  transition: all .5s;
}
.preview-slide .slidelist > li > a {
  display: block;
  position: relative;
  white-space: wrap;
}
.preview-slide .slidelist label {
  position: absolute;
  z-index: 10;
  top: 500px;
  transform: translateY(-50%);
  cursor: pointer;
  width: 44px;
  height: 44px;
}
.preview-slide .slidelist .left {
  left: 10px;
  background: url(../../../assets/images/service/icon/icon-circle-left.svg) center center / 100% no-repeat;
}
.preview-slide .slidelist .right {
  right: 10px;
  background: url(../../../assets/images/service/icon/icon-circle-right.svg) center center / 100% no-repeat;
}

.preview-slide .slide-control [class*="control"] {display: none;}
.preview-slide [id="slide01"]:checked ~ .slidewrap .control01 {display: block;}
.preview-slide [id="slide02"]:checked ~ .slidewrap .control02 {display: block;}
.preview-slide [id="slide03"]:checked ~ .slidewrap .control03 {display: block;}
.preview-slide [id="slide04"]:checked ~ .slidewrap .control04 {display: block;}
.preview-slide [id="slide05"]:checked ~ .slidewrap .control05 {display: block;}
.preview-slide [id="slide06"]:checked ~ .slidewrap .control06 {display: block;}

.preview-slide input[id="slide01"]:checked ~ .slidewrap .slidelist > li {transform: translateX(0%);}
.preview-slide input[id="slide02"]:checked ~ .slidewrap .slidelist > li {transform: translateX(-100%);}
.preview-slide input[id="slide03"]:checked ~ .slidewrap .slidelist > li {transform: translateX(-200%);}
.preview-slide input[id="slide04"]:checked ~ .slidewrap .slidelist > li {transform: translateX(-300%);}
.preview-slide input[id="slide05"]:checked ~ .slidewrap .slidelist > li {transform: translateX(-400%);}
.preview-slide input[id="slide06"]:checked ~ .slidewrap .slidelist > li {transform: translateX(-500%);}

.slide-pagelist {
  text-align: center;
  padding: 20px;
}
.slide-pagelist > li {
  display: inline-block;
  vertical-align: middle;
}
.slide-pagelist > li > label {
  display: block;
  padding: 6px 6px;
  border-radius: 30px;
  background: var(--gray200);
  margin: 16px 4px;
  cursor: pointer;
}

.preview-slide input[id="slide01"]:checked ~ .slidewrap .slide-pagelist > li:nth-child(1) > label {background: var(--primary300);}
.preview-slide input[id="slide02"]:checked ~ .slidewrap .slide-pagelist > li:nth-child(2) > label {background: var(--primary300);}
.preview-slide input[id="slide03"]:checked ~ .slidewrap .slide-pagelist > li:nth-child(3) > label {background: var(--primary300);}
.preview-slide input[id="slide04"]:checked ~ .slidewrap .slide-pagelist > li:nth-child(4) > label {background: var(--primary300);}
.preview-slide input[id="slide05"]:checked ~ .slidewrap .slide-pagelist > li:nth-child(5) > label {background: var(--primary300);}
.preview-slide input[id="slide06"]:checked ~ .slidewrap .slide-pagelist > li:nth-child(6) > label {background: var(--primary300);}

// 슬라이드 내용 끝


</style>
