import { render, staticRenderFns } from "./IpAlarmCard.vue?vue&type=template&id=f212604c&scoped=true"
import script from "./IpAlarmCard.vue?vue&type=script&lang=js"
export * from "./IpAlarmCard.vue?vue&type=script&lang=js"
import style0 from "./IpAlarmCard.vue?vue&type=style&index=0&id=f212604c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f212604c",
  null
  
)

export default component.exports