<template>  
  <b-modal id="select-template-modal" title="템플릿 선택" hide-header-close size="xl" centered content-class="select-template-modal">
    <div class="d-flex">
      <div class="section">
        <label for="template" class="f-body5 c-gray500">템플릿 검색</label>
        <b-input-group class="w-100">
          <b-form-input></b-form-input>
          <b-input-group-append>
            <b-button variant="dark" @click="fnSearch">조회</b-button>
          </b-input-group-append>
        </b-input-group>
        <div class="d-flex justify-content-between">
          <label class="f-body5 c-gray500">템플릿 명 (템플릿 제목)</label>
          <span class="f-body4 f-bold c-gray500">총 <span class="text-primary">{{totCnt}}건</span></span>
        </div>
        <div class="dropdown b-dropdown template-list">
          <ul class="dropdown-menu show">
            <li @click="fnGetTemplateInfo(idx, templateInfo.tmpltId)" v-for="(templateInfo, idx) in templateList" :key="templateInfo.tmpltId" class="bb-ddd pd5">
                      {{templateInfo.tmpltName | unescapeXss}}
                      <span v-if="!$gfnCommonUtils.isEmpty(templateInfo.tmpltTitle)">({{templateInfo.tmpltTitle | unescapeXss}})</span>
                    </li>
                    <li v-if="templateList.length == 0" class="bb-ddd pd5 text-center">검색된 내용이 없습니다.</li>
          </ul>
        </div>
      </div>
      <div class="section">
        <p class="m-0 mb-1 pb-3 f-body1 c-gray700">템플릿 내용</p>
        <dl class="template-content">
          <dt>발송유형</dt>
          <dd>{{templateData.senderType | lmsmms}}</dd>
        </dl>
        <dl class="template-content">
          <dt>메시지 구분</dt>
          <dd>{{templateData.msgKindName}}</dd>
        </dl>
        <dl class="template-content">
          <dt>템플릿 명</dt>
          <dd>{{templateData.tmpltName}}</dd>
        </dl>
        <dl class="template-content">
          <dt>내용</dt>
          <dd>{{templateData.tmpltContent}}</dd>
        </dl>
        <dl class="template-content" v-for="(imgInfo, idx) in templateData.imgInfoList" :key="imgInfo.fileId">
          <dt>이미지-{{idx+1}}</dt>
          <dd>{{imgInfo.imgUrl}}</dd>
        </dl>
      </div>
      <div class="section">
        <PreviewDefault>
          <!-- NOTE: 템플릿 내용 들어가는 부분 -->
          <div class="preview-section-title">웹 템플릿 등록 테스트입니다.</div>
        </PreviewDefault>
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="primary">템플릿 선택</b-button>
      <b-button variant="outline-primary" @click="closeModal">취소</b-button>
    </template>
  </b-modal>
</template>

<script>
import PreviewDefault from '@/components/service/preview/PreviewDefault.vue';
import templateApi from "@/modules/template/service/templateApi.js";
import confirm from "@/modules/commonUtil/service/confirm.js";

export default {
  components: {PreviewDefault, },
  name: "selectTemplateModal",
  data() {
    return {
      totCnt:0,
      templateList:[],
      templateData:{},
      searchData:{
        searchCondi: 'tmpltName',
        searchText: '',
        pageNo: 1,
        listSize: 999999999
      }
    }
  },
  mounted() {
    this.fnSearch();
  },
  methods: {
    fnResetData(){
      Object.assign(this.$data, this.$options.data());
    },
    //템플릿선택
    fnSelectTemplate(){
      if(Object.keys(this.templateData).length == 0){
        confirm.fnAlert(this.componentsTitle, '템플릿을 선택해주세요.');
        return;
      }
      this.$parent.fnSetTemplateInfo(this.templateData);
      this.fnClose();
    },
    //템플릿 리스트 선택
    fnGetTemplateInfo(idx, tmpltId){
      let tempData = {};
      const targetField = ['tmpltName', 'tmpltDesc', 'tmpltTitle', 'tmpltContent', 'rcvblcNumber'];
      if(this.templateList[idx].tmpltId == tmpltId){
        tempData = this.templateList[idx];
      } else {
        this.templateList.forEach(function(){
          if(this.tmpltId == tmpltId){
            tempData = this;
            return false;
          }
        });
      }
      if(tempData.imgInfoList && typeof(tempData.imgInfoList) === 'string'){
        tempData.imgInfoList = JSON.parse(tempData.imgInfoList);
      }
      this.$gfnCommonUtils.unescapeXssFields(tempData, targetField);
      this.templateData = Object.assign({}, tempData);
    },
    //템플릿 리스트 검색
    async fnSearch(){
      let params = {...this.searchData, searchSenderType: this.senderType};
      await templateApi.selectSmsTmpltList(params).then(response =>{
        var result = response.data;
        if(result.success) {
          this.templateList = result.data;
          this.totCnt = result.pageInfo.totCnt;
        } else {
          confirm.fnAlert(this.componentsTitle, result.message);
        }
      });
    },
    closeModal() {
      this.$bvModal.hide('select-template-modal');
    }
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/service/message.scss';
</style>
