<template>
    <div class="consoleMain" :style="mainStyles">
        <div class="noticeHead">
          공지사항
        </div>

            <div class="noticedetailwrap">
              <div class="noticedetailbox">
                <div class="noticedetailHead">
                  <span :class="{'noticeTag_detailguide': noticeData.noticeType === 'INFO', 
                                'noticeTag_detailcheck': noticeData.noticeType === 'CHECK',
                                'noticeTag_detailerror': noticeData.noticeType === 'ERROR',
                                'noticeTag_detailevent': noticeData.noticeType === 'EVENT'}">
                        {{ getNoticeTypeLabel(noticeData.noticeType) }}</span>
                  <span class="noticedetailtitle">{{noticeData.title | unescapeXss }}</span>
                </div>
                <div class="noticesubHead">
                  <span class="noticedetailwriter">운영자</span>
                  <span class="noticedetaildate">{{noticeData.regDt}}</span>
                </div>
                <div class="notice_hr"></div>
                <div>
                  <pre class="notice_content">
                  <p v-html="noticeData.content"></p>
                  </pre>
                </div>
              </div>
              <router-link :to="{ name: 'consoleNotice'}">
                <div class="listbutton">목록</div>
              </router-link>
            </div>
          
      </div>
</template>

<script>
import customerCenterApi from "@/modules/customerCenter/service/api.js";

export default {
  name: 'consoleNoticeDetail',
  props: {
    noticeId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      noticeData: {}
    }
  },
  async mounted() {
    // 페이지가 마운트되었을 때 공지사항 ID가 변경되었는지 확인하고 데이터를 가져옴
    await this.fnSelectNoticeInfo();
  },
  watch: {
    // 공지사항 ID가 변경되면 데이터를 다시 가져옴
    noticeId: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.fnSelectNoticeInfo();
        }
      }
    }
  },

  methods: {
    getNoticeTypeLabel(type) {
          switch(type) {
            case 'INFO':
              return '안내';
            case 'CHECK':
              return '점검';
            case 'ERROR':
              return '장애';
            case 'EVENT':
              return '행사';
            default:
              return type;
          }
    },

    async fnSelectNoticeInfo() {
      try {
        const response = await customerCenterApi.selectNoticeList({ noticeId: this.noticeId });
        const result = response.data;
        if (result.success && result.data.length > 0) {
          this.noticeData = result.data[0];
        } else {
          alert(result.message);
        }
      } catch (error) {
        console.error('Error fetching notice data:', error);
      }
    },
  }
}
</script>
