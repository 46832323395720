<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
    <path d="M2.66663 38.5V34.8333H43V38.5H2.66663ZM8.16663 33C7.15829 33 6.2951 32.641 5.57704 31.9229C4.85899 31.2049 4.49996 30.3417 4.49996 29.3333V9.16667C4.49996 8.15833 4.85899 7.29514 5.57704 6.57708C6.2951 5.85903 7.15829 5.5 8.16663 5.5H37.5C38.5083 5.5 39.3715 5.85903 40.0895 6.57708C40.8076 7.29514 41.1666 8.15833 41.1666 9.16667V29.3333C41.1666 30.3417 40.8076 31.2049 40.0895 31.9229C39.3715 32.641 38.5083 33 37.5 33H8.16663ZM8.16663 29.3333H37.5V9.16667H8.16663V29.3333Z" fill="#7C7DFA"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconComputer',
  };
</script>