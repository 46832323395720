import HomeMain from '../views/bm-homeMain.vue';
import Tracking from '../views/bm-tracking.vue';
import MessageSendMain from '../views/messageSendMain.vue';
import MultiSendListSample from '../views/multiSendList.vue';
import MultiSendMainSample from '../views/multiSendMain.vue';
//import Template from '../views/bm-template.vue';
import ChartSample from '../views/chartSample.vue';

export default [
	{
		path: '/ac/home',
		name: 'home',
		component: HomeMain,
		meta: {title: '현황판'},
	},

	{
		path: '/ac/tracking',
		name: 'tracking',
		component: Tracking
	},

	{
		path: '/ac/messageSendMain',
		name: 'messageSendMain',
		component: MessageSendMain
	},

	{
		path: '/ac/multiSendList',
		name: 'multiSendListSample',
		component: MultiSendListSample
	},

	{
		path: '/ac/multiSendMain',
		name :'multiSendMainSample',
		component: MultiSendMainSample
	},

	// {
	// 	path: '/ac/template',
	// 	name : 'template',
	// 	component : Template
	// },

	{
		path: '/ac/chartSample',
		name : 'chartSample',
		component : ChartSample
	}

]
