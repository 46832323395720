var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('dl',[_c('dt',[_vm._v("결과수신여부 "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",value:(_vm.msgTooltip),expression:"msgTooltip",modifiers:{"hover":true,"html":true}}],staticClass:"btn-icon p-0 btn-tooltip btn-tooltip-dark",attrs:{"variant":"light"}},[_c('IconQuestion')],1)],1),_c('dd',[_vm._v("사용")])]),_c('dl',[_c('dt',[_vm._v("중복체크 "),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.html",value:(_vm.msgTooltip),expression:"msgTooltip",modifiers:{"hover":true,"html":true}}],staticClass:"btn-icon p-0 btn-tooltip btn-tooltip-dark",attrs:{"variant":"light"}},[_c('IconQuestion')],1)],1),_c('dd',[_vm._v("내용 노출")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("API KEY 명")]),_c('dd',[_vm._v("내용 노출")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("API KEY")]),_c('dd',[_vm._v("GW_TEST_1")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("초당발송건수 TPS"),_c('br'),_vm._v(" 입력범위 0~100")]),_c('dd',[_c('div',{staticClass:"box"},[_c('span',[_vm._v("1,000")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("라인타입")]),_c('dd',[_c('div',{staticClass:"box"},[_c('span',[_vm._v("일반")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("IP 체크")]),_c('dd',[_vm._v("사용")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',{staticClass:"align-items-center"},[_c('dt',[_vm._v("고객 접속 IP")]),_c('dd',[_vm._v("100.99.*.*100.99.70.*49.170.27.3014.51.250.25100.99.72.*106.246.229.114164.124.106.143118.222.92.5164.124.106.146")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('dl',[_c('dt',[_vm._v("상태")]),_c('dd',[_vm._v("사용")])])
}]

export { render, staticRenderFns }