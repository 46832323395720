<template>
	<div class="consoleMain" :style="mainStyles">
		
		<div class="consoleMainTitle">
    	<h2>발신정보</h2>
    </div>
		<div class="tabContainer">
			<ul class="tabList">
			<li :class="{ active: tabButton == 1 }" @click="selectTab(1, true)">
				<a>문자 발신번호</a>
			</li>
			<li :class="{ active: tabButton == 2 }" @click="selectTab(2, true)">
				<a>RCS 발신번호</a>
			</li>
      <!-- <li :class="{ active: tabButton == 3 }" @click="selectTab(3, true)">
				<a>080 수신거부</a>
			</li> -->
			</ul>
			<div class="tabLine"></div>
		</div>
    <!-- <div class="templateFirstBox">
		</div> -->
			<!-- 발신번호관리 탭 -->
			<!-- <ul class="tabStyle tab6 bgColor_tapGray">
				<li :class="{active:(selMidTab == 1)}" @click="selMidTab=1; selSubTab=1"><a style="width:25%">발신번호 사전등록 안내</a></li>
				<li :class="{active:(selMidTab == 2)}" @click="selMidTab=2; selSubTab=2"><a style="width:25%">발신번호 등록</a></li>
				<li :class="{active:(selMidTab == 3)}" @click="selMidTab=3; selSubTab=3"><a style="width:25%">발신번호 조회</a></li>
			</ul> -->
			<callnum1 v-if="selMidTab==1&&selSubTab==1" @fnMoveTab="fnMoveTab"></callnum1>
			<callnum2 v-if="selMidTab==2&&selSubTab==2" @fnMoveMain="fnMoveMain"></callnum2>
			<callnum3 v-if="selMidTab==3&&selSubTab==3"></callnum3>
		
	</div>
</template>


<script>

// 발신번호관리
//import callbackManage     from "@/modules/project/components/bc-project-callback-manage.vue";
import callnum1     from "@/modules/sendInfo/components/bc-msgSendNumberList.vue";
import callnum2     from "@/modules/sendInfo/components/bc-sendInfo-callnum2.vue";
import callnum3     from "@/modules/sendInfo/components/bc-sendInfo-callnum3.vue";
//import messageSendHeader from '../components/messageSendHeader.vue';

//import sendInfoApi from '../service/sendInfoApi'
//import tokenSvc from '@/common/token-service';
import { mapState } from 'vuex';
export default {
  components: {
      callnum1
    , callnum2
    , callnum3
	//, messageSendHeader
  },
  computed: {
    ...mapState(['isSidebarOpen']),
    mainStyles() {
      return {
        width: this.isSidebarOpen ? '82%' : '100%',
        transition: 'width 0.5s ease',
        marginLeft: this.isSidebarOpen ? '18%' : '0', // 사이드바의 특정 너비가 있다면 조정
      };
    }
  },
  data() {
    return {
    	selMidTab   : 1 ,
      selSubTab   : 1 ,
		  tabButton	: 1
    }
  },
  created() {
    this.updateTabButton(this.$route.path);
  },
  watch: {
    '$route.path': function(newPath) {
      this.updateTabButton(newPath);
    }
  },
  mounted() {
    if( this.$route.params.selMidTab != null || this.$route.params.selMidTab != undefined ){
      this.selMidTab = this.$route.params.selMidTab;
    }
    if( this.$route.params.selSubTab != null || this.$route.params.selSubTab != undefined ){
      this.selSubTab = this.$route.params.selSubTab;
    }
    // 내부에서 새로그침시 프로젝트 화면으로 이동하게 처리
    // if( this.projectId === undefined || this.projectId === null ){
    //   this.$router.push( {name:'projectManage', params:{}} );
    // }

	//this.fnSelectProjectTabYn();
  },
  methods: {
	selectTab(tabNumber, isRedirect = false) {
      this.tabButton = tabNumber;
      if (isRedirect) {
        this.redirectToTemplateList(tabNumber);
      }
    },
    updateTabButton(path) {
      const routes = {
        "/ac/sendInfo/msgSendNumber": 1,
        "/ac/sendInfo/rcsSendNumber": 2,
        "/ac/sendInfo/rejct080Num": 3
      };
      this.tabButton = routes[path] || null;
    },
    redirectToTemplateList(tabNumber) {
      const routes = {
        1: "/ac/sendInfo/msgSendNumber",
        2: "/ac/sendInfo/rcsSendNumber",
        3: "/ac/sendInfo/rejct080Num"
      };
      const targetRoute = routes[tabNumber];
      if (this.$route.path !== targetRoute) {
        this.$router.push(targetRoute);
      }
    },
    fnMoveMain() {
      this.selMidTab = 1;
      this.selSubTab = 1;
      //this.selectTab(1, true) ;
    },
    fnMoveTab(tab) {
      this.selMidTab = tab
      this.selSubTab = tab
    }
  }
}
</script>