<template>
  <div class="uc-alimtalk-template-list list-view">
    <TemplateTabs />

    <div class="tab-contents">
      <div class="info card">
        <ul>
          <li>알림톡 메시지를 발송하기 위해서는 템플릿을 먼저 등록하고,검수 과정을 통해 승인 상태가 되어야 메시지 발송이 가능합니다.</li>
          <li>템플릿을 등록 (등록완료) 하시고 템플릿 상세에서 [검수요청] 하시면 검수 과정을 통해 템플릿이 처리(승인/반려) 됩니다.</li>
          <li>템플릿 코드를 클릭하시면 템플릿 상세를 보실 수 있고 수정도 가능합니다.([등록완료], [반려] 상태일 경우 수정 가능)</li>
          <li>템플릿 상태를 클릭하시면 템플릿 관련 문의를 할 수 있고 반려 상태라면 반려 메시지를 확인할 수 있습니다.</li>
          <li>알림톡 템플릿 삭제는 [등록완료] 상태일 경우 삭제 가능합니다. 삭제 할 템플릿을 선택 하시고 [삭제]버튼을 클릭하십시오.</li>
        </ul>
      </div>

      <!-- 검색영역 Start -->
      <div class="search-section card border-0">
        <div class="search-section-forms">
          <div class="d-flex align-items-center flex-wrap">
            <label>검색조건</label>
            <b-dropdown id="template-dropdown" variant="secondary" class="template-dropdown">
             
              <template #button-content>
                <span>{{ searchData.searchCondi === 'tmpltName' ? '템플릿명' : '템플릿키' }}</span>
                <IconArrowDown />
              </template>
              <b-dropdown-item @click="searchData.searchCondi = 'tmpltName';">템플릿명</b-dropdown-item>
              <b-dropdown-item @click="searchData.searchCondi = 'tmpltKey';">템플릿키</b-dropdown-item>
            </b-dropdown>
            <div class="search-group">
              <SearchInput v-model="searchData.searchText" @search="fnPageNoResetSearch"/>
            </div>

            <i class="vertical-divider"></i>
            <label>템플릿 상태</label>
            <b-form-group>
              <b-form-checkbox-group
                id="service-checkbox-group-template"
                v-model="statusSelected"
                :options="statusOptions"
                name="template-1"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
        </div>
        <b-button @click="fnPageNoResetSearch" variant="dark" class="btn-submit">검색</b-button>
      </div>
      <!-- 검색영역 End -->

      <!-- 리스트 영역 Start -->
      <div class="table-section card">
        <div class="d-flex align-items-center">
          <p class="list-count">전체 <span class="text-primary">{{totCnt | formatComma}}건</span></p>
          
          <b-dropdown id="pageCount-dropdown" variant="secondary" class="pageCount-dropdown">
            <template #button-content>
              <span>{{ pageCount }}개씩 보기</span>
              <IconArrowDown />
            </template>
            <b-dropdown-item-button @click="handlePageCountChange(10)">10개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(20)">20개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(30)">30개씩 보기</b-dropdown-item-button>
          </b-dropdown>
         
          <b-button variant="secondary" class="btn-svg btn-svg-right ml-auto" @click="navigateToManage">
            <span>템플릿 등록</span>
            <IconArrowRight />
          </b-button>

          <i class="vertical-divider"></i>
          <b-button @click="fnExcelDownLoad" variant="outline-primary" class="btn-svg btn-svg-right ml-2">
            <span>엑셀 다운로드</span>
            <IconArrowLineDown />
          </b-button>

        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
               
                <th class="text-center">
                  <b-form-checkbox
                    id="selectAll"
                    name="selectAll"
                    value="selectAll"
                    size="lg"
                  ></b-form-checkbox>
                </th>

                <th>
                  <div class="d-flex align-items-center">
                    NO.
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center"> <!-- NOTE: 정렬시 class명 추가하면 스타일 변경됨 -->
                    템플릿 코드
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    템플릿 키
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    채널
                    <IconSort class="down" />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    템플릿명
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    상태/문의
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    등록자
                    <IconSort />
                  </div>
                </th>
                <th>
                  <div class="d-flex align-items-center">
                    최종수정일자
                    <IconSort />
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(contant, idx) in contants" :key="idx">
                <td class="text-center">
                  <b-form-checkbox
                    :id=index
                    :name=index
                    :value=index
                    size="lg"
                  > 
                   <!-- <input v-if="contant.tmpltStatCode == 'T'" type="checkbox" :id="'listCheck_'+idx" name="listCheck_" class="boardCheckStyle" :value="contant.tmpltKey" v-model="listChkBox">
                      <input v-else type="checkbox" :id="'listCheck_'+idx" name="listCheck_" class="boardCheckStyle" :value="contant.tmpltKey" v-model="listChkBox" disabled="disabled" >
                      <label :for="'listCheck_'+idx"></label> -->
                    </b-form-checkbox>
                </td>
                <td>{{totCnt-offset-contant.rownum+1 | formatComma}}</td>
                <td>
                  <span @click="navigateToManage(contant)" class="text-underline">{{contant.tmpltCode}}</span>
                </td>
                <td>{{contant.tmpltKey}}</td>
                <td>{{contant.kkoChId}}</td>
                <td>{{contant.tmpltName}}</td>
                <td v-if="(contant.tmpltStatCode == 'S' && contant.existsRejResnYn == 'Y') || (contant.tmpltStatCode == 'A' && contant.transCnt == 0)">
                      <u v-if="contant.tmpltStatCode == 'S'"><a
                        href="#"
                        @click.prevent="fnOpenAlimTalkRejectReasonLayer(contant.tmpltKey)"
                      >{{contant.tmpltStatCodeName}}</a></u>
                      <u v-else><a
                        href="#"
                        @click.prevent="fnOpenAlimTalkTransLayer(contant.tmpltCode)"
                      >{{contant.tmpltStatCodeName}}</a></u>
                    </td>
                    <td v-else>
                      {{contant.tmpltStatCodeName}}
                    </td>
                <td>{{contant.regNm}}</td>
                <td>{{contant.updDt}}</td>
              </tr>
              <tr v-if="contants.length == 0">
                    <td class="text-center">
                      <input type="checkbox" id="listCheck_0" class="boardCheckStyle">
                      <label for="listCheck_0"></label>
                    </td>
                    <td class="text-center" colspan="7">검색된 내용이 없습니다.</td>
                  </tr>
            </tbody>
          </table>

          <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"/>
        </div>
      </div>
      <!-- 리스트 영역 End -->

      <p>모달 예시입니다.<br/>
      데이터 연동시 상태에 따라 모달이 오픈되는 조건이 달라서 예시로 버튼 넣었습니다.</p>
      <div class="d-flex align-items-center">
        <b-button variant="outline-primary" @click="openTalkReturnModal">알림톡 반려 사유 모달</b-button>
        <b-button variant="outline-primary" @click="openTalkStatusModal">승인상태 상세 모달</b-button>
        <b-button variant="outline-primary" @click="openMoveRequestModal">운영이관 요청 모달</b-button>
      </div>
    </div>

    <AlertModal title="알림톡 반려 사유" desc="<p class='m-0 f-body3 c-gray500'>안녕하세요. 카카오톡 알림톡 검수 담당자입니다.<br/><br/>알림톡은 정보성 메시지에 한하여 발송이 가능하오나 해당 메시지 내용 확인 시 발송 목적이 확인되는 문구가 존재하지 않아 정보성 메시지인지 확인이 어렵습니다. 따라서 발송 목적이 확인되도록 메시지 수정 및 고정값 추가 요청드립니다.<br/>*테스트 메시지일 경우 메시지 내 ‘테스트(test)’를 고정값으로 추가 요청 드립니다.<br/><br/>더 자세한 알림톡 검수 가이드는 딜러사를 통해 확인 부탁드립니다. 감사합니다.</p>" />
    <TalkStatusModal/>
    <TalkMoveRequestModal />
  </div>
</template>

<script>
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import TemplateTabs from '@/components/service/template/TemplateTabs.vue'
import SearchInput from '@/components/service/form/SearchInput.vue'
import IconSort from '@/components/service/icons/IconSort.vue';
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue';
import Pagination from '@/components/service/Pagination.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue'
import IconArrowLineDown from '@/components/service/icons/IconArrowLineDown.vue';
import TalkStatusModal from '@/modules/template/components/modal/TalkStatusModal'
import TalkMoveRequestModal from '@/modules/template/components/modal/TalkMoveRequestModal'
import AlimTalkRejectReasonLayer from "@/modules/template/components/bp-alimTalkRejectReason.vue";
import AlimTalkTransLayer from "@/modules/template/components/bp-alimTemplateTransPop.vue";
import templateApi from "@/modules/template/service/templateApi.js";
import commonApi from "@/modules/commonUtil/service/commonUtilApi.js"
import {eventBus} from "@/modules/commonUtil/service/eventBus";

export default {
  components: {  
    IconArrowDown, SearchInput, Pagination, IconSort, TemplateTabs, IconArrowRight, AlertModal, IconArrowLineDown, TalkStatusModal, TalkMoveRequestModal, },
  name: "alimTalkTemplateList",
  props: {
    searchData : {
      type: Object,
      require: false,
      default: function() {
        return {
          searchCondi: 'tmpltName',
          searchText: '',
          searchTmpltStatCodes: []
        }
      }
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function() {
        return '알림톡 템플릿';
      }
    },
  },
  data() {
    return {
      statusSelected: [],
      statusOptions: [
        { text: '전체', value: 'all' },
        { text: '등록완료', value: 'complete' },
        { text: '승인대기', value: 'wait' },
        { text: '검수시작', value: 'start' },
        { text: '승인', value: 'allow' },
        { text: '반려', value: 'notallow' },
        { text: '저장', value: 'save' },
        { text: '해지', value: 'cancel' },
      ],
      pageCount: 10,
      alimTalkRejectReasonOpen : false,
      rejectTmpltKey: '',
      tmpltStatCodeAllSelected: true,
      listAllChecked: false,
      listChkBox: [],
      searchDateInterval: 7,
      tmpltStatCodeList : [],
      listSize : 10,  // select 박스 value (출력 갯수 이벤트)
      pageNo : 1,  // 현재 페이징 위치
      totCnt : 0,  //전체 리스트 수
      offset : 0, //페이지 시작점
      contants: [],
      popReset : 0,
			rowData : {},
    }
  },
  async mounted() {
    await this.fnExistApiKey();
    this.fnSelectTmpltStatCodeList();
    this.fnPageNoResetSearch();
  },
  methods: {
    navigateToManage(contant) {
      this.$router.push({ name: 'alimTalkTemplateManage', params: { tmpltKey: contant.tmpltKey } });
    },
    openTalkReturnModal() {
      this.$bvModal.show('alert-modal');
    },
    openTalkStatusModal() {
      this.$bvModal.show('talk-status-modal');
    },
    openMoveRequestModal() {
      this.$bvModal.show('talk-move-request-modal');
    },
    handlePageCountChange(count) {
    this.pageCount = count;
    this.fnSelected(count); 
  },
    async fnExistApiKey(){
      let params = {};
      await templateApi.selectApiKey(params).then(response =>{
        const result = response.data;
        if(result.success) {
          if(this.$gfnCommonUtils.isEmpty(result.data)){
            confirm.fnAlert(this.componentsTitle, '해당 프로젝트의 사용가능한 API 키가 존재하지 않습니다.\n템플릿을 등록/수정/검수요청 하실 수 없습니다.');
          }
        }
      });
    },
    async fnValidUseChGrp(){
      let params = {chGrp: 'KKO'};
      await templateApi.selectValidUseChGrp(params).then(response =>{
        const result = response.data;
        if(result.success) {
          if(this.$gfnCommonUtils.isEmpty(result.data)){
            confirm.fnAlert(this.componentsTitle, '이용하실 수 없는 채널입니다.');
            this.$router.back();
          }
        } else {
          confirm.fnAlert(this.componentsTitle, '시스템 오류입니다. 잠시 후 다시 시도하세요.');
          this.$router.back();
        }
      });
    },
    fnOpenAlimTalkRejectReasonLayer(rejectTmpltKey){
      this.rejectTmpltKey = rejectTmpltKey;
      this.alimTalkRejectReasonOpen = true;
    },
    //운영이관 팝업
    fnOpenAlimTalkTransLayer(tmpltCode){
      var vm = this;
      vm.popReset++;
      vm.rowData  = {
				tmpltCode:	tmpltCode
			}
			jQuery("#smsConfirm").modal("show");

      //this.rejectTmpltKey = rejectTmpltKey;
     // this.alimTalkTransOpen = true;
    },
    //템플릿 엑셀 다운로드
    fnExcelDownLoad(){
      const params = this.searchData;
      templateApi.excelDownloadAlimTalkTmplt(params);
    },
    fnDeleteReqTmplt(){
      //유효성 검사
      if(this.listChkBox == null || this.listChkBox.length == 0){
        confirm.fnAlert(this.componentsTitle, '삭제할 항목을 선택해주세요.');
        return;
      }

      eventBus.$on('callbackEventBus', this.fnProcDeleteReqTmplt);
      confirm.fnConfirm(this.componentsTitle, "선택한 템플릿을 삭제요청 하시겠습니까?", "확인");
    },
    async fnProcDeleteReqTmplt(){
      var params = {tmpltKeys : this.listChkBox};
      await templateApi.procDeleteRequestKkoTmplt(params).then(response =>{
        var result = response.data;
        confirm.fnAlert(this.componentsTitle, result.message);
        if(result.success) {
          this.listAllChecked = false;
          this.fnListChkAll();
          this.fnPageNoResetSearch();
        }
      });
    },
    fnUpdateStartDate(sltDate) {
      this.searchData.searchStartDate = sltDate;
    },
    fnUpdateEndDate(sltDate) {
      this.searchData.searchEndDate = sltDate;
    },
    fnSearchtmpltStatCodeChkAll(){
      const vm = this;
      this.searchData.searchTmpltStatCodes = [];
      if(this.tmpltStatCodeAllSelected){
        this.tmpltStatCodeList.forEach(function(codeInfo){
          vm.searchData.searchTmpltStatCodes.push(codeInfo.codeVal1);
        });
      }
    },
    async fnSelectTmpltStatCodeList(){
      let params = {
        codeTypeCd: 'TMPLT_STAT_CODE',
        useYn: 'Y'
      };
      await commonApi.selectCodeList(params).then(response =>{
        const result = response.data;
        if(result.success) {
          this.tmpltStatCodeList = [];
          let tempStatCodeList = result.data
            //R-검수중(롯데), Q-검수중(카카오) => (업체) 제거 요청
            .map((code) => {
              let codeVal = code.codeVal1;
              let codeName = code.codeName1;
              if(codeVal === 'R' || codeVal === 'Q'){
                codeVal = 'RQ';
                codeName = '검수중'
              }
              return { ...code, codeVal1: codeVal, codeName1: codeName };
            })
            //D-삭제 미노출 및 R-검수중(롯데), Q-검수중(카카오) 검수중 중복제거
            .filter((code, idx, callback) => {
              return code.codeVal1 !== 'D' && idx === callback.findIndex(t => t.codeVal1 === code.codeVal1)
            });

          this.tmpltStatCodeList = Object.assign([], tempStatCodeList);
          this.fnSearchtmpltStatCodeChkAll();
        } else {
          confirm.fnAlert(this.componentsTitle, result.message);
        }
      });
    },
    async fnSelectAlimTalkTmpltList(){
      //유효성 검사
      if(this.searchData.searchStartDate && this.searchData.searchEndDate){
        if(this.searchData.searchStartDate.replace(/[^0-9]/g, '') > this.searchData.searchEndDate.replace(/[^0-9]/g, '')){
          confirm.fnAlert(this.componentsTitle, '시작일은 종료일보다 클 수 없습니다.');
          return false;
        }
      }
      this.contants = [];
      let params = Object.assign({}, this.searchData);
      params.pageNo = this.pageNo;
      params.listSize = this.listSize;
      params.selCnt = this.searchData.searchTmpltStatCodes.length;
      await templateApi.selectAlimTalkTmpltList(params).then(response =>{
        const result = response.data;
        if(result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          confirm.fnAlert(this.componentsTitle, result.message);
        }
      });
    },
    //검색일자변경
    fnSetIntervalSearchDate(interval){
      this.searchDateInterval = interval;
      this.searchData.searchEndDate = this.$gfnCommonUtils.getCurretDate();
      this.searchData.searchStartDate = this.$gfnCommonUtils.strDateAddDay(this.searchData.searchEndDate, -this.searchDateInterval);
    },
    //리스트 전체 체크박스
    fnListChkAll(){
      const vm = this;
      if(this.listAllChecked){
        this.contants.forEach(function(contant){
          if(contant.tmpltStatCode == 'T'){
            vm.listChkBox.push(contant.tmpltKey);
          }
        });
      } else {
        this.listChkBox = [];
      }
    },
    fnSelected(listSize) {
      this.listSize = Number(listSize);
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnPageNoResetSearch(){
      this.$refs.updatePaging.fnAllDecrease();
    },
    fnSearch(pageNo) {
      this.pageNo = (this.$gfnCommonUtils.defaultIfEmpty(pageNo, '1'))*1;
      this.fnSelectAlimTalkTmpltList();
    }
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@import '@/assets/scss/service/message.scss';
@import '@/assets/scss/service/template.scss';

.template-dropdown {
  min-width: 160px;
  margin: 0 16px;
}
.search-group {
  width: 355px;
  margin-right: 20px;
}
.form-group {
  margin-left: 24px;
  margin-bottom: 0;
}
.vertical-divider {
  width: 1px;
  height: 16px;
  margin: 0 20px;
  background: var(--border-color);
}
.list-view .pageCount-dropdown {
  margin-right: auto;
}
.table td {
  vertical-align: middle;
}
</style>
