<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="8" fill="#9CA3AF"/>
    <path d="M9.92578 5.03125L9.92578 11.6357" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M9.92578 15.8848C10.5471 15.8848 11.0508 15.3811 11.0508 14.7598C11.0508 14.1384 10.5471 13.6348 9.92578 13.6348C9.30446 13.6348 8.80078 14.1384 8.80078 14.7598C8.80078 15.3811 9.30446 15.8848 9.92578 15.8848Z" fill="white"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconInfo',
  };
</script>