var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"message-tabs-wrap"},[_c('div',{staticClass:"message-tabs"},[_c('div',{class:['tab', _vm.isActive('/ac/alarm/alarm'), 
                          _vm.isActive('/ac/alarm/alarm/success'), 
                          _vm.isActive('/ac/alarm/alarm/inflow'), 
                          _vm.isActive('/ac/alarm/alarm/ip'), 
                          _vm.isActive('/ac/alarm/alarm/prepayment'), 
                          _vm.isActive('/ac/alarm/alarm/limits'), 
                          _vm.isActive('/ac/alarm/alarm/average')]},[_c('router-link',{attrs:{"to":"/ac/alarm/alarm"}},[_vm._v("알람")])],1),_c('div',{class:['tab', _vm.isActive('/ac/alarm/receptGroup')]},[_c('router-link',{attrs:{"to":"/ac/alarm/receptGroup"}},[_vm._v("수신그룹")])],1),_c('div',{class:['tab', _vm.isActive('/ac/alarm/recipient')]},[_c('router-link',{attrs:{"to":"/ac/alarm/recipient"}},[_vm._v("수신자")])],1)]),_c('p',{staticClass:"breadcrumb f-body5 c-gray700"},[_vm._v("알람 > "+_vm._s(_vm.breadCrumbTitle))])])
}
var staticRenderFns = []

export { render, staticRenderFns }