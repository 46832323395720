<template>
	<div class="modal modalStyle in" id="corpInfoPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
		<div class="modal-dialog">
			<div class="modal-content">
				<div class="modal-body">
					<div class="of_h">
						<h2>고객사 정보</h2>
						<hr>
						<h4>사업자 정보</h4>
						<div class="border-line mt20 Dashboard06">
							<div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">사업자번호</h5>
								<input type="text" class="inputStyle float-right" style="width:75%" title="사업자번호 입력란" v-model="regno" disabled>
							</div>
							<div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">사업자명</h5>
								<input type="text" class="inputStyle float-right" style="width:75%" title="사업자명 입력란" v-model="corpName" disabled>
							</div>
							<div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">대표자명</h5>
								<input type="text" class="inputStyle float-right" style="width:75%" title="대표자명 입력란" v-model="ceoName" disabled>
							</div>
							<div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">사업장주소</h5>
								<input type="text" class="inputStyle" style="width:20%" title="우편번호 입력란" placeholder="우편번호" disabled v-model="zipcode">
								<!-- <a href="#self" class="btnStyle1 backLightGray float-right" title="주소조회">주소조회</a> -->
								<input type="text" class="inputStyle float-right" style="width:52%" title="사업장 주소 입력란" v-model="woplaceAddress" placeholder="주소" disabled>
								<input type="text" class="inputStyle consolMarginTop float-right" style="width:75%" title="사업장 상세주소 입력란" v-model="woplaceAddressDetail" placeholder="상세주소" disabled>
							</div>
							<div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">사업자등록증</h5>
								<input type="text" class="inputStyle" style="width:53%" title="첨부파일" v-model="attachFileName" disabled>
								<input type="hidden" v-model="attachFilePath">
								<input type="hidden" v-model="fileId">
								<a href="#self" @click.prevent="fnFileDown(fileId)" class="btnStyle1 backLightGray float-right" title="다운로드">다운로드</a>
							</div>
              <!-- <div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">통신사실 증명원</h5>
								<input type="text" class="inputStyle" style="width:53%" title="첨부파일" v-model="attachFileName1" disabled>
								<input type="hidden" v-model="attachFilePath1">
								<input type="hidden" v-model="fileId1">
								<a href="#self" @click.prevent="fnFileDown(fileId1)" class="btnStyle1 backLightGray float-right" title="다운로드">다운로드</a>
							</div> -->
              <div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">대리인 재직증명서</h5>
								<input type="text" class="inputStyle" style="width:53%" title="첨부파일" v-model="attachFileName2" disabled>
								<input type="hidden" v-model="attachFilePath2">
								<input type="hidden" v-model="fileId2">
								<a href="#self" @click.prevent="fnFileDown(fileId2)" class="btnStyle1 backLightGray float-right" title="다운로드">다운로드</a>
							</div>
              <!-- <div v-show="attachFileName3 != ''" class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">대리인 위임장</h5>
								<input type="text" class="inputStyle" style="width:53%" title="첨부파일" v-model="attachFileName3" disabled>
								<input type="hidden" v-model="attachFilePath3">
								<input type="hidden" v-model="fileId3">
								<a href="#self" @click.prevent="fnFileDown(fileId3)" class="btnStyle1 backLightGray float-right" title="다운로드">다운로드</a>
							</div> -->

							<div class="of_h consolMarginTop">
								<h5 class="inline-block" style="width:25%">영업사원</h5>
								<select class="selectStyle2" style="width:75%" title="영업사원 선택란" v-model="salesMan" disabled>
									<option value="">선택하세요</option>
									<option  v-for="(row, index) in salesManArr" :key="index" :value="row.codeVal1"> {{ row.codeName1 }} </option>
								</select>
							</div>
						</div>
					</div>
					<div class="text-center mt40">
						<a href="#self" @click.prevent="fnCloseLayer" class="btnStyle2 backWhite ml10" title="닫기">닫기</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import myPageApi from '@/modules/myPage/service/myPageApi';

import commonUtilApi from "@/modules/commonUtil/service/commonUtilApi";

export default {
  name: 'corpInfoPopup',
  data() {
    return {
      corpId :"",
      regno : '',                   // 사업자번호
      corpName : '',                // 사업자명
      ceoName : '',                 // ceo명
      // busitype : '',                // 업태
      // busiclass : '',               // 종목
      zipcode :"",                  // 우편번호
      woplaceAddress : '',          //사업장주소
      woplaceAddressDetail : '',    //사업장 상세주소
      // wireTel : '',                 // 유선전화번호
      // domainName : '',              // 도메인 명
      fileId : "",
      attachFileName : '',          // 사업자등록증 이미지명
      attachFilePath : '',          // 사업자등록증 이미지경로

      fileId1 : "",
      // attachFileName1 : '',          // 통신사실 증명원 이미지명
      // attachFilePath1 : '',          // 통신사실 증명원 이미지경로

      fileId2 : "",
      attachFileName2 : '',          // 대리인 재직증명서 or 신분증 이미지명
      attachFilePath2 : '',          // 대리인 재직증명서 or 신분증 이미지경로

      fileId3 : "",
      // attachFileName3 : '',          // 대리인 위임장 이미지명
      // attachFilePath3 : '',          // 대리인 위임장 이미지경로

      custNo : "",                  // 고객사 번호
      // custKdCd : "",                // 고객 유형
      // custrnmNo : "",               // 고객 식별 번호
      salesMan : "",                // 영업사원

      // custTypeArr : [],              // 고객 유형 코드값
      salesManArr : []
    }
  },
  props: {
    corpInfo : {
      type : Object,
      require : true
    },
    popReset: {
      type: Number
    }
  },
  mounted() {
    this.fnReset();
    // this.fnGetCustType();
    this.fnGetSalesMan();
  },
  watch: {
    popReset(){
      this.fnReset();
    },
    // wireTel(){
    //   if(this.wireTel != undefined){
    //     return this.wireTel = this.wireTel.replace(/[^0-9]/g, '');
    //   }
    // }
  },
  methods: {
    fnCloseLayer(){
        jQuery("#corpInfoPopup").modal("hide");
    },
    // 고객 유형 코드 값 조회
    fnGetCustType(){
      var params = {
        codeTypeCd	: "CORP_CUST_TYPE",
        useYN		: "Y"
      };
      commonUtilApi.selectCodeList(params).then(response =>{
        var result = response.data;
        if(result.success){
          this.custTypeArr = result.data;
        }
      });
    },
    fnGetSalesMan(){
      var params = {
        codeTypeCd	: "SALES_MAN",
        useYN		: "Y"
      };
      commonUtilApi.selectCodeList(params).then(response =>{
        var result = response.data;
        if(result.success){
          this.salesManArr = result.data;
        }
      });
    },
    // 데이터 초기화
    fnReset(){
      this.corpId = this.corpInfo.corpId;
      this.regno = this.corpInfo.regno;
      this.corpName = this.$gfnCommonUtils.unescapeXss(this.corpInfo.corpName);
      this.ceoName = this.$gfnCommonUtils.unescapeXss(this.corpInfo.ceoName);
      // this.busitype = this.corpInfo.busitype;
      // this.busiclass = this.corpInfo.busiclass;
      this.zipcode = this.corpInfo.zipcode;
      this.woplaceAddress = this.$gfnCommonUtils.unescapeXss(this.corpInfo.woplaceAddress);
      this.woplaceAddressDetail = this.$gfnCommonUtils.unescapeXss(this.corpInfo.woplaceAddressDetail);
      // this.wireTel = this.corpInfo.offcTel;
      this.fileId = this.corpInfo.fileId;
      this.attachFileName = this.corpInfo.attachFileName;
      this.attachFilePath = this.corpInfo.attachFilePath;

      this.fileId1 = this.corpInfo.fileId1;
      // this.attachFileName1 = this.corpInfo.attachFileName1;
      // this.attachFilePath1 = this.corpInfo.attachFilePath1;

      this.fileId2 = this.corpInfo.fileId2;
      this.attachFileName2 = this.corpInfo.attachFileName2;
      this.attachFilePath2 = this.corpInfo.attachFilePath2;

      this.fileId3 = this.corpInfo.fileId3;
      // this.attachFileName3 = this.corpInfo.attachFileName3;
      // this.attachFilePath3 = this.corpInfo.attachFilePath3;

      // this.custKdCd = this.corpInfo.custKdCd;
      // this.custrnmNo = this.corpInfo.custrnmNo;
      this.custNo = this.corpInfo.custNo;
      // this.domainName = this.corpInfo.domainName;
      this.salesMan = !this.$gfnCommonUtils.isEmpty(this.corpInfo.salesId) ? this.corpInfo.salesId : "";
    },
    // 사업자 등록증 다운로드
    fnFileDown(fileId) {
      if(fileId != "") {
        var params = {
            fileId    : fileId
        };
        myPageApi.downloadRegCardImage(params);
      }
    },
  }
}
</script>