<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M10.0003 9.18815C11.8413 9.18815 13.3337 7.69577 13.3337 5.85482C13.3337 4.01387 11.8413 2.52148 10.0003 2.52148C8.15938 2.52148 6.66699 4.01387 6.66699 5.85482C6.66699 7.69577 8.15938 9.18815 10.0003 9.18815Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M17.5 19.1895C17.5 15.0473 14.1421 11.6895 10 11.6895C5.85787 11.6895 2.5 15.0473 2.5 19.1895" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconUser',
  };
</script>