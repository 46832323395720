<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M18.3332 2.61914L9.1665 11.7858" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.3332 2.61914L12.4998 19.2858L9.1665 11.7858L1.6665 8.45247L18.3332 2.61914Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconSend',
  };
</script>