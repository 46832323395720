<template>
  <div class="chart-wrapper">
    <div class="chart-container">
      <radar-chart :chart-data="chartData" :chart-options="chartOptions"></radar-chart>
    </div>
  </div>
</template>

<script>
import { Radar } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, RadialLinearScale, PointElement, LineElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, RadialLinearScale, PointElement, LineElement);

export default {
  name: 'RadarChart',
  components: {
    'radar-chart': Radar,
  },
  data() {
    return {
      chartData: {
        labels: ['SMS', 'KAKAO', 'RCS', 'PUSH'],
        datasets: [
          {
            label: 'January',
            backgroundColor: 'rgba(179,181,198,0.2)',
            borderColor: 'rgba(179,181,198,1)',
            pointBackgroundColor: 'rgba(179,181,198,1)',
            data: [65, 59, 90, 81],
          },
          {
            label: 'February',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            pointBackgroundColor: 'rgba(255,99,132,1)',
            data: [28, 48, 40, 19],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom', // 범례를 차트의 왼쪽에 위치시킵니다.
            labels: {
              boxWidth: 20,
              padding: 20,
              usePointStyle: true,
            },
          },
        },
        layout: {
          padding: {
            left: 100, // 범례를 위한 충분한 패딩 확보
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
        scales: {
          r: {
            ticks: {
              display: false,
            },
            grid: {
              circular: false, // 마름모꼴로 설정
            },
          },
        },
      },
    };
  },
};
</script>

<style scoped>
.chart-wrapper {
  display: flex;
  justify-content: flex-start; /* 차트와 범례를 왼쪽으로 정렬 */
  align-items: center; /* 세로 중앙 정렬 */
  height: 100%; /* 차트 높이 조정 */
  width: 100%; /* 전체 너비 조정 */
}

.chart-container {
  flex-grow: 1;
  max-width: 600px; /* 차트 너비 제한 */
  height: 100%; /* 차트 높이 조정 */
}
</style>
