<template>
  <div>
    <BcBookingSend />
  </div>
</template>

<script>
import BcBookingSend from '../components/bc-booking-send.vue';

export default {
  components: {
    BcBookingSend
  }
}
</script>