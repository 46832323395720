import Vue from 'vue';
import Router from 'vue-router';
import searchcondition from './store'
import tokenSvc from '@/common/token-service';
import confirm from '@/modules/commonUtil/service/confirm';

// import WebNaviLayout from './views/WebNaviLayout.vue';
// import WebUcNaviLayout from './views/WebUcNaviLayout.vue';
// import LoginLayout from './views/LoginLayout.vue';
import PublicLayout from './views/publicLayout.vue';
import PublicLayoutNot from './views/publicLayoutNot.vue';
import signUpRoutes from './modules/signUp/router';
import loginRoutes from './modules/login/router';
import ServiceLayout from './components/service/ServiceLayout.vue';

import mainRoutes from './modules/main/router';
import acHomeRoutes from './modules/acHome/router';
import userRoutes from './modules/user/router';
import messageRoutes from './modules/message/router';
import channelRoutes from './modules/channel/router';
import apiKeyRoutes from './modules/apiKey/router';
import customerCenterRoutes from './modules/customerCenter/router';
import rcsTemplateSendRoutes from './modules/rcsTemplateSend/router';
import messageStatusRoutes from './modules/messageStatus/router';
import cashRoutes from './modules/cash/router';
import addressRoutes from './modules/address/router';

import templateRoutes from './modules/template/router';
import sendInfoRoutes from './modules/sendInfo/router';
import sendLimitRoutes from './modules/sendLimit/router';

import statisticsUserRoutes from './modules/statisticsUser/router';

import myPageRoutes from './modules/myPage/router';
import useStatusRoutes from './modules/use/router';
import alarmRoutes from './modules/alarm/router';

Vue.use(Router)


const requireAuth = () => (to, from, next) => {
	if (tokenSvc.getToken().principal.svcTypeCd == 'BO' && tokenSvc.getToken().principal.role !== 'GUEST') {
		confirm.fnAlert("", "Back Ofiice 계정으로는 Console 로그인이 불가합니다.");
		return next('/login');
	}
	next();

	// 안씀
	// if (tokenSvc.getToken().principal.svcTypeCd == 'UC') {
	// 	confirm.fnAlert("", "User 권한 사용자는 Admin Console 사용이 불가합니다.");
	// 	return next('/uc/home');
	// }
	// next();
};

const requireAuthLogin = () => (to, from, next) => {
	if (tokenSvc.getToken() !== null) {
		const svcTypeCd = tokenSvc.getToken().principal.svcTypeCd;
		if (svcTypeCd == 'UC' || svcTypeCd == 'AC') {
			return next('/ac/home');
		}
	}
	next();
};

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			redirect: '/public/main'
		},
		{
			path: '/public',
			component: PublicLayout,
			children: [
				{
					path: '/view/error/404',
					component: () => import('./views/ErrorPage404.vue'),
					meta: { public: true }
				},
				{
					path: '/view/error/500',
					component: () => import('./views/ErrorPage500.vue'),
					meta: { public: true }
				},
				...mainRoutes
			]
		},
		{
			path: '/sign',
			component: PublicLayout,
			children: [
				{
					path: '/view/error/404',
					component: () => import('./views/ErrorPage404.vue'),
					meta: { public: true }
				},
				{
					path: '/view/error/500',
					component: () => import('./views/ErrorPage500.vue'),
					meta: { public: true }
				},
				...signUpRoutes
			]
		},
		{
			path: '/not',
			component: PublicLayoutNot
		},
		{
			path: '/login',
			component: PublicLayout,
			beforeEnter: requireAuthLogin(),
			children: [
				{
					path: '/view/error/404',
					component: () => import('./views/ErrorPage404.vue'),
					meta: { public: true }
				},
				{
					path: '/view/error/500',
					component: () => import('./views/ErrorPage500.vue'),
					meta: { public: true }
				},
				...loginRoutes
			]
		},
		{
			path: '/ac',
			component: ServiceLayout,
			beforeEnter: requireAuth(),
			children: [
				{
					path: '/view/error/404',
					component: () => import('./views/ErrorPage404.vue'),
					meta: { public: true }
				},
				{
					path: '/view/error/500',
					component: () => import('./views/ErrorPage500.vue'),
					meta: { public: true }
				},
				...acHomeRoutes,
				...userRoutes,
				...apiKeyRoutes,
				...customerCenterRoutes,
				...channelRoutes,
				...sendInfoRoutes,
				...sendLimitRoutes,
				...cashRoutes,
				...addressRoutes,
				...statisticsUserRoutes,
				...myPageRoutes,
				...useStatusRoutes,
				...alarmRoutes,
			],
		},
		{
			path: '/uc',
			component: ServiceLayout,
			beforeEnter: requireAuth(),
			children: [
				{
					path: '/view/error/404',
					component: () => import('./views/ErrorPage404.vue'),
					meta: { public: true }
				},
				{
					path: '/view/error/500',
					component: () => import('./views/ErrorPage500.vue'),
					meta: { public: true }
				},
				...messageRoutes,
				...templateRoutes,
				...messageStatusRoutes,
				...rcsTemplateSendRoutes,
			],
		},

		{ path: '*', redirect: '/view/error/404' }
	],

	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
		// 이전에 저장된 스크롤 위치로 이동 (예: 브라우저의 뒤로 가기 버튼을 눌렀을 때)
		return savedPosition;
		} else {
		// 새로운 라우트로 이동할 때 맨 위로 스크롤
		return { x: 0, y: 0 };
		}
	},
});

router.beforeEach((to, from, next) => {
	const isPublic = to.matched.some(record => record.meta.public);
	const loggedIn = !!tokenSvc.getToken();

	if (!isPublic && !loggedIn) {
		return next('/login');
	}

	to.matched.some(record => {
		if (record.meta.usingSearchCondition) {
			const shareList = record.meta.shareList;
			if (from.name && shareList && shareList.includes(from.name)) {
				// shareList에 포함되어 있는 라우터에서 온 경우 검색 조건을 유지한다.
			} else {
				// 그 외의 경우 검색 조건 초기화
				searchcondition.commit("searchcondition/updateSearchCondition", null);
			}
		}
		next();
	});
});

var timeout = 1500;

router.afterEach((to) => {
  if (!to.meta.menu) {
    timeout = 0;
    return;
  }

  Vue.nextTick(() => {
    setTimeout(() => {
      var menu = document.getElementById('M_' + to.meta.menu);

      if (!menu) {
        // console.log('Menu element not found: ', '#M_' + to.meta.menu);
        // alert('권한이 없습니다.');
        // window.history.back();
        return;
      }

      var isRead = menu.dataset.r === 'true';
      var isSave = menu.dataset.w === 'true';


      to.meta.isRead = isRead;
      to.meta.isSave = isSave;

      if (isRead) {
        document.querySelectorAll("[activity='READ']").forEach(element => {
          element.removeAttribute('activity');
        });
      } else {
        document.querySelectorAll("[activity='READ']").forEach(element => {
          element.remove();
          alert('권한이 없습니다.');
          window.history.back();
          return;
        });
      }

      if (isSave) {
        document.querySelectorAll("[activity='SAVE']").forEach(element => {
          element.removeAttribute('activity');
        });
      } else {
        document.querySelectorAll("[activity='SAVE']").forEach(element => {
          element.remove();
        });
      }

    //   console.log('MENU CODE : ' + to.meta.menu + ', READ:' + isRead + ', SAVE:' + isSave);
    }, timeout);
    timeout = 0;
  });
});

export default router;
