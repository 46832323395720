import { render, staticRenderFns } from "./bm-rcsTemplateList.vue?vue&type=template&id=7db19d8e&scoped=true"
import script from "./bm-rcsTemplateList.vue?vue&type=script&lang=js"
export * from "./bm-rcsTemplateList.vue?vue&type=script&lang=js"
import style0 from "./bm-rcsTemplateList.vue?vue&type=style&index=0&id=7db19d8e&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7db19d8e",
  null
  
)

export default component.exports