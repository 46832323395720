<template>
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M0.4375 8.75C0.437882 9.09798 0.576286 9.4316 0.822344 9.67766C1.0684 9.92371 1.40202 10.0621 1.75 10.0625H5.25C5.36603 10.0625 5.47731 10.0164 5.55936 9.93436C5.64141 9.85231 5.6875 9.74103 5.6875 9.625C5.6875 9.50897 5.64141 9.39769 5.55936 9.31564C5.47731 9.23359 5.36603 9.1875 5.25 9.1875H1.75C1.634 9.1874 1.52278 9.14127 1.44076 9.05924C1.35873 8.97722 1.3126 8.866 1.3125 8.75V3.3866L5.46232 5.80799C5.66409 5.92182 5.89183 5.98163 6.12349 5.98163C6.35515 5.98163 6.58289 5.92182 6.78466 5.80799L10.9375 3.38485V4.375C10.9375 4.49103 10.9836 4.60231 11.0656 4.68436C11.1477 4.76641 11.259 4.8125 11.375 4.8125C11.491 4.8125 11.6023 4.76641 11.6844 4.68436C11.7664 4.60231 11.8125 4.49103 11.8125 4.375V2.625C11.8121 2.27702 11.6737 1.9434 11.4277 1.69734C11.1816 1.45129 10.848 1.31288 10.5 1.3125H1.75C1.40435 1.3203 1.07502 1.46108 0.830551 1.70555C0.586079 1.95002 0.445295 2.27935 0.4375 2.625V8.75ZM1.75 2.1875H10.5C10.5762 2.18839 10.6509 2.20944 10.7164 2.24851C10.7818 2.28758 10.8358 2.34329 10.8728 2.40997L6.34375 5.0526C6.27685 5.09133 6.20092 5.11173 6.12362 5.11173C6.04632 5.11173 5.97039 5.09133 5.90349 5.0526L1.37633 2.41124C1.41324 2.34421 1.46728 2.28818 1.53293 2.24887C1.59858 2.20956 1.67349 2.18838 1.75 2.1875Z" fill="#374151"/>
  <path d="M10.0625 5.6875C9.37027 5.6875 8.69358 5.89277 8.11801 6.27736C7.54243 6.66194 7.09383 7.20857 6.82892 7.84811C6.56402 8.48765 6.4947 9.19138 6.62975 9.87032C6.7648 10.5492 7.09814 11.1729 7.58763 11.6624C8.07711 12.1519 8.70075 12.4852 9.37969 12.6202C10.0586 12.7553 10.7624 12.686 11.4019 12.4211C12.0414 12.1562 12.5881 11.7076 12.9726 11.132C13.3572 10.5564 13.5625 9.87973 13.5625 9.1875C13.5614 8.25957 13.1923 7.36995 12.5362 6.7138C11.8801 6.05766 10.9904 5.68857 10.0625 5.6875ZM10.0625 11.8125C9.54333 11.8125 9.03581 11.6585 8.60413 11.3701C8.17245 11.0817 7.836 10.6717 7.63732 10.192C7.43864 9.71239 7.38665 9.18459 7.48794 8.67539C7.58923 8.16619 7.83923 7.69846 8.20635 7.33134C8.57346 6.96423 9.04119 6.71423 9.55039 6.61294C10.0596 6.51165 10.5874 6.56364 11.067 6.76232C11.5467 6.961 11.9567 7.29745 12.2451 7.72913C12.5335 8.16081 12.6875 8.66832 12.6875 9.1875C12.6867 9.88346 12.4099 10.5507 11.9178 11.0428C11.4257 11.5349 10.7585 11.8117 10.0625 11.8125Z" fill="#374151"/>
  <path d="M11.0339 8.03917L9.58917 9.84512L9.0598 9.31575C8.97728 9.23605 8.86677 9.19196 8.75206 9.19295C8.63735 9.19395 8.52762 9.23996 8.4465 9.32108C8.36538 9.40219 8.31937 9.51192 8.31838 9.62663C8.31738 9.74134 8.36148 9.85186 8.44117 9.93437L9.31617 10.8094C9.36002 10.8522 9.4123 10.8855 9.46968 10.907C9.52707 10.9286 9.58831 10.938 9.64952 10.9346C9.71073 10.9312 9.77057 10.9152 9.82523 10.8874C9.8799 10.8597 9.92821 10.8209 9.96708 10.7735L11.7171 8.586C11.753 8.54114 11.7797 8.48965 11.7957 8.43446C11.8117 8.37927 11.8167 8.32147 11.8104 8.26436C11.8041 8.20725 11.7865 8.15194 11.7588 8.1016C11.7311 8.05126 11.6938 8.00687 11.6489 7.97096C11.6041 7.93505 11.5526 7.90833 11.4974 7.89232C11.4422 7.87631 11.3844 7.87133 11.3273 7.87766C11.2702 7.88399 11.2149 7.90151 11.1645 7.92921C11.1142 7.95692 11.0698 7.99431 11.0339 8.03917Z" fill="#374151"/>
</svg>
</template>

<script>
  export default {
    name: 'IconCheck',
  };
</script>