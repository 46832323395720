<template>
  <div class="message-tabs-wrap">
    <div class="message-tabs">
      <div :class="['tab', isActive('/ac/sendLimit/spam')]">
        <router-link to="/ac/sendLimit/spam">스팸문구</router-link>
      </div>
      <div :class="['tab', isActive('/ac/sendLimit/sendLimitAmount')]">
        <router-link to="/ac/sendLimit/sendLimitAmount">발송한도금액</router-link>
      </div>
      <div :class="['tab', isActive('/ac/sendLimit/rejct080Num')]">
        <router-link to="/ac/sendLimit/rejct080Num">수신거부관리</router-link>
      </div>
    </div>
    <p class="breadcrumb f-body5 c-gray700">발신제한 > {{breadCrumbTitle}}</p>
  </div>
</template>

<script>
import '@/assets/scss/service/messageTabs.scss'

export default {
  name: "LimitSpamTabs",
  computed: {
    breadCrumbTitle() {
      switch (this.$route.path) {
        case '/ac/sendLimit/spam':
          return '스팸문구';
        case '/ac/sendLimit/sendLimitAmount':
          return '발송한도금액';
        case '/ac/sendLimit/rejct080Num':
          return '수신거부관리';
        default:
          return ''
      }
    },
  },
  methods: {
    isActive(link) {
      return window.location.pathname === link ? 'active' : '';
    }
  }
}
</script>