<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M1.66699 5.02279C1.66699 4.56255 2.04009 4.18945 2.50033 4.18945H17.5003C17.9606 4.18945 18.3337 4.56255 18.3337 5.02279V16.6895C18.3337 17.1497 17.9606 17.5228 17.5003 17.5228H2.50033C2.04009 17.5228 1.66699 17.1497 1.66699 16.6895V5.02279Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M1.66699 7.52148H18.3337" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M11.25 14.1895H15" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.333 5.02148V11.6882" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M1.66699 5.02148V11.6882" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconPay',
  };
</script>