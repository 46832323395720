<template>
	<div>
		<RecieverManage />
	</div>
</template>

<script>
import RecieverManage from '../components/bc-receiver-manage.vue';

export default {
	components: {
		RecieverManage
	}
}
</script>