<template>
  <div class="chart-container">
    <bar-chart :chart-data="chartData" :chart-options="chartOptions"></bar-chart>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

// Chart.js에 필요한 컴포넌트 등록
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ChartDataLabels)

export default {
  name: 'Chart',
  components: {
    'bar-chart': Bar
  },
  data() {
    return {
      chartData: {
        labels: ['SMS', 'LMS', 'MMS', 'KAKAO', 'RCS', 'PUSH', '기타'],
        datasets: [
          {
            label: '',  // 라벨을 빈 문자열로 설정
            backgroundColor: ['#C2F1F7', '#9AE7D9', '#87F3BF', '#B2E889', '#D2E82D', '#F2E3BE', '#EED2EB'],
            data: [40, 20, 12, 39, 10, 40, 39]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: true,
        aspectRatio: 3,
        scales: {
          x: {
            ticks: {
              color: '#000',
              font: {
                size: 14
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              stepSize: 1,
              color: '#000',
              font: {
                size: 14
              }
            }
          }
        },
        plugins: {
          title: {
            display: true,
            text: '1월 통계',
            position: 'top',
            font: {
              size: 18,
              weight: 'bold'
            },
            padding: {
              top: 10,
              bottom: 30
            }
          },
          datalabels: {
            color: function(context) {
              return context.dataset.backgroundColor[context.dataIndex];
            },
            anchor: 'end',
            align: 'top',
            formatter: (value) => value + ' units',
            font: {
              weight: 'bold'
            }
          },
          tooltip: {
            callbacks: {
              label: function(context) {
                let label = context.dataset.label || '';
                if (label) {
                  label += ': ';
                }
                label += context.raw;
                return label + ' units';
              }
            }
          },
          legend: {
            display: false  // 범례를 완전히 비활성화
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  margin: auto;
  width: 800px;
}
</style>