<template>
  <div class="chart-container">
    <line-chart :chart-data="chartData" :options="chartOptions"></line-chart>
  </div>
</template>

<script>
import { Line } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'LineChart',
  components: {
    'line-chart': Line
  },
  data() {
    return {
      chartData: {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
          {
            label: 'SMS',
            backgroundColor: '#C2F1F7',
            borderColor: '#C2F1F7',
            data: [40, 39, 10, 40, 39, 80, 40]
          },
          {
            label: 'RCS',
            backgroundColor: '#D2E82D',
            borderColor: '#D2E82D',
            data: [21, 31, 8, 23, 11, 20, 30]
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'left',
            labels: {
              boxWidth: 20,
              padding: 20
            }
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  margin: auto;
  height: 400px; /* 차트 높이 조정 */
  width: 600px; /* 차트 너비 조정 */
}
</style>
