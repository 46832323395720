<template>
	<div>
		<div class="row consolMarginTop">
			<div class="col-xs-12">
				<div class="menuBox">						
					<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 전기통신사업법 제 84조 <strong>'SMS 발신 번호 사전 등록제'</strong> 가 2015년 10월 16일부로 시행됨에 따라 SMS를 발송하려면 발신 번호를 먼저 등록하셔야 합니다.</p>
					<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 첨부 파일 확장자는 png, jpg, pdf, zip, tif, tiff만 허용됩니다. (5M 이하 파일 가능)</p>
				</div>
			</div>
		</div>

		<div class="clear" style="margin-top: 30px;">
			<h4 class="inline-block">문자 발신번호 등록</h4>	
		</div>
		<div class="row consolMarginTop">
			<div class="col-xs-12">
				<div class="menuBox">						
					<div class="of_h vertical-top">
						<div class="float-left" style="width:20%"><h4>등록방법 선택</h4></div>
						<div class="float-left" style="width:80%">
							<input type="radio" id="selType_1" name="selType" value="1" class="blank2" v-model="selType">
							<label for="selType_1" class="mr30" style="margin-left: 7px;">휴대폰 번호 등록</label>
							<input type="radio" id="selType_2" name="selType" value="2" style="margin-left: 37px;" class="blank2" v-model="selType">
							<label for="selType_2" class="mr30" style="margin-left: 7px;">유선 번호 등록</label>
							<input type="radio" id="selType_3" name="selType" value="3" style="margin-left: 37px;" class="blank2" v-model="selType">
							<label for="selType_3" class="mr30" style="margin-left: 7px;">타인/타사 등록</label>
							<input type="radio" id="selType_4" name="selType" value="4" style="margin-left: 37px;" class="blank2" v-model="selType">
							<label for="selType_4" style="margin-left: 7px;">대리인</label>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="selType == 1" class="row" id="msgType01">
			<div class="col-xs-12">
				<!-- <div style="margin-top: 30px;">
					<h4 class="inline-block">문자 발신번호 등록 방법</h4>
				</div>
				<div class="container">
					<div class="progress-container" style="margin-top: 70px;">
						<div class="progress-step active">
							<div class="step-number">1</div>
							<div class="step-title">휴대폰 번호 등록</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">2</div>
							<div class="step-title">발신번호명 설정</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">3</div>
							<div class="step-title">승인대기</div>
						</div>
					</div>
				</div> -->
				<table class="table_skin1 consolMarginTop" style="width:100%; margin-top: 30px;">
					<tr>
						<td class="text-center vertical-middle bb2" style="width:30%">
							<h5 class="topH4" style="text-align: left;">휴대폰 번호 등록</h5>
							<ul class="text-left dotList" style="margin-top: 20px;">
								<li> PASS 앱을 통해 휴대폰 번호를 발신번호로 등록합니다.</li>
								<li> 기존에 등록된 발신번호는 중복 등록이 불가능합니다.</li>
								<li> KISA 관계법령에 의거 휴대폰 번호 등록 시에도 통신서비스 가입증명원 서류가 추가되어야 합니다.(서류 번호가 상이할 경우 등록에 제한이 됩니다)</li>
								<li> 휴대폰 인증 후 아래의 서류를 첨부하시면, 고객센터에서 서류 심사 후 발신번호가 등록됩니다. (1~3일 소요)</li>
							</ul>
						</td>
						<td class="text-center vertical-middle end"  style="width:70%">
							<div class="row">
								<div class="col-xs-12 consoleCon">				
									<div class="of_h">
										<h5 class="inline-block" style="width:22%;text-align: left;margin-top: 10px;">본인인증</h5>	
										<div class="float-right" style="width:76%; text-align: left;">
											<a @click="fnPopup" class="btnStyle1 font13 width180">휴대폰 인증</a><input type="hidden" id="projectId" v-model="projectId">
											<input type="hidden" id="message"><button id="niceAuth" style="display:none;" @click="fnMessage"></button>
											<!-- 본인인증 서비스 팝업을 호출하기 위해서는 다음과 같은 form이 필요합니다. -->
											<form name="form_chk" method="post">
												<input type="hidden" name="tr_cert" v-model="tr_cert">
												<input type="hidden" name="tr_url" v-model="tr_url">
												<input type="hidden" name="tr_ver" value = "V2">
											</form>
										</div>
										<div class="consolMarginTop">
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">발신번호</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left" id="phoneCerti" v-model="callNum4" style="width:35%" disabled>
											</div>
										</div>
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">발신번호명</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left" id="userNm" v-model="callNumNm4" style="width:35%" disabled>
												<input type="hidden" class="float-left" id="gender"  style="width:35%" disabled>
											</div>
										</div>
									</div>
									</div>
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">통신서비스 가입증명원</h5>
										<div class="float-right" style="width:76%">
											<input id="joinFile4" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="joinFile4"/>
											<p class="txtCaption color1 mt10" style="text-align: left;">&nbsp;&nbsp;&nbsp;등록할 발신 번호에 해당하는 통신서비스 이용 증명원을 첨부하시기 바랍니다.</p>
										</div>
									</div>							
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<div class="of_h mt15">
											<div class="" style="width:100%; margin-bottom: 1px; text-align: left; margin-left: 10px;">
												<input type="checkbox" id="regNoYn" class="checkStyle2" value="" v-model="regNoYn" @click="fnToggleOnOff">
												<label for="regNoYn">계정 담당자가 사업자 등록등의 대표자 본인입니다.(증빙서류 제외 대상)</label>
											</div>
										</div>
									</div>	
									<div v-if="regNoYn != true" class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22%;text-align: left;">재직 증명서</h5>
										<div class="float-right" style="width:76%">
											<input id="workFile" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="workFile"/>
											<p class="txtCaption color1 mt10" style="text-align: left;">&nbsp;&nbsp;&nbsp;휴대폰 소유자 재직증명서를 첨부하시기 바랍니다.</p>
										</div>
									</div>		

								</div>
							</div>

						</td>
					</tr>
				</table>
				<div class="text-center mt40">
					<a @click="fnApprovalHp" class="btnStyle2 borderGray ml10" activity="SAVE" >승인요청</a>
					<!-- <router-link :to="{ name: 'msgSendNumber' }" tag="a" class="btnStyle2 borderGray ml10">목록</router-link> -->
					<a @click="fnCallParent()" class="btnStyle2 borderGray ml10">목록</a>
				</div>
			</div>
		</div>

		<div v-if="selType == 2" class="row" id="msgType02">
			<div class="col-xs-12">
				<!-- <div style="margin-top: 30px;">
					<h4 class="inline-block">문자 발신번호 등록 방법 (유선 번호 등록)</h4>
				</div>
				<div class="container">
					<div class="progress-container" style="margin-top: 70px;">
						<div class="progress-step active">
							<div class="step-number">1</div>
							<div class="step-title">유선 번호 등록</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">2</div>
							<div class="step-title">발신번호명 설정</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">3</div>
							<div class="step-title">승인대기</div>
						</div>
					</div>
				</div> -->
				<table class="table_skin1 consolMarginTop" style="width:100%; margin-top: 30px;">
					<tr>
						<td class="text-center vertical-middle bb2" style="width:30%">
							<h5 class="topH4" style="text-align: left;">유선 번호 등록</h5>
							<ul class="text-left dotList" style="margin-top: 20px;">
								<li> 통신서비스 이용증명원을 발급받은 유선 번호만 등록이 가능합니다.</li>
								<li> 기존에 등록된 발신번호는 중복 등록이 불가능합니다.</li>
								<li> 일괄 등록은 최대 1,000건까지 가능합니다.</li>
								<li> 유선 번호를 입력한 후 아래의 서류를 첨부하시면, 고객센터에서 서류 심사 후 발신번호가 등록됩니다. (1~3일 소요)</li>
							</ul>
						</td>
						<td class="text-center vertical-middle end"  style="width:70%">
							<div class="row">
								<div class="col-xs-12 consoleCon">		
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<div class="of_h mt15">
											<div class="" style="width:100%; margin-bottom: 1px; text-align: left; margin-left: 10px;">
												<input type="checkbox" id="allRegYn" class="checkStyle2" value="" v-model="allRegYn" @click="fnToggleOnOff">
												<label for="allRegYn">번호를 일괄 등록합니다.(일괄 등록 시 기존에 등록된 발신번호가 있다면 해당 번호는 다름 단계에 표시되지 않습니다.)</label>
											</div>
										</div>
									</div>	
									<div v-if="allRegYn == true" class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">일괄등록</h5>
										<!-- <a @click="fnFileDown('FLE0CheTyB', '대리인등록.xlsx')" class="color-blue">업로드 샘플 다운로드</a> -->
										<div class="float-right" style="width:76%">
											<input id="allFile" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="allFile"/>
											<a href="/downloads/문자 발신번호 일괄 등록.xlsx" class="btnStyle2 backPink absolute top0 right0" style="margin: 70px 50px 0px 0px;" download="문자 발신번호 일괄 등록.xlsx">업로드 샘플 다운로드</a>
											<!-- <a @click="fnFileDownAll('FLEcxJMtiT', '유선 등록.xlsx')" class="color-blue">[업로드 샘플 다운로드]</a>  -->
										</div>
									</div>	
									<div v-if="allRegYn != true" class="consolMarginTop">
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">유선번호</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left"  v-model="callNum" style="width:56%">
											</div>
										</div>
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">발신번호명</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left"  v-model="callNumNm" style="width:56%">
											</div>
										</div>
									</div>
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">통신서비스 가입증명원</h5>
										<div class="float-right" style="width:76%">
											<input id="joinFile" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="joinFile"/>
											<p class="txtCaption color1 mt10" style="text-align: left;">&nbsp;&nbsp;&nbsp;등록할 발신 번호에 해당하는 통신서비스 이용 증명원을 첨부하시기 바랍니다.</p>
										</div>
									</div>							
								</div>
							</div>

						</td>
					</tr>
				</table>
				<div class="text-center mt40">
					<a v-if="allRegYn == true" @click="fnApprovalTelAll" class="btnStyle2 borderGray ml10" activity="SAVE" >승인요청</a>
					<a v-if="allRegYn != true" @click="fnApprovalTel" class="btnStyle2 borderGray ml10" activity="SAVE" >승인요청</a>
					<a @click="fnCallParent()" class="btnStyle2 borderGray ml10">목록</a>
				</div>
			</div>

		</div>

		<div v-if="selType == 3" class="row" id="msgType02">
			<div class="col-xs-12">
				<!-- <div style="margin-top: 30px;">
					<h4 class="inline-block">문자 발신번호 등록 방법 (타인/타사 번호 등록)</h4>
				</div>
				<div class="container">
					<div class="progress-container" style="margin-top: 70px;">
						<div class="progress-step active">
							<div class="step-number">1</div>
							<div class="step-title">타인.타사 번호 등록</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">2</div>
							<div class="step-title">발신번호명 설정</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">3</div>
							<div class="step-title">승인대기</div>
						</div>
					</div>
				</div> -->
				<table class="table_skin1 consolMarginTop" style="width:100%; margin-top: 30px;">
					<tr>
						<td class="text-center vertical-middle bb2" style="width:30%">
							<h5 class="topH4" style="text-align: left;">타인/타사 번호 등록</h5>
							<ul class="text-left dotList" style="margin-top: 20px;">
								<li> 통신서비스 이용증명원을 발급받은 유선 번호만 등록이 가능합니다.</li>
								<li> 기존에 등록된 발신번호는 중복 등록이 불가능합니다.</li>
								<li> 일괄 등록은 최대 1,000건까지 가능합니다.</li>
								<li> 타인/타사 번호 등록시 아래의 서류를 첨부하시면, 서류 심사 후 발신번호가 등록됩니다. (1-3일 소요)</li>
							</ul>
						</td>
						<td class="text-center vertical-middle end"  style="width:70%">
							<div class="row">
								<div class="col-xs-12 consoleCon">		
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<div class="of_h mt15">
											<div class="" style="width:100%; margin-bottom: 1px; text-align: left; margin-left: 10px;">
												<input type="checkbox" id="allRegYn3" class="checkStyle2" value="" v-model="allRegYn3" @click="fnToggleOnOff">
												<label for="allRegYn3">번호를 일괄 등록합니다.(일괄 등록 시 기존에 등록된 발신번호가 있다면 해당 번호는 다름 단계에 표시되지 않습니다.)</label>
											</div>
										</div>
									</div>	
									<div v-if="allRegYn3 == true" class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">일괄등록</h5>
										<!-- <a @click="fnFileDown('FLE0CheTyB', '대리인등록.xlsx')" class="color-blue">업로드 샘플 다운로드</a> -->
										<div class="float-right" style="width:76%">
											<input id="allFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="allFile3"/>
											<a href="/downloads/문자 발신번호 일괄 등록.xlsx" class="btnStyle2 backPink absolute top0 right0" style="margin: 70px 50px 0px 0px;" download="문자 발신번호 일괄 등록.xlsx">업로드 샘플 다운로드</a>
											<!-- <a @click="fnFileDownAll('FLEcxJMtiT', '타인/타사 등록.xlsx')" class="color-blue">[업로드 샘플 다운로드]</a>  -->
										</div>
									</div>	
									<div v-if="allRegYn3 != true" class="consolMarginTop">
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">타인/타사 번호</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left"  v-model="callNum3" style="width:56%">
											</div>
										</div>
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">발신번호명</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left"  v-model="callNumNm3" style="width:56%">
											</div>
										</div>
									</div>
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">통신서비스 가입증명원</h5>
										<div class="float-right" style="width:76%">
											<input id="joinFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="joinFile3"/>
											<p class="txtCaption color1 mt10" style="text-align: left;">&nbsp;&nbsp;&nbsp;등록할 발신 번호에 해당하는 통신서비스 이용 증명원을 첨부하시기 바랍니다.</p>
										</div>
									</div>
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">위임업체 대리인 신분증 사본</h5>
										<div class="float-right" style="width:76%">
											<input id="giveProxyFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="giveProxyFile3"/>
										</div>
									</div>	
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">위임업체 인감증명서</h5>
										<div class="float-right" style="width:76%">
											<input id="giveSealFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="giveSealFile3"/>
										</div>
									</div>	
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">수임업체 인감증명서</h5>
										<div class="float-right" style="width:76%">
											<input id="acceptSealFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="acceptSealFile3"/>
										</div>
									</div>
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">인감이 날인된 위임장 <br>
											<a href="/downloads/타인_타사 번호 위임장.zip" download="타인_타사 번호 위임장.zip"><p class="color-blue" >[양식 다운로드]</p></a></h5> 
											<!-- <a @click="fnFileDownAll('FLEcxJMtiT', '타인/타사 번호 위임장.xlsx')" class="color-blue">[양식 다운로드]</a> -->
										<div class="float-right" style="width:76%">
											<input id="entrustFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="entrustFile3"/>
											<p class="txtCaption color1 mt10" style="text-align: left;">&nbsp;&nbsp;&nbsp;업로드 샘플 다운로드의 양식에 맞게 번호를 기재해 주시기 바랍니다.
												첨부 파일 확장자는 xls, xlsx 만 허용됩니다. (5M 이하 파일 가능).</p>
												
										</div>
									</div>
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">위임-수임관계 확인 서류<br>
											<a href="/downloads/위임-수임 관계 확인서.pdf" download="위임-수임 관계 확인서.pdf"><p class="color-blue" >[양식 다운로드]</p></a></h5> 
											<!-- <a @click="fnFileDownAll('FLEcxJMtiT', '위임-수임관계 확인서류.xlsx')" class="color-blue">[양식 다운로드]</a>  -->
										<div class="float-right" style="width:76%">
											<input id="checkFile3" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="checkFile3"/>
										</div>
									</div>									
								</div>
							</div>

						</td>
					</tr>
				</table>
				<div class="text-center mt40">
					<a v-if="allRegYn3 == true" @click="fnApprovalOtherAll" class="btnStyle2 borderGray ml10" activity="SAVE" >승인요청</a>
					<a v-if="allRegYn3 != true" @click="fnApprovalOther" class="btnStyle2 borderGray ml10" activity="SAVE" >승인요청</a>
					<a @click="fnCallParent()" class="btnStyle2 borderGray ml10">목록</a>
				</div>
			</div>

		</div>

		<div v-if="selType == 4" class="row" id="msgType02">
			<div class="col-xs-12">
				<!-- <div style="margin-top: 30px;">
					<h4 class="inline-block">문자 발신번호 등록 방법 (대리인 등록 요청)</h4>
				</div>
				<div class="container">
					<div class="progress-container" style="margin-top: 70px;">
						<div class="progress-step active">
							<div class="step-number">1</div>
							<div class="step-title">대리인 등록</div>
						</div>
						<div class="progress-step " style="margin-left: 70px;">
							<div class="step-number">2</div>
							<div class="step-title">발신번호 등록(대리인)</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">3</div>
							<div class="step-title">발신번호명 설정</div>
						</div>
						<div class="progress-step" style="margin-left: 70px;">
							<div class="step-number">4</div>
							<div class="step-title">등록완료</div>
						</div>
					</div>
				</div> -->
				<table class="table_skin1 consolMarginTop" style="width:100%; margin-top: 30px;">
					<tr>
						<td class="text-center vertical-middle bb2" style="width:30%">
							<h5 class="topH4" style="text-align: left;">발신번호 등록 (대리인)</h5>
							<ul class="text-left dotList" style="margin-top: 20px;">
								<li> 대리인은 기업 또는 기관으로부터 권한을 위임 받은 사람으로서 기업 또는 기관을 대신하여 발신번호를 등록, 수정, 삭제 할 수 있으며 이에 대한 권한과 책임을 갖습니다.</li>
								<li> 일괄 등록은 최대 1,000건까지 가능합니다.</li>
								<li> 일괄 등록 시 기존에 등록된 발신번호가 있다면 해당 발신번호는 다음 단계에 표시 되지 않습니다.</li>
								<li> 전화번호 세칙을 준수한 번호만 등록이 가능합니다. 단, 060(금융권 번호) , KISA 번호도용차단 번호 리스트에 등록된 발신번호는 등록이 되지 않습니다.</li>
							</ul>
						</td>
						<td class="text-center vertical-middle end" v-if="proxyState == ''" style="width:70%">
								<a @click="fnCallNumProxy" class="btnStyle1 font13 width180">대리인 등록 요청</a><br>
								대리인은 기업 또는 기관으로부터<br>권한을 위임 받은 사람으로서 기업<br>또는 기관을 대신하여 발신번호를<br>등록, 수정, 삭제 할 수 있으며 이에<br>대한 권한과 책임을 갖습니다.
							</td>
						<td class="text-center vertical-middle end" v-if="proxyState == '10'" style="width:70%">
							<h5 class="color000">요청 중..</h5>
							<p>대리인은 기업 또는 기관으로부터 권한을 위임 받은 사람으로서 기업 또는 기관을 대신하여 발신번호를 등록, 수정, 삭제 할 수 있으며 이에 대한 권한과 책임을 갖습니다.</p>
						</td>
						<td class="text-center vertical-middle end" v-if="proxyState == '20'" style="width:70%">
							<h5 class="color000">접수/심사 중..</h5>
							<p>대리인은 기업 또는 기관으로부터 권한을 위임 받은 사람으로서 기업 또는 기관을 대신하여 발신번호를 등록, 수정, 삭제 할 수 있으며 이에 대한 권한과 책임을 갖습니다.</p>
						</td>
						<td class="text-center vertical-middle end" v-if="proxyState == '80'" style="width:70%">
							<div class="row">
								<div class="col-xs-12 consoleCon">		
									<div class="of_h consolMarginTop" style="margin-top: 20px;">
										<div class="of_h mt15">
											<div class="" style="width:100%; margin-bottom: 1px; text-align: left; margin-left: 10px;">
												<input type="checkbox" id="allRegYn2" class="checkStyle2" value="" v-model="allRegYn2" @click="fnToggleOnOff">
												<label for="allRegYn2">번호를 일괄 등록합니다.(일괄 등록 시 기존에 등록된 발신번호가 있다면 해당 번호는 다름 단계에 표시되지 않습니다.)</label>
											</div>
										</div>
									</div>	
									<div v-if="allRegYn2 == true" class="of_h consolMarginTop" style="margin-top: 20px;">
										<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">일괄등록</h5>
									
										<div class="float-right" style="width:76%">
											<input id="allFile2" type="file" class="btnStyle7 minWidthAuto float float-left" style="width:40%" ref="allFile2"/>
											<a href="/downloads/문자 발신번호 일괄 등록.xlsx" class="btnStyle2 backPink absolute top0 right0" style="margin: 70px 50px 0px 0px;" download="문자 발신번호 일괄 등록.xlsx">업로드 샘플 다운로드</a>
											<!-- <a @click="fnFileDownAll('FLEcxJMtiT', '대리인등록.xlsx')" class="color-blue">[업로드 샘플 다운로드]</a>  -->
										</div>
									</div>	
									<div v-if="allRegYn2 != true" class="consolMarginTop">
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">대리인 등록 번호</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left"  v-model="callNum2" style="width:56%">
											</div>
										</div>
										<div class="of_h">
											<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">발신번호명</h5>
											<div class="float-right" style="width:76%">
												<input type="text" class="float-left"  v-model="callNumNm2" style="width:56%">
											</div>
										</div>
									</div>
								</div>
							</div>
						</td>
						<td class="text-center vertical-middle end" v-if="proxyState == '90'" style="width:70%">
							<h5 class="color000"><a data-toggle="modal" data-target="#reject">반려 <i class="far fa-info-circle vertical-baseline"></i></a></h5>
							<div class="of_h text-center mt10"><a @click="fnCallNumProxy" class="btnStyle1 font13 width180">대리인 등록 요청</a></div>
							<p class="mt10">대리인은 기업 또는 기관으로부터 권한을 위임 받은 사람으로서 기업 또는 기관을 대신하여 발신번호를 등록, 수정, 삭제 할 수 있으며 이에 대한 권한과 책임을 갖습니다.</p>
						</td>
					</tr>
				</table>
				<div class="text-center mt40" v-if="proxyState == '80'">
					<a v-if="allRegYn2 == true" @click="fnSaveProxyAll" class="btnStyle2 borderGray ml10" activity="SAVE" >등록</a>
					<a v-if="allRegYn2 != true" @click="fnSaveProxy" class="btnStyle2 borderGray ml10" activity="SAVE" >등록</a>
					<a @click="fnCallParent()" class="btnStyle2 borderGray ml10">목록</a>
				</div>
			</div>
		</div>
		<layerPopupProxy  :srcProjectId = "srcProjectId" :detailCnt="detailCnt"/>
		
		<!-- reject Modal -->
		<div class="modal modalStyle" id="reject" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" style="width:650px">
				<div class="modal-content">
					<div class="modal-body">					
						<h2>반려</h2>
						<hr>
						<p class="text-center mt20">{{proxyReason}}</p>		
						<div class="text-center mt20">
							<a class="btnStyle1 backWhite" data-dismiss="modal" title="취소">닫기</a>						
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import layerPopupProxy from "./bp-sendInfo-callnum-proxy.vue";
import confirm from '@/modules/commonUtil/service/confirm';
import sendInfoApi from '../service/sendInfoApi'
import tokenSvc from '@/common/token-service';
import commonUtilApi from "@/modules/commonUtil/service/commonUtilApi";
import axios from 'axios';

export default {
  components: {
	//   layerPopup,
	//   layerPopupDoc,
	layerPopupProxy,
	//   layerPopupAll
  },
  watch: {
    callNum () {
      this.callNum = this.$gfnCommonUtils.hpNumberAddDash(this.callNum);
    },
	callNum2 () {
      this.callNum2 = this.$gfnCommonUtils.hpNumberAddDash(this.callNum2);
    },
	callNum3 () {
      this.callNum3 = this.$gfnCommonUtils.hpNumberAddDash(this.callNum3);
    },
	callNum4 () {
      this.callNum4 = this.$gfnCommonUtils.hpNumberAddDash(this.callNum4);
    }
	},
	data() {
    return {
		// 프로젝트 정보
		projectId : '',
		srcProjectId : '',
		detailCnt : 0,
		sEncData : '', 				// 나이스본인인증 암호화 정보
		proxyState : '',
		proxyReason : '',
		callNum : '(필수)등록할 유선 번호를 입력 해 주세요.',
		callNumNm : '',
		callNum2 : '(필수)등록할 휴대폰/유선 번호를 입력 해 주세요.',
		callNumNm2 : '',
		callNum3 : '(필수)등록할 핸드폰 번호를 입력 해 주세요.',
		callNumNm3 : '',
		callNum4 : '(필수)등록할 휴대폰 인증을 해 주세요.',
		callNumNm4 : '',
		selType : 1,
		regNoYn : '',
		allRegYn : '',
		allRegYn2 : '',
		allRegYn3 : '',
		tr_cert : '',
		tr_url : '',
		KMCIS_window : null
    }
  },
  mounted() {
		this.fnGetCallNumProxy();

		this.fnGetKmcCheck();

  }, 
  methods: {
	fnToggleOnOff() {
		if (this.regNoYn == true) {
			this.regNoYn = '';
		}else{
			this.regNoYn = 'Y';
		}
	},
	// 본인인증시 사용될 데이터 조회
	fnGetKmcCheck(){
		let tr_certVal = '';
		let tr_urlVal = '';

		sendInfoApi.getKmcCheck().then((response) => {
			var result = response.data;
			if(result.success){
				tr_certVal = result.data.tr_cert;
				tr_urlVal = result.data.tr_url;
				this.tr_cert = tr_certVal;
				this.tr_url = tr_urlVal;
			} else {
				confirm.fnAlert("", result.message);
			}
		});
	},
	fnPopup(){
			window.open('', 'popupChk', 'width=400, height=705, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
			document.form_chk.action = "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
			document.form_chk.target = "popupChk";
			document.form_chk.submit();
	},
	// 본인인증 팝업
	fnPopup4(){

		const UserAgent = navigator.userAgent;
		const isMobile = UserAgent.match(/iPhone|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) !== null 
					|| UserAgent.match(/LG|SAMSUNG|Samsung/i) !== null;

		if (isMobile) {
			this.$refs.reqKMCISForm.target = '';
		} else {
			this.KMCIS_window = window.open('', 'KMCISWindow', 'width=425, height=550, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250');
			
			if (this.KMCIS_window === null) {
				alert("※ 윈도우 XP SP2 또는 인터넷 익스플로러 7 사용자일 경우에는 \n 화면 상단에 있는 팝업 차단 알림줄을 클릭하여 팝업을 허용해 주시기 바랍니다. \n\n※ MSN,야후,구글 팝업 차단 툴바가 설치된 경우 팝업허용을 해주시기 바랍니다.");
			}

			this.$refs.reqKMCISForm.target = 'KMCISWindow';
		}

		this.$refs.reqKMCISForm.action = 'https://www.kmcert.com/kmcis/web/kmcisReq.jsp';
		this.$refs.reqKMCISForm.submit();

	},
	fnMessage() {
		//confirm.fnAlert("", "휴대폰 인증 되었습니다.");
		this.callNum4 = jQuery("#phoneCerti").val();
		this.callNumNm4 = jQuery("#userNm").val();
	},
	// 대리인 데이터 조회
	fnGetCallNumProxy(){
		let params = {'corpId':tokenSvc.getToken().principal.corpId}

		sendInfoApi.getCallNumProxy(params).then((response) => {
			var result = response.data;
			if(result.success){
				if (result.data != null) {
					this.proxyState = result.data.state;
					this.proxyReason = result.data.handleReason;
				}
			} else {
				confirm.fnAlert("", result.message);
			}
		});
	},
    fnCallNumProxy(){
      this.detailCnt = this.detailCnt + 1;
      jQuery("#proxyPop").modal("show");
    },
	// 일괄 파일다운로드
    fnFileDownAll: function(fileId, name) {
      if(fileId != "") {
        var params = {
              fileId    : fileId,
              fileNm      : name
          };

        commonUtilApi.downloadFile(params).then(response =>{
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
      }
    },
	// 파일다운로드
		fnFileDown: function(fileId, name) {
      if(fileId != "") {
        var params = {
              fileId    : fileId,
              fileNm      : name
          };

        commonUtilApi.downloadFile(params).then(response =>{

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", name); //or any other extension
            document.body.appendChild(link);
            link.click();
        });
      }
    },
	async fnApprovalHp() {
      
		if(this.callNum4 == '') {
				confirm.fnAlert("", "등록할 발신번호를 입력해주세요.");
		return;
		}
		
		if(this.$refs.joinFile4.files[0] === null || this.$refs.joinFile4.files[0] === undefined ){
				confirm.fnAlert("", "통신서비스 가입증명원 파일을 등록해주세요.");
		return;
		}
		if(this.regNoYn != true){
			if(this.$refs.workFile.files[0] === null || this.$refs.workFile.files[0] === undefined ){
				confirm.fnAlert("", "재직증명서 파일을 등록해주세요.");
			return;
			}
		}

		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('callNum', this.$gfnCommonUtils.hpNumberRemoveDash(this.callNum4));
		fd.append('callNumNm', this.callNumNm4);
		fd.append('userId', tokenSvc.getToken().principal.userId);
		fd.append('regNoYn', this.regNoYn);

		// 첨부파일 정리
		fd.append('joinFile'		, this.$refs.joinFile4.files[0]);
		
		if(this.regNoYn != true){
			fd.append('workFile'		, this.$refs.workFile.files[0]);
		}
		await axios.post('/sendInfoApi/manage/saveCallNumHp',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
			var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert("", result.message);
		}
		}).catch(function () {
			confirm.fnAlert("", "저장에 실패했습니다.");
		});
	},
	async fnApprovalTel() {
      
		if(this.callNum == '') {
				confirm.fnAlert("", "등록할 발신번호를 입력해주세요.");
		return;
		}
		
		if(this.$refs.joinFile.files[0] === null || this.$refs.joinFile.files[0] === undefined ){
				confirm.fnAlert("", "통신서비스 가입증명원 파일을 등록해주세요.");
		return;
		}

		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('callNum', this.$gfnCommonUtils.hpNumberRemoveDash(this.callNum));
		fd.append('callNumNm', this.callNumNm);
		fd.append('userId', tokenSvc.getToken().principal.userId);

		// 첨부파일 정리
		fd.append('joinFile'		, this.$refs.joinFile.files[0]);
		
		await axios.post('/sendInfoApi/manage/saveCallNumDoc',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
			var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert("", result.message);
		}
		}).catch(function () {
			confirm.fnAlert("", "저장에 실패했습니다.");
		});
    },
	async fnApprovalTelAll(){
		if(this.$refs.allFile.files[0] === null || this.$refs.allFile.files[0] === undefined ){
				confirm.fnAlert("", "파일을 등록해주세요.");
		return;
		}

		if(this.$refs.joinFile.files[0] === null || this.$refs.joinFile.files[0] === undefined ){
				confirm.fnAlert("", "통신서비스 가입증명원 파일을 등록해주세요.");
		return;
		}

		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('userId', tokenSvc.getToken().principal.userId);

		// 첨부파일 정리
		fd.append('allFile'		, this.$refs.allFile.files[0]);
		fd.append('joinFile'		, this.$refs.joinFile.files[0]);

		await axios.post('/sendInfoApi/manage/saveCallNumAll',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
			var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert('', result.message);
		}
		})
		.catch(function (e) {
			console.log(e);
			confirm.fnAlert("", "등록에 실패했습니다.");
		});
    },
	// 대리인 등록 
	fnSaveProxy() {
		//유효성 검사
		if(this.callNum2 == null || this.callNum2 == '') {
			confirm.fnAlert('', '발신번호를 입력해주세요.');
			return false;
		}
 
		let params = {
			'callNum': this.$gfnCommonUtils.hpNumberRemoveDash(this.callNum2),
			'callNumNm': this.callNumNm2
		};

		sendInfoApi.saveCallNumPro(params).then(response =>{
			var result = response.data;
			if(result.success) {
				this.callNum2 = '';
				this.callNumNm2 = '';
				confirm.fnAlert('', '등록을 성공했습니다.');
			} else {
				confirm.fnAlert('', result.message);
			}
		});
	},
	async fnSaveProxyAll(){
		if(this.$refs.allFile2.files[0] === null || this.$refs.allFile2.files[0] === undefined ){
				confirm.fnAlert("", "파일을 등록해주세요.");
		return;
		}

		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('userId', tokenSvc.getToken().principal.userId);

		// 첨부파일 정리
		fd.append('allFile'		, this.$refs.allFile2.files[0]);

		await axios.post('/sendInfoApi/manage/saveCallNumProxyAll',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
			var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert('', result.message);
		}
		})
		.catch(function (e) {
		console.log(e);
			confirm.fnAlert("", "등록에 실패했습니다.");
		});
    },
	async fnApprovalOther() {
      
		if(this.callNum3 == '') {
			confirm.fnAlert("", "등록할 발신번호를 입력해주세요.");
		return;
		}
		
		if(this.$refs.joinFile3.files[0] === null || this.$refs.joinFile3.files[0] === undefined ){
			confirm.fnAlert("", "통신서비스 가입증명원 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.giveProxyFile3.files[0] === null || this.$refs.giveProxyFile3.files[0] === undefined ){
			confirm.fnAlert("", "위임업체 대리인 신분증 사본 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.giveSealFile3.files[0] === null || this.$refs.giveSealFile3.files[0] === undefined ){
				confirm.fnAlert("", "위임업체 인감증명서 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.acceptSealFile3.files[0] === null || this.$refs.acceptSealFile3.files[0] === undefined ){
			confirm.fnAlert("", "수임업체 인감증명서 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.entrustFile3.files[0] === null || this.$refs.entrustFile3.files[0] === undefined ){
			confirm.fnAlert("", "인감이 날인된 위임장 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.checkFile3.files[0] === null || this.$refs.checkFile3.files[0] === undefined ){
			confirm.fnAlert("", "위임-수임관계 확인 서류파일을 등록해주세요.");
		return;
		}

		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('callNum', this.$gfnCommonUtils.hpNumberRemoveDash(this.callNum3));
		fd.append('callNumNm', this.callNumNm3);
		fd.append('userId', tokenSvc.getToken().principal.userId);

		// 첨부파일 정리
		fd.append('joinFile'			, this.$refs.joinFile3.files[0]);
		fd.append('giveProxyFile'		, this.$refs.giveProxyFile3.files[0]);
		fd.append('giveSealFile'		, this.$refs.giveSealFile3.files[0]);
		fd.append('acceptSealFile'	, this.$refs.acceptSealFile3.files[0]);
		fd.append('entrustFile'		, this.$refs.entrustFile3.files[0]);
		fd.append('checkFile'			, this.$refs.checkFile3.files[0]);
		
		await axios.post('/sendInfoApi/manage/saveCallNumOther',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
			var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert("", result.message);
		}
		}).catch(function () {
			confirm.fnAlert("", "저장에 실패했습니다.");
		});
    },
	async fnApprovalOtherAll(){
		if(this.$refs.allFile3.files[0] === null || this.$refs.allFile3.files[0] === undefined ){
			confirm.fnAlert("", "파일을 등록해주세요.");
		return;
		}

		if(this.$refs.joinFile3.files[0] === null || this.$refs.joinFile3.files[0] === undefined ){
			confirm.fnAlert("", "통신서비스 가입증명원 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.giveProxyFile3.files[0] === null || this.$refs.giveProxyFile3.files[0] === undefined ){
			confirm.fnAlert("", "위임업체 대리인 신분증 사본 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.giveSealFile3.files[0] === null || this.$refs.giveSealFile3.files[0] === undefined ){
			confirm.fnAlert("", "위임업체 인감증명서 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.acceptSealFile3.files[0] === null || this.$refs.acceptSealFile3.files[0] === undefined ){
			confirm.fnAlert("", "수임업체 인감증명서 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.entrustFile3.files[0] === null || this.$refs.entrustFile3.files[0] === undefined ){
			confirm.fnAlert("", "인감이 날인된 위임장 파일을 등록해주세요.");
		return;
		}
		if(this.$refs.checkFile3.files[0] === null || this.$refs.checkFile3.files[0] === undefined ){
			confirm.fnAlert("", "위임-수임관계 확인 서류파일을 등록해주세요.");
		return;
		}

		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('userId', tokenSvc.getToken().principal.userId);

		// 첨부파일 정리
		fd.append('allFile'			, this.$refs.allFile3.files[0]);
		fd.append('joinFile'			, this.$refs.joinFile3.files[0]);
		fd.append('giveProxyFile'		, this.$refs.giveProxyFile3.files[0]);
		fd.append('giveSealFile'		, this.$refs.giveSealFile3.files[0]);
		fd.append('acceptSealFile'	, this.$refs.acceptSealFile3.files[0]);
		fd.append('entrustFile'		, this.$refs.entrustFile3.files[0]);
		fd.append('checkFile'			, this.$refs.checkFile3.files[0]);

		await axios.post('/sendInfoApi/manage/saveCallNumOtherAll',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
		var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert('', result.message);
		}
		})
		.catch(function (e) {
			console.log(e);
			confirm.fnAlert("", "등록에 실패했습니다.");
		});
    },
	async fnApprovalProxy(){
		if(this.callNum2 == '') {
				confirm.fnAlert("", "등록할 발신번호를 입력해주세요.");
        return;
		}
		
		var fd = new FormData();
		fd.append('corpId', tokenSvc.getToken().principal.corpId);
		fd.append('callNum', this.$gfnCommonUtils.hpNumberRemoveDash(this.callNum2));
		fd.append('callNumNm', this.callNumNm2);
		fd.append('userId', tokenSvc.getToken().principal.userId);
      
		await axios.post('/sendInfoApi/manage/saveCallNumProxyReq',
		fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
		var result = response.data;
		if(result.success) {
			confirm.fnAlert("", "저장에 성공했습니다.");
			this.fnCallParent();
		} else {
			confirm.fnAlert("", result.message);
		}
		}).catch(function () {
			confirm.fnAlert("", "저장에 실패했습니다.");
		});
    },
	fnCallParent() {
      this.$emit('fnMoveMain');
    }

  }
}
</script>
<style scoped>
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  width: 80%;
  max-width: 900px;
  text-align: center;
}

h1 {
  margin-bottom: 40px;
  color: #333;
}

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 20px;
}

.progress-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  position: relative;
}

.progress-step:not(:last-child)::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ccc;
  top: 50%;
  left: 50%;
  z-index: -1;
}

.progress-step:not(:first-child)::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 4px;
  background-color: #ccc;
  top: 50%;
  right: 50%;
  z-index: -1;
}

.step-number {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ccc;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
  z-index: 1;
}

.step-title {
  color: #ccc;
  font-size: 14px;
}

.progress-step.active .step-number {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

.progress-step.active ~ .progress-step .step-number {
  background-color: #fff;
  color: #ccc;
  border-color: #ccc;
}

.progress-step.active .step-title {
  color: #333;
}

.progress-step.active ~ .progress-step .step-title {
  color: #ccc;
}
</style>