<template>
  <div class="status-card">
    <div class="status-card-body" :class="{ 'request': item.type === 'request', 'reqing': item.type === 'reqing', 'waiting': item.type === 'waiting', 'success': item.type === 'success', 'failure': item.type === 'failure' }">
      <p class="m-0 f-title3 c-gray600">{{ item.title }}</p>
      <p class="status-card-text">
        <span>{{ item.value }}</span>건
      </p>
    </div>
  </div>
</template>




<script>
export default {
  name: 'StatusCard',
  props: {
    item: {
      type: Object,
      required: true
    },
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;

.status-card {
  width: 20%;
  padding: 0 12.5px;

  &-body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 26px 23px;
    background: var(--white); /* 기본 배경색 */
    border: 1px solid var(--gray300); /* 기본 테두리 색상 (회색) */
    border-radius: 12px;

    /* 발송 요청 카드에만 배경색과 테두리 제거 */
    &.request {
      background-color: var(--status-request-bg); /* 발송요청 카드 배경색 */
      border: none; /* 발송요청 카드 테두리 제거 */
    }
  }

  &-text {
    margin: 0;
    @include typography.font-style(20px, 700, 140%, -0.4px);
    color: var(--gray700);

    span {
      padding-right: 4px;

      /* 상태별 텍스트 색상 */
      .status-card-body.request & {
        color: var(--status-request); /* 발송요청 텍스트 색상 */
      }
      .status-card-body.reqing & {
        color: var(--status-reqing); /* 발송중 텍스트 색상 */
      }
      .status-card-body.waiting & {
        color: var(--status-waiting); /* 대기 텍스트 색상 */
      }
      .status-card-body.success & {
        color: var(--status-success); /* 성공 텍스트 색상 */
      }
      .status-card-body.failure & {
        color: var(--status-failure); /* 실패 텍스트 색상 */
      }
    }
  }
}





</style>
