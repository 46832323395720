<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M15.833 2.52148H4.16634C3.7061 2.52148 3.33301 2.89458 3.33301 3.35482V18.3548C3.33301 18.8151 3.7061 19.1882 4.16634 19.1882H15.833C16.2933 19.1882 16.6663 18.8151 16.6663 18.3548V3.35482C16.6663 2.89458 16.2933 2.52148 15.833 2.52148Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.5 10.4395L11.0565 8.99591L12.5 10.4395ZM11.0565 8.99591C11.4335 8.61891 11.6667 8.09808 11.6667 7.52279C11.6667 6.3722 10.7339 5.43945 9.58333 5.43945C8.43275 5.43945 7.5 6.3722 7.5 7.52279C7.5 8.67337 8.43275 9.60612 9.58333 9.60612C10.1586 9.60612 10.6795 9.37295 11.0565 8.99591Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.08301 13.3555H12.9163" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.08301 15.8555H9.99967" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconSendInfo',
  };
</script>