<template>
    <div class="consoleMain" :style="mainStyles">
      <consoleNoticeLayer ref="consoleNoticeLayer"></consoleNoticeLayer>
      <div class="consoleMainMenu">
        <h1>바 차트</h1>
        <BarChart />
        <br><br>
        <h1>도넛차트</h1>
        <DonutChart />
        <h1>라인차트</h1>
        <LineChart />
        <br><br>
        <h1>레이더차트</h1>
        <RadarChart />
        <h4 style="color: red;">향후 디자이너와 논의 후 그래프종류 선정 및 대시보드 적용예정...</h4>
      </div>
    </div>

  </template>



  <script>
  import { mapState } from 'vuex';

  import consoleNoticeLayer from "@/modules/customerCenter/components/bp-consoleNoticeLayer.vue";
  import BarChart from '@/components/Chart.vue'
  import DonutChart from '@/components/DonutChart.vue'
  import LineChart from '@/components/LineChart.vue'
  import RadarChart from '@/components/RadarChart.vue'

  export default {
    components: {
      consoleNoticeLayer,
      LineChart,
      BarChart,
      DonutChart,
      RadarChart
    },
    computed: {
      ...mapState(['isSidebarOpen']),
      mainStyles() {
        return {
          width: this.isSidebarOpen ? '82%' : '100%',
          transition: 'width 0.5s ease',
          marginLeft: this.isSidebarOpen ? '18%' : '0', // 사이드바의 특정 너비가 있다면 조정
        };
      }
    }
  };
  </script>