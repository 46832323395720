import statisticsUserSendByDay from '../views/bm-statisticsUser-sendByDay'
import statisticsUserSendByMonth from '../views/bm-statisticsUser-sendByMonth'
import statisticsUserSendByMain from '../views/bm-statisticsUser-sendStatisMain'
import statisticsUserRealTime from '../views/bm-stat-ch-min'

export default [
	{
		path: '/uc/statisticsUser/sendByDay',
		name: 'sendByDay',
		meta: { menu: 'UC_DAY_CH_STATIS' },
		component: statisticsUserSendByDay
	},
	{
		path: '/uc/statisticsUser/sendByMonth',
		name: 'sendByMonth',
		meta: { menu: 'UC_MONTH_CH_STATIS' },
		component: statisticsUserSendByMonth
	},
	{
		path: '/uc/statisticsUser/sendStatisMain',
		name: 'sendByStatisMain',
		meta: { menu: 'UC_MAIN_CH_STATIS' },
		component: statisticsUserSendByMain
	},
	{
		path: '/uc/statisticsUser/realTime',
		name: 'realTime',
		meta: { menu: 'UC_REAL_TIME_STATIS'},
		component: statisticsUserRealTime
	}
]