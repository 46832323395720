<template>
    <div class="phonMockupContainer">
        <svg width="376" height="794" viewBox="0 0 376 794" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="7" y="7" width="362" height="780" rx="22" stroke="#1A1717" stroke-width="14" />
            <rect x="177" y="23" width="21" height="22" rx="10.5" fill="#070707" />
        </svg>
        <div class="contentOverlay">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        viewBox: { type: String, default: "0 0 24 24" },
        iconName: { type: String, default: "" },
        width: { type: [Number, String], default: 18 },
        height: { type: [Number, String], default: 18 },
        iconColor: { type: String, default: "currentColor" }

    }
}

</script>