<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 10.8555C1.25 6.02298 5.16751 2.10547 10 2.10547C14.8325 2.10547 18.75 6.02298 18.75 10.8555C18.75 15.688 14.8325 19.6055 10 19.6055C5.16751 19.6055 1.25 15.688 1.25 10.8555ZM11.8734 8.98047C11.8734 10.016 11.0346 10.8555 10 10.8555C8.96537 10.8555 8.12664 10.016 8.12664 8.98047C8.12664 7.94493 8.96537 7.10547 10 7.10547C11.0346 7.10547 11.8734 7.94493 11.8734 8.98047ZM14.0048 14.955C14.9781 13.9766 13.8773 12.7305 12.4978 12.7305H7.50218C6.12268 12.7305 5.02193 13.9766 5.99523 14.955C6.91419 15.8789 8.38077 16.4805 10 16.4805C11.6192 16.4805 13.0858 15.8789 14.0048 14.955Z" :fill=fill />
  </svg>
</template>

<script>
export default {
  name: 'IconUserRounded',
  props: {
    fill: {
      type: String,
      default: '#fff', // 기본 색상
    },
  },
};
</script>