<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M1.66699 18.3555H18.3337" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.24967 12.5215H3.33301V18.3548H6.24967V12.5215Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M11.6667 8.35547H8.75V18.3555H11.6667V8.35547Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M17.0837 3.35547H14.167V18.3555H17.0837V3.35547Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconStats',
  };
</script>