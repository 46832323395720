<template>
  <header id="header" class="d-flex align-items-end justify-content-between header">
    <div class="d-flex align-items-end">
      <h2 class="m-0 heading1">{{ headerTitle }}</h2>
      <p v-if="showMsgDesc" class="msg-desc f-body4">야간 메시지 발송 제한으로 21:00 ~ 다음날 08:00까지 메시지를 발송할 수 없습니다.
        <b-button v-b-tooltip.hover.html="msgTooltip" variant="light" class="btn-icon p-0">
          <IconQuestion isRed />
        </b-button>
      </p>
      <p v-else-if="showSmartMsgDesc" class="msg-desc f-body4">야간 메시지 발송 제한으로 21:00 ~ 다음날 08:00 까지 메시지 발송을 할 수 없습니다.
        <b-button v-b-tooltip.hover.html="msgTooltip" variant="light" class="btn-icon p-0">
          <IconQuestion isRed />
        </b-button>
      </p>
    </div>

    <div class="d-flex">
      <b-dropdown id="color-dropdown" text="색상변경" variant="outline-secondary" class="mr-3">
        <b-dropdown-item v-for="color in colors" :key="color" @click="changeColor(color)">
          {{ color }}
        </b-dropdown-item>
      </b-dropdown>

      <a href="https://guide.ums.ectech.co.kr/" target="_blank">
        <b-button variant="outline-primary" size="lg">이용 가이드</b-button>
      </a>

      <b-dropdown id="dropdown-dropright" right text="Drop-Right" variant="primary" class="cash-dropdown" toggle-class="btn btn-svg btn-svg-left ml-3 btn-primary btn-lg">
        <template #button-content>
          <IconCoin />
          <span>캐시현황</span>
        </template>
        <b-dropdown-text>
          <span>남은 충전캐시</span>
          <span>30,000P</span>
        </b-dropdown-text>
        <b-dropdown-text>
          <span>남은 이벤트 캐시</span>
          <span>100P</span>
        </b-dropdown-text>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-text>
          <span>남은 캐시</span>
          <span class="text-primary">100P</span>
        </b-dropdown-text>
      </b-dropdown>
    </div>
  </header>
</template>


<script>
import IconCoin from '@/components/service/icons/IconCoin.vue'
import IconQuestion from '@/components/service/icons/IconQuestion.vue'
import '@/assets/scss/service/header.scss';

export default {
  components: { IconCoin, IconQuestion, },
  name: 'ServiceHeader',
  data() {
    return {
      colors: ['default', 'orange', 'cyan', 'darkblack', 'violet'],
      userName: '신유진',  // 사용자 이름
      userEmail: 'yjshin@mstonegroove.co.kr'  // 사용자 이메일
    };
  },
  mounted() {
    this.changeColor('default');

    window.RocketChat.livechat.registerGuest({
      name: this.userName,
      email: this.userEmail
    });
  },
  computed: {
    headerTitle() {
      switch (this.$route.path) {
        case '/ac/home':
          return '현황판';
        case '/uc/message/multiSendList':
        case '/uc/message/sendSms':
        case '/uc/message/smartSendMain':
        case '/uc/rcsTemplateSend':
        case '/uc/message/sendAlimTalk':
          return '발송';
        case '/uc/messageStatus':
        case '/uc/webSend':
          return '조회';
        case '/uc/template/multiSendTemplateList':
        case '/uc/template/smsTemplateList':
        case '/uc/template/rcsTemplateList':
        case '/uc/template/alimTalkTemplateList':
          return '템플릿';
        case '/uc/statisticsUser/realTime':
        case '/uc/statisticsUser/sendByDay':
        case '/uc/statisticsUser/sendByMonth':
          return '통계';
        case '/ac/channel/rcs':
        case '/ac/channel/kakao':
          return '채널 관리';
        case '/ac/apikey':
          return 'API KEY';
        case '/ac/user/manage':
          return '사용자';
        case '/ac/sendInfo/msgSendNumber':
        case '/ac/sendInfo/rcsSendNumber':
          return '발신정보';
        case '/ac/cash/cashMain':
        case '/ac/cash/history':
        case '/ac/cash/serviceHist':
          return '정산';
        case '/ac/address/manage':
        case '/ac/address/receiver/manage':
          return '주소록';
        case '/ac/sendLimit/spam':
        case '/ac/sendLimit/sendLimitAmount':
        case '/ac/sendLimit/rejct080Num':
          return '발신제한';
        case '/ac/alarm/alarm':
        case '/ac/alarm/alarm/success':
        case '/ac/alarm/alarm/inflow':
        case '/ac/alarm/alarm/ip':
        case '/ac/alarm/alarm/prepayment':
        case '/ac/alarm/alarm/limits':
        case '/ac/alarm/alarm/average':
        case '/ac/alarm/receptGroup':
        case '/ac/alarm/recipient':
          return '알람';
        case '/ac/use/state':
          return '이용현황';
        default:
          return '';
      }
    },
    showMsgDesc() {
      return this.$route.path === '/uc/message/multiSendList';
    },
    showSmartMsgDesc() {
      return this.$route.path === '/uc/message/smartSendMain';
    }
  },
  methods: {
    msgTooltip() {
      return '<ul><li>프로젝트 기본정보에서 세팅 할 수 있습니다.</li></ul>'
    },
    changeColor(color) {
      /*
      setCorpColor 사용할 수 있도록 개선 필요
      1. _variables.scss 에 해당 색상 정의
      2. main.css 에서 해당 색상 로딩
      */
      const root = document.documentElement;
      var colorList = ['primary', 'primary50', 'primary100', 'primary200', 'primary300', 'primary400', 'primary500', 'primary600', 'primary700', 'primary800'];
      colorList.forEach(element => {
        const newColorValue = getComputedStyle(root).getPropertyValue('--' + color + '-' + element).trim();
        root.style.setProperty('--' + element, newColorValue); // CSS 변수 설정
      });

      const newColorValue = getComputedStyle(root).getPropertyValue('--' + color + '-bgColor').trim();
      root.style.setProperty('--bgColor', newColorValue); // CSS 변수 설정
    },
  }
}
</script>

<style lang="scss">
.header, .container-fluid {
  background-color: var(--bgColor, #fff); /* 기본 배경색 설정 */
}
</style>

<style scoped lang="scss">
.header {
  padding: 20px 40px 19px 40px;
  border-bottom: 1px solid var(--gray300);
  h2 {
    color: var(--gray700);
  }
  .msg-desc {
    display: flex;
    align-items: center;
    margin: 0 0 0 20px;
    color: #FF6057;
    .btn {
      margin-left: 8px;
      padding: 0;
      background-color: transparent;
      border: none;
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>