<template>
  <div class="service-home-slide d-flex flex-column justify-content-between">
    <div class="d-flex align-items-center mb-2">
      <p class="title f-title1 mb-0">{{ title }}</p>
      <router-link
        to="/ac/consoleNotice"
        style="font-size: 14px; color: blue; text-decoration: underline; font-weight: 500; margin-left: 10px;"
      >
        +더보기
      </router-link>
    </div>
    <b-carousel id="home-carousel" no-wrap controls no-interval>
      <b-carousel-slide v-for="(item, index) in notices" :key="index">
        <div class="slide-content">
          <p v-if="item.title" class="content-title f-body3 f-bold c-gray700" style="margin-bottom: 0px;">
            {{ item.title }}
            <span class="content-date f-body5 c-gray500">( {{ item.regDt }} )</span>
          </p>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>


<script>
import homeApi from '@/modules/acHome/service/api';

export default {
  name: "NoticeSlide",
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      notices: []
    };
  },
  created() {
    this.fnGetNoticeList();
  },
  methods: {
    async fnGetNoticeList() {
      try {
        const response = await homeApi.selectNoticeList({});
        const result = response.data;
        if (result.success) {
          this.notices = result.data;
        } else {
          alert(`Error: ${result.message}`);
        }
      } catch (error) {
        console.error("Failed to fetch notices:", error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as *;

.content-title {
  margin-bottom: 8px;
  @include multiline-ellipsis(1);
}
</style>
