<template>
	<div>
		<realTime />
	</div>
</template>

<script>
import realTime from '../components/bc-stat-ch-min.vue';

export default {
	components: {
		realTime
	}
}
</script>