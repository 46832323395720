<template>
  <div class="modal fade modalStyle in" id="smsConfirm" tabindex="-1" role="dialog" aria-hidden="true"  @click.self="fnClose">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <article>
              <h4 class="inline-block">승인상태 상세</h4>

              <p class="color3"><i class="far fa-info-circle"></i> 사용자가 수정요청 한 템플릿이며, 기존 템플릿으로 발송은 가능합니다.</p>
              <div class="of_h mt20">
                <div  v-if="statType == 'Y'" class="of_h">
                  탬플릿 수정 상태: <button @click.prevent="fnOpenAlimTalkTransLayer1" style="height: 28px;">운영이관요청</button>
                </div>
                <div  v-if="statType != 'Y'" class="of_h">
                  탬플릿 수정 상태: {{statTypeNm}}
                </div>
                <!-- <div class="float-right h4Button">
                    <a @click="fnReg" class="btnStyle2 borderGray">수정 템플릿 보기</a>
                </div> -->
              </div>	
              <div class="row">
                  <div class="col-xs-12 consolMarginTop">
                      <!-- table -->
                      <table  class="table_skin1 bt-000 tbl-striped">
                          <colgroup>
                              <col style="width:30%">
                              <col style="width:20%">
                              <col>
                          </colgroup>
                          <thead>
                              <tr>
                                <th class="text-center lc-1">템플릿 명</th>
                                <th class="text-center lc-1">상태</th>
                                <th class="text-center lc-1">반려사유</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(contant, idx) in contants" :key="idx">
                                <td class="text-center">{{contant.tmpltName}}</td>
                                <td class="text-center">{{contant.tmpltStatCodeName}}</td>
                                <td class="text-center">{{contant.reason}}</td>
                              </tr>
                              <tr v-if="contants.length == 0">
                                <td class="text-center" colspan="3">검색된 내용이 없습니다.</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>			
              </div>
          </article>
      </div>

          <div class="text-center mt10">
            <a href="#" @click.prevent="fnClose" class="btnStyle1 backWhite" title="닫기">닫기</a>
          </div>

      </div>
    </div>
    <AlimTalkTransLayer2 :popReset1="popReset1" :rowData1.sync="rowData1"></AlimTalkTransLayer2>
  </div>

  </template>
  
  <script>
  import confirm from "@/modules/commonUtil/service/confirm.js";
  import templateApi from "@/modules/template/service/templateApi.js";
  import AlimTalkTransLayer2 from "@/modules/template/components/bp-alimTemplateTransPrdPop.vue";
  export default {
    name: 'authPhoneNumbPopup',
    components: {
    AlimTalkTransLayer2,
  },
    props: {
      rowData : {
        type: Object,
        require: true,
      },
      popReset : {
        type : Number
      }
    },
    data(){
      return {
        tmpltCode : "",
        popReset1 : 0,
        rowData1 : {},
        contants: [],
        statType : "",
        statTypeNm : "",
      }
    },
    mounted() {
      //this.fnSelectTmpltTransList();
      
    },
    watch: {
      popReset(){
      this.tmpltCode = this.rowData.tmpltCode;
      this.fnSelectTmpltTransList();
    }
    },
    methods: {
      fnSelectTmpltTransList(){
        this.contants = [];
        let params = {
          tmpltCode: this.rowData.tmpltCode
        };
        templateApi.selectAlimTalkTmpltTransList(params).then(response =>{
          const result = response.data;
          if(result.success) {
            this.contants = result.data;
            let rtnData = result.data[0];
            this.statTypeNm = rtnData.tmpltStatCodeName;
            this.statType = rtnData.tmpltStatCode;
          } else {
            confirm.fnAlert(this.componentsTitle, result.message);
          }
        });
      },
      // 닫기
      fnClose(){
        jQuery("#smsConfirm").modal("hide");
      },
      fnReg(){
          this.$emit('update:alimTalkTransOpen', false);
      },
      //운영이관 팝업
      fnOpenAlimTalkTransLayer1(){
        var vm = this;
        vm.popReset1++;
        vm.rowData1  = {
          tmpltCode:	this.tmpltCode
        }
        
        vm.popReset1++;
        jQuery("#prdConfirm").modal("show");
      }
    }
  }
  </script>