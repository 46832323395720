<template>
<div class="modal fade modalStyle in" id="smsConfirm" tabindex="-1" role="dialog" aria-hidden="true"  @click.self="fnClose">
		<div class="modal-dialog" style="width:400px">
			<div class="modal-content">
				<div class="modal-body pd60">
					<div>
						<h2 class="text-center">인증 번호입력</h2>
						<!-- <p class="text-center mt20"><img src="" alt="아이콘1" class="auto"></p> -->
						<p v-if="authType == 'sms'" class="font-size14 text-center color1 mt10">{{ phone }}번으로 끝나는 휴대폰으로<br>인증번호를 발송하였습니다.<br>수신받은 인증번호를 입력해 주세요.</p>
            <p v-if="authType == 'email'" class="font-size14 text-center color1 mt10">{{ mail }} 이메일로 <br>인증번호를 발송하였습니다.<br>수신받은 인증번호를 입력해 주세요.</p>
						<div class="of_h mt20">
							<div class="of_h">
                <input type="text" id="certifyNumber" name="certifyNumber"  v-model.trim="certifyNumber" maxlength="6" class="inputStyle"  placeholder="인증번호 입력">  &nbsp;
                <button @click.prevent="$parent.fnSetPhoneCertify" :class="timeCounter != 0 ? 'color3': 'color1'" :disabled="timeCounter != 0" style="height: 28px;">인증번호 재요청</button>
							</div>
						</div>				
					</div>
					<div class="text-center mt40">
						<button class="btnStyle3 black font14" data-toggle="modal"  @click.prevent="fnAuthLogin" data-target="#Register">확인</button>
						<button class="btnStyle3 white font14 ml10" @click.prevent="fnClose"  data-dismiss="modal">취소</button>			
					</div>
					<p class="font-size12 text-center color2 mt15">{{timeCounter }} 초 후에 [인증번호 재요청] 버튼이 활성화 됩니다.</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import confirm from "@/modules/commonUtil/service/confirm.js";

export default {
  name: 'authPhoneNumbPopup',
  props: {
    rowData : {
      type: Object,
      require: true,
    },
    popReset : {
      type : Number
    }
  },
  data(){
    return {
      certifyNumber : "",
      phone : "",
      timeCounter : 0, //30초
      authType : "",
      mail : ""
    }
  },
  mounted() {
  },
  watch: {
    popReset(){
      clearInterval(this.polling);
      this.certifyNumber = "";
      this.phone = this.rowData.phone;
      this.authType = this.rowData.authType;
      this.mail = this.rowData.userId;

      this.timeCounter = 180;
      this.start();
    }
  },
  methods: {
    start(){ // 1초에 한번씩 start 호출 
      this.polling = setInterval( () =>{
        this.timeCounter--; // 1초씩 감소
        if (this.timeCounter == 0) this.timeStop();
      },1000)
    },
    timeStop() { 
      clearInterval(this.polling);
    },
    fnAuthLogin(){
      if(this.$gfnCommonUtils.isEmpty(this.certifyNumber)){
        confirm.fnAlert("","인증번호를 입력해주세요.");
        return;
      }
      if(this.timeCounter > 180){
        confirm.fnAlert("","인증번호를 재요청해 해주세요.");
        return;
      }
      
      this.rowData.certifyNumber = this.certifyNumber;
      this.$parent.ajaxlogin();
    },
    // 닫기
    fnClose(){
      jQuery("#smsConfirm").modal("hide");
    }
  }
}
</script>