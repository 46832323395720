<!-- 나중에 라인차트로 사용하고 싶을때 활용 -->
<template>
  <div>
    <div class="d-flex justify-content-between">
      <p class="chart-title">최근 30일 발송건수 <span class="chart-date">2024.09</span></p>
      <ul class="chart-category-list">
        <li
          v-for="(category, index) in categories"
          :key="index"
          :class="['chart-category-item', { 'disabled': chartData.datasets[index].hidden }]"
          @click="toggleDatasetVisibility(index)"
        >
          <i :style="{ backgroundColor: colors[index] }" class="rounded-circle"></i>
          <span>{{ category }}</span>
        </li>
      </ul>
    </div>
    <div class="card chart-card">
      <div class="chart-container">
        <LineChart style="height: 300px;"
          :chart-options="chartOptions"
          :chart-data="chartData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Line as ChartLine } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, LineElement, CategoryScale, LinearScale, PointElement } from 'chart.js'

ChartJS.register(Title, Tooltip, LineElement, CategoryScale, LinearScale, PointElement)

export default {
  name: 'MonthlyChart',
  components: { LineChart: ChartLine },
  data() {
    return {
      categories: ['SMS', 'LMS', 'MMS', 'KAKAO', 'RCS', '기타'],
      colors: ['#5C6EFD', '#FAA0A2', '#49A677', '#FED932', '#BD6AFF', '#F7A355'],
      chartData: {
        labels: [
          '09.01', '09.02', '09.03', '09.04', '09.05', '09.06', '09.07', '09.08', '09.09', '09.10',
          '09.11', '09.12', '09.13', '09.14', '09.15', '09.16', '09.17', '09.18', '09.19', '09.20',
          '09.21', '09.22', '09.23', '09.24', '09.25', '09.26', '09.27', '09.28', '09.29', '09.30'
        ],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          }
        },
        scales: {
          y: {
            ticks: {
              callback: function(value) {
                return value + '건'; // Y축 값에 '건' 붙이기
              },
            }
          }
        }
      }
    };
  },
  created() {
    this.generateRandomData();
  },
  methods: {
    generateRandomData() {
      const min = 10000;
      const max = 20000;

      const datasets = this.categories.map((category, index) => {
        return {
          label: category,
          data: Array.from({ length: 30 }, () => Math.floor(Math.random() * (max - min + 1)) + min),
          borderColor: this.colors[index],
          backgroundColor: this.colors[index],
          fill: false,
          lineTension: 0.4,
          pointRadius: 3,
          hidden: false
        };
      });

      this.chartData.datasets = datasets;
    },
    toggleDatasetVisibility(index) {
      const dataset = this.chartData.datasets[index];
      dataset.hidden = !dataset.hidden;
    }
  }
}
</script>

<style scoped>
.chart-category-item {
  cursor: pointer;
  user-select: none;
}
.chart-category-item.disabled span {
  text-decoration: line-through;
  color: #aaa;
}
</style>
