<template>
	<div>
		<UserManage />
	</div>
</template>

<script>
import UserManage from '../components/bc-user-manage.vue';

export default {
	components: {
		UserManage
	}
}
</script>