<template>
  <div class="message-tabs-wrap">
    <div class="message-tabs">
      <div :class="['tab', isActive('/ac/cash/cashMain')]">
        <router-link to="/ac/cash/cashMain">충전관리</router-link>
      </div>
      <div :class="['tab', isActive('/ac/cash/history')]">
        <router-link to="/ac/cash/history">이용금액</router-link>
      </div>
      <div :class="['tab', isActive('/ac/cash/serviceHist')]">
        <router-link to="/ac/cash/serviceHist">서비스 요금</router-link>
      </div>
    </div>
    <p class="breadcrumb f-body5 c-gray700">정산 > {{breadCrumbTitle}}</p>
  </div>
</template>

<script>
import '@/assets/scss/service/messageTabs.scss'

export default {
  name: "CashTabs",
  computed: {
    breadCrumbTitle() {
      switch (this.$route.path) {
        case '/ac/cash/cashMain':
          return '충전관리';
        case '/ac/cash/history':
          return '이용금액';
        case '/ac/cash/serviceHist':
          return '서비스 요금';
        default:
          return ''
      }
    },
  },
  methods: {
    isActive(link) {
      return window.location.pathname === link ? 'active' : '';
    }
  }
}
</script>