<template>
  <div class="message-tabs-wrap">
    <div class="message-tabs">
      <div :class="['tab', isActive('/uc/template/multiSendTemplateList'), isActive('/uc/template/multiSendTemplateManage')]">
        <router-link to="/uc/template/multiSendTemplateList">원스텝메시지</router-link>
      </div>
      <div :class="['tab', isActive('/uc/template/smsTemplateList'), isActive('/uc/template/smsTemplateManage')]">
        <router-link to="/uc/template/smsTemplateList">문자</router-link>
      </div>
      <div :class="['tab', isActive('/uc/template/rcsTemplateList'), isActive('/uc/template/rcsTemplateManage')]">
        <router-link to="/uc/template/rcsTemplateList">RCS</router-link>
      </div>
      <div :class="['tab', isActive('/uc/template/alimTalkTemplateList'), isActive('/uc/template/alimTalkTemplateManage')]">
        <router-link to="/uc/template/alimTalkTemplateList">카카오톡</router-link>
      </div>
    </div>
    <p class="breadcrumb f-body5 c-gray700">템플릿 > {{breadCrumbTitle}}</p>
  </div>
</template>

<script>
import '@/assets/scss/service/messageTabs.scss'

export default {
  name: "TemplateTabs",
  computed: {
    breadCrumbTitle() {
      switch (this.$route.path) {
        case '/uc/template/multiSendTemplateList':
          return '원스텝메시지';
        case '/uc/template/multiSendTemplateManage':
          return '원스텝메시지 > 템플릿 등록';  
        case '/uc/template/smsTemplateList':
          return '문자';
        case '/uc/template/smsTemplateManage':
          return '문자 > 템플릿 등록';
        case '/uc/template/rcsTemplateList':
          return 'RCS';
        case '/uc/template/rcsTemplateManage':
          return 'RCS > 템플릿 등록';
        case '/uc/template/alimTalkTemplateList':
          return '카카오톡';
        case '/uc/template/alimTalkTemplateManage':
          return '카카오톡 > 템플릿 등록';
        default:
          return ''
      }
    },
  },
  methods: {
    isActive(link) {
      return window.location.pathname === link ? 'active' : '';
    }
  }
}
</script>