<template>
	<div>
		<article>
			<div class="clear" style="margin-top: 30px;">
				<h4 class="inline-block">발신번호 조회</h4>	
			</div>

			<div class="row consolMarginTop">
				<div class="col-xs-12">
					<div class="menuBox">						
						<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 등록방법이 RCS 인 발신번호는 RCS 발신번호 메뉴에서 삭제가 가능합니다.</p>
						<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 발신번호명 또는 발신번호명 등록 클릭 시 입력 및 수정이 가능합니다.</p>
						<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> 처리상태가 요청 및 접수일 경우 수정 및 삭제가 되지 않습니다.</p>
					</div>
				</div>
			</div>

			<div class="row mt15" >
				<div class="col-xs-12">
					<div class="menuBox">						
						<div class="of_h">
							<div>
								<h4 class="inline-block" style="width:6%">발신번호</h4>
								<input type="text" class="inputStyle" style="width:16%" v-model="srcCallNum" @keyup.enter="fnSearch(1)" placeholder="숫자만 입력">
								<h4 class="inline-block ml60" style="width:6%">발신번호명</h4>
								<input type="text" class="inputStyle" style="width:16%" v-model="srcCallNumNm" @keyup.enter="fnSearch(1)">
							</div>
							<div style="margin-top: 10px;">
								<h4 class="inline-block" style="width:6%">RCS 상태</h4>
								<select class="selectStyle2" style="width:16%" v-model="srcRcsState" @change="fnSearch(1)">
									<option value="">전체</option>
									<option value="승인대기">승인대기</option>
									<option value="승인">승인</option>
									<option value="반려">반려</option>
								</select>
								<h4 class="inline-block ml60" style="width:6%">문자상태</h4>
								<select class="selectStyle2" style="width:16%" v-model="srcSmsState" @change="fnSearch(1)">
									<option value="">전체</option>
									<option value="10">요청</option>
									<option value="20">접수</option>
									<option value="80">승인</option>
									<option value="90">반려</option>
									<option value="91">차단</option>
								</select>
								<a @click="fnSearch(1)" class="btnStyle1 float-right">검색</a>
							</div>		
						</div>						
					</div>
				</div>
			</div>
			
			<div class="float-right" style="margin-top: 10px;">
				<a @click="fnExcelDownLoad" class="btnStyle2 borderGray ml10" title="엑셀 다운로드" activity="READ">엑셀 다운로드 <i class="fal fa-arrow-to-bottom"></i></a>
			</div>
			<div class="row mt20">
				<div class="col-xs-12">		
					<div class="of_h">
						<!-- 15개씩 보기 -->
						<div class="of_h inline">
							<div class="float-left">전체 : <span class="color1"><strong>{{totCnt | formatComma}}</strong></span>건
								<SelectLayer @fnSelected="fnSelected" classProps="selectStyle2 width120 ml20"></SelectLayer>
							</div>
						</div>
						<!-- //15개씩 보기 -->
					</div>
				</div>			
			</div>
			
			<div class="row">
				<div class="col-xs-12">
					<!-- table -->
					<table class="table_skin1 bt-000 tbl-striped mt10">
						<colgroup>
							<col style="width:18%">
							<col style="width:18%">
							<!-- <col style="width:10%"> -->
							<col>
							<col>
							<col style="width:12%">
							<col style="width:12%">
							<col style="width:12%">
						</colgroup>
						<thead>
							<tr>
							<th class="text-center lc-1">발신번호</th>
							<th class="text-center lc-1">발신번호명</th>
							<th class="text-center lc-1">처리 상태</th>
							<th class="text-center lc-1">등록 방법</th>
							<th class="text-center lc-1">요청일자</th>
							<th class="text-center lc-1">승인일자</th>
							<th class="text-center lc-1 end">관리</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in data" :key="index">
								<td class="text-center">{{row.callNum | hpNumberAddDash}}</td>
								<td v-if="row.regWay == 'RCS Biz'" class="text-center"><h6><a data-toggle="modal" data-target="#callNumModifyNo" style="text-decoration: underline;">{{row.callNumNm}}</a></h6></td>
								<td v-if="(row.callNumNm != '' && row.callNumNm != null) && !(row.smsState == '10' || row.smsState == '20') && (row.regWay != 'RCS Biz')" class="text-center"><h6><a @click="fnCallNumSet(index)" data-toggle="modal" data-target="#callNumModify" style="text-decoration: underline;">{{row.callNumNm}}</a></h6></td>
								<td v-if="(row.callNumNm != '' && row.callNumNm != null) && (row.smsState == '10' || row.smsState == '20') && (row.regWay != 'RCS Biz')" class="text-center"><h6><a data-toggle="modal" data-target="#callNumModifyNo" style="text-decoration: underline;">{{row.callNumNm}}</a></h6></td>
								<td v-if="(row.callNumNm == '' || row.callNumNm == null) && !(row.smsState == '10' || row.smsState == '20') && (row.regWay != 'RCS Biz')" class="text-center"><h6><a @click="fnCallNumSet(index)" data-toggle="modal" data-target="#callNumModify" style="text-decoration: underline; color: red;">발신번호명 등록</a></h6></td>
								<td v-if="(row.callNumNm == '' || row.callNumNm == null) && (row.smsState == '10' || row.smsState == '20') && (row.regWay != 'RCS Biz')" class="text-center"><h6><a data-toggle="modal" data-target="#callNumModifyNo" style="text-decoration: underline; color: red;">발신번호명 등록</a></h6></td>
								<td v-if="!(row.smsState == '90' || row.smsState == '91')" class="text-center">{{row.smsStateNm}}</td>
								<td v-if="row.smsState == '90' || row.smsState == '91'" class="text-center"><a @click="fnCallNumReasonSet(index)" data-toggle="modal" data-target="#reject"  class="color1" style="text-decoration:underline">{{row.smsStateNm}}</a></td>
								<td class="text-center">{{row.regWay}}</td>
								<td class="text-center">{{row.reqDt}}</td>
								<td class="text-center">{{row.handleDt}}</td>
								<td v-if="row.smsState == '' || row.smsState == '10' || row.smsState == '20' || row.smsState == '90' || row.smsState == '91'" class="end"></td>
								<td v-if="!(row.smsState == '' || row.smsState == '10' || row.smsState == '20' || row.smsState == '90' || row.smsState == '91')" class="end"><a @click="fnClearConfirm(index)" class="btnStyle1 borderLightGray small">삭제</a></td>
							</tr>
							<tr v-if="data.length == 0"> 
								<td class="text-center" colspan="10">검색된 내용이 없습니다.</td>
							</tr>
						</tbody>
					</table>
					<!-- //table -->
				</div>			
			</div>

			<!-- pagination -->
			<div id="pageContent">
				<PageLayer @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></PageLayer>
			</div>
			<!-- //pagination -->


			<!-- //본문 -->
		</article>

		<!-- reject Modal -->
		<div class="modal modalStyle" id="reject" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" style="width:650px">
				<div class="modal-content">
					<div class="modal-body">					
						<h2>반려</h2>
						<hr>
						<p class="text-center mt20">{{setHandleReason}}</p>		
						<div class="text-center mt20">
							<a class="btnStyle1 backWhite" data-dismiss="modal" title="취소">닫기</a>						
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- callNumModify Modal -->
		<div class="modal modalStyle" id="callNumModify" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" style="width:650px">
				<div class="modal-content">
					<div class="modal-body">					
						<h3>발신번호 수정</h3>
						<hr>
						<div class="of_h">
							<h5 class="inline-block vertical-middle" style="width:22% ;text-align: left;">발신번호명</h5>
							<div class="float-right" style="width:76%">
								<input type="text" class="float-left"  v-model="setCallNumNm" style="width: 80%;">
								<input type="hidden" class="float-left"  v-model="setCallNum">
							</div>
						</div>
						<div class="text-center mt20">
							<a @click="fnCallNumModify" class="btnStyle1 borderGray ml10" data-dismiss="modal" activity="SAVE" >수정</a> &nbsp;
							<a class="btnStyle1 backWhite" data-dismiss="modal" title="취소">닫기</a>						
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="modal modalStyle" id="callNumModifyNo" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" style="width:650px">
				<div class="modal-content">
					<div class="modal-body">					
						<h3>발신번호 수정 불가</h3>
						<hr>
						<p class="text-center mt20">등록방법이 RCS Biz 이거나 처리상태가 요청 및 접수일 경우에는 발신번호명을 수정할 수 없습니다.</p>		
						<div class="text-center mt20">
							<a class="btnStyle1 backWhite" data-dismiss="modal" title="취소">닫기</a>						
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import sendInfoApi from '../service/sendInfoApi'
import SelectLayer from '@/components/SelectLayer.vue';
import PageLayer from '@/components/PageLayer.vue';
import {eventBus} from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
	components: {
		PageLayer,
		SelectLayer,
	},
	watch: {
	},
	data() {
		return {
			// 검색 조건
			srcCallNum	: "",
			srcCallNumNm : "",
			srcRcsState	: "",
			srcSmsState	: "",

			// 리스트 
			data : {},
			pageInfo: {},
			row_data : {},
			listSize : 10,  // select 박스 value (출력 갯수 이벤트)
			pageNo : 1,  // 현재 페이징 위치
			totCnt : 0,  //전체 리스트 수
			offset : 0, //페이지 시작점
			detailCnt : 0,
			rcsOpen: false,
			smsOpen: false,
			projects : [],
			idx : 0,
			//팝업
			setCallNum : '',
			setCallNumNm : '',
			setHandleReason : '',
		}
	},
	mounted() {
		this.fnSearch(1);
	},
	methods: {
		// select 박스 선택시 리스트 재출력
		fnSelected(listSize) {
			this.listSize = Number(listSize);
			this.$refs.updatePaging.fnAllDecrease();
		},
		// 검색
		fnSearch(pageNo) {
			var params = {
				"srcCallNum"	: this.srcCallNum,
				"srcCallNumNm"	: this.srcCallNumNm,
				"srcRcsState"	: this.srcRcsState,
				"srcSmsState"	: this.srcSmsState,
				"pageNo"		: (this.$gfnCommonUtils.defaultIfEmpty(pageNo, '1'))*1,
				"listSize"		: this.listSize
			}
			
			sendInfoApi.getCallNumList(params).then(response =>{
				var result = response.data;
				if(result.success) {
					this.data = result.data; 
					this.totCnt = result.pageInfo.totCnt;
					this.offset = result.pageInfo.offset;
				}
			});
		},
		fnCallNumReasonSet(idx) {
			this.idx = idx;
			this.setHandleReason = this.data[this.idx].handleReason;
		},
		fnClearConfirm(idx){
			this.idx = idx;
			eventBus.$on('callbackEventBus', this.fnClear);
			confirm.fnConfirm("", "해당 발신 번호를 삭제 하시겠습니까?", "확인");
		},
		fnClear() {
			var params = {
				'callNum'  : this.data[this.idx].callNum,
				'callNumNm'  : this.data[this.idx].callNumNm,
				'brandId'  : this.data[this.idx].brandId,
				'chatbotId'  : this.data[this.idx].chatbotId,
			};

			sendInfoApi.delCallNum(params).then(response =>{
				var result = response.data;
				if( result.success ){
					confirm.fnAlert("", "삭제 처리되었습니다.");
					this.fnSearch(1);
				} else {
					confirm.fnAlert("", response.data.message);
				}
			});
		},
		fnCallNumSet(idx) {
			this.idx = idx;
			this.setCallNumNm = this.data[this.idx].callNumNm;
			this.setCallNum = this.data[this.idx].callNum;
		},
		fnCallNumModify() {
			//유효성 검사
			if(this.setCallNumNm == null || this.setCallNumNm == '') {
				confirm.fnAlert('', '발신번호명을 입력해주세요.');
				return false;
			}
	
			let params = {
				'callNum': this.$gfnCommonUtils.hpNumberRemoveDash(this.setCallNum),
				'callNumNm': this.setCallNumNm
			};
			sendInfoApi.updateCallNumNm(params).then(response =>{
				var result = response.data;
				if(result.success) {
					this.callNum2 = '';
					this.callNumNm2 = '';
					confirm.fnAlert('', '수정을 성공했습니다.');
					this.fnSearch(1);
				} else {
					confirm.fnAlert('', result.message);
				}
			});
		},
		//문자 발신번호 엑셀 다운로드
		fnExcelDownLoad(){
			const params = {
				"srcCallNum"	: this.srcCallNum,
				"srcCallNumNm"	: this.srcCallNumNm,
				"srcRcsState"	: this.srcRcsState,
				"srcSmsState"	: this.srcSmsState,
			}
			sendInfoApi.excelDownloadRcsCallnumList(params);
   		},
	}

}
</script>