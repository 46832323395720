<template>
  <div class="w-100">
    <div class="d-flex justify-content-between">
      <p class="chart-title">당일 발송성공율 <span class="chart-date">2024.09</span>
        <router-link
          to="/uc/statisticsUser/sendByDay"
          style="font-size: 14px; color: blue; text-decoration: underline; margin-left: 10px; font-weight: 500;"
        >
          +더보기
        </router-link>
      </p>
      <ul class="chart-category-list">
        <li
          v-for="(category, index) in categories"
          :key="index"
          class="chart-category-item"
        >
          <i :style="{ backgroundColor: colors[index] }" class="rounded-circle"></i>
          <span>{{ category }}</span>
        </li>
      </ul>
    </div>
    <div class="card chart-card" style="height: 208px;">
      <div class="chart-container" style="top: 30px;">
        <Bar style="height: 180px;"
          :chart-options="chartOptions"
          :chart-data="chartData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, BarElement, CategoryScale, LinearScale)

export default {
  name: 'PercentChart',
  components: { Bar },
  data() {
    return {
      categories: ['성공률'],
      colors: ['#5C6EFD', '#FAA0A2', '#49A677', '#FED932', '#BD6AFF'],
      chartData: {
        labels: ['SMS', 'LMS', 'MMS', 'KAKAO', 'RCS'],
        datasets: []
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: {
            left: 10,
            right: 10,
            top: 10,
            bottom: 10
          }
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
          datalabels: {
            display: false,
          }
        },
        scales: {
          x: {
            categoryPercentage: 0.4,
            barPercentage: 0.5,
            maxBarThickness: 15,
            minBarLength: 2
          },
          y: {
            ticks: {
              callback: function(value) {
                return value + '%';
              }
            }
          }
        }
      }
    }
  },
  created() {
    this.generateRandomData();
  },
  methods: {
    generateRandomData() {
      const datasets = this.categories.map((category, index) => {
        return {
          label: category,
          data: [60, 40, 70, 50, 80, 30], // 임의의 퍼센트 데이터
          backgroundColor: this.colors,
          borderColor: this.colors,
          borderWidth: 1,
          borderRadius: 8,
        }
      });

      this.chartData.datasets = datasets;
    }
  }
}
</script>
