<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M9.58366 2.52148H1.66699V11.6881H9.58366V2.52148Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M18.3337 15.0215H1.66699V18.7715H18.3337V15.0215Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M18.3337 2.52148H12.917V5.85481H18.3337V2.52148Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M18.3337 8.35547H12.917V11.6888H18.3337V8.35547Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconTemplate',
  };
</script>