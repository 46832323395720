<template>
    <section class="publicAllSection">
            <div class="publicAllwrap">
                <div class="publicAllcontent">
                <div class="idsection">
                    <div class="idHead">e-UMS 비밀번호 재설정</div>
                    <div class="idinputwrap">
                        <span class="pw_email">이메일</span>
                        <label>
                            <input type="" class="pw_input" placeholder="이메일 주소를 입력해주세요." v-model="email">
                        </label>
                        
                            <div class="pw_comment">
                                <span>이메일 인증 유효 시간은&nbsp;<span class="point_blue">1시간</span>&nbsp;입니다.</span>
                                <!-- <span>남은 인증 유효 시간&nbsp;<span class="point_red">23:59:10</span></span> -->
                            </div>


                            <div class="pw_comment">
							<!-- <p class="font-size12 color3">Google메일을 이용할 시 이메일 인증에는 문제가 없으나,</p> -->
							<p class="font-size12 color3">Google메일을 이용할 시 이메일 인증에는 문제가 없으나,구글의 보안프록시 서버를 사용하여 <br>이미지를 제공하기 때문에 본문 상 이미지가 보이지 않을 수 있습니다.</p>
						    </div>
                    </div>
                    <button class="submitButton"  @click.prevent="fnSendMail">비밀번호 재설정 메일 발송</button>
                    <div class="mail_repeat">
                        메일을 못 받으셨나요?&nbsp;&nbsp;&nbsp;
                        <span class="point_boldblue"><a href="#self" @click.prevent="fnSendMail">메일 재발송 클릭</a></span>
                    </div>
                 
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import loginApi from '@/modules/login/service/api';
import * as utils from '@/common/utils';
import confirm from "@/modules/commonUtil/service/confirm";

export default {
	data: function() {
		return {
			errors: [],
			userId: '',
			loginPwd: null,
			status: '',
			email : ""
		};
	},
	created() {
	},
	mounted() {
	},
	methods: {
		fnSendMail(){
			var params = {
				email : this.email,
				location : "/login/setUserPwd"
			};

			loginApi.sendCertifyMail(params).then(response => {
				var result = response.data;
				var vm = this;
				if(!result.success){
					confirm.fnAlert("", result.message);
					return;
				} else {
					vm.$router.push({name : "mailSend"});
				}
			});
		}
	}
};
</script>