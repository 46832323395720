<template>
  <div class="modal modalStyle in" id="qnaPop" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
          <div class="of_h">
            <h2>1:1 문의</h2>
            <hr>
            <div class="of_h">
              <h5 class="inline-block" style="width:18%">문의유형 <span class="color1"
                  v-if="this.status != 'detail'">*</span></h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">
              {{ qnaTypeStr }}</h5>
              <div v-else class="inline-block float-right qnaTypeArr" style="width:80%">
                <div v-for="(row, idx) in qnaTypeArr">
                  <input :key="idx" type="radio" name="inqueiryTypeQ" :value="row.codeVal1"
                    :id="'inqueiryTypeQ_'+row.codeVal1" v-model="qnaType">
                  <label :key="idx+'_sub'" :for="'inqueiryTypeQ_'+row.codeVal1"
                    class="mr20 font-size14">{{row.codeName1}}</label>
                </div>
              </div>
            </div>
            <div class="of_h consolMarginTop">
              <h5 class="inline-block" style="width:18%">회사명 <span class="color1"
                  v-if="this.status != 'detail'">*</span></h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">
                {{ corpName }}</h5>
              <div v-else class="float-right" style="width:80%">
                <input type="text" class="inputStyle" v-model="corpName" disabled>
              </div>
            </div>
            <div class="of_h consolMarginTop">
              <h5 class="inline-block" style="width:18%">이름 <span class="color1" v-if="this.status != 'detail'">*</span>
              </h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">{{ name
                }}</h5>
              <div v-else class="float-right" style="width:80%">
                <input type="text" class="inputStyle" v-model="name">
              </div>
            </div>
            <div class="of_h consolMarginTop">
              <h5 class="inline-block" style="width:18%">이메일 <span class="color1"
                  v-if="this.status != 'detail'">*</span></h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">{{ email
                }}</h5>
              <div v-else class="float-right" style="width:80%">
                <input type="text" class="inputStyle" v-model="email">
              </div>
            </div>
            <div class="of_h consolMarginTop">
              <h5 class="inline-block" style="width:18%">전화번호 <span class="color1"
                  v-if="this.status != 'detail'">*</span></h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">{{
                hpNumber | hpNumberAddDash }}</h5>
              <div v-else class="float-right" style="width:80%">
                <input type="text" class="inputStyle" v-model="hpNumber" placeholder="-없이 입력하세요">
              </div>
            </div>
            <div class="of_h consolMarginTop">
              <h5 class="inline-block" style="width:18%">문의제목 <span class="color1"
                  v-if="this.status != 'detail'">*</span></h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">{{ title
                | unescapeXss}}</h5>
              <input v-else type="text" class="inputStyle float-right" style="width:80%" placeholder="제목을 입력하세요(100자)"
                v-model="title">
            </div>
            <div class="of_h consolMarginTop">
              <h5 class="inline-block" style="width:18%">문의내용 <span class="color1"
                  v-if="this.status != 'detail'">*</span></h5>
              <h5 v-if="this.status == 'detail'" class="font-normal inline-block float-right" style="width:80%">
                <pre>{{ content | unescapeXss}}</pre>
              </h5>
              <textarea v-else class="textareaStyle height120 float-right" style="width:80%"
                placeholder="문의내용을 입력하세요(500자)" v-model="content"></textarea>
            </div>

            <div class="of_h consolMarginTop float-right file-upload" style="width:80%" v-if="this.status != 'detail'">
                <input type="text" class="upload-name" v-model="attachFileNm" disabled v-if="attachFileNm">
                <input type="file" id="attachCorpImg" @change="fnFileChange(0)" ref="attachFile" style="display:none;">
                <button @click="fnAttachCorpImg(0)" class="fileinput_button">
                    <img src="../../../assets/images/image/fileadd.png" class="file_img">
                    파일첨부
                </button>
                <button v-show="attachFileNm !== ''" @click="fnFileDel(0)" class="file_del" style="width: 15%">삭제</button>
                <input type="file" id="ex_filename" class="upload-hidden">
                <div class="filecommentsection">
                    <p class="addcomment">첨부 가능 : 사진, PDF, 한글, 엑셀, PPT</p>
                    <p class="addcomment">파일은 5MB까지 가능합니다.</p>
                </div>
            </div>

            <div v-if="this.status == 'detail'">
              <div class="of_h consolMarginTop2">
                <h5 class="inline-block" style="width:20%">첨부파일</h5>
                <h5 class="font-normal inline-block float-right" style="width:20%">
                  {{ attachFileNm }}
                </h5>
                <a v-show="attachFileNm" @click.prevent="fnFileDown(selectRow.fileId, selectRow.attachFileName)" :value="attachFileNm" class="btnStyle7 vertical-middle minWidthAuto"
                style="min-width:auto; width:20%">다운로드</a>
              </div>
              <div class="of_h consolMarginTop">
                <h5 class="inline-block" style="width:18%">문의상태</h5>
                <h5 class="font-normal inline-block float-right" style="width:80%">{{ qnaStatusStr }}</h5>
              </div>
              <hr v-if="this.qnaStatus == '03'">
              <div class="of_h consolMarginTop" v-if="this.qnaStatus == '03'">
                <h5 class="inline-block" style="width:18%">답변자</h5>
                <h5 class="font-normal inline-block float-right" style="width:80%">
                  <pre>{{ replyUser }}</pre>
                </h5>
              </div>
              <div class="of_h consolMarginTop" v-if="this.qnaStatus == '03'">
                <h5 class="inline-block" style="width:18%">답변 내용</h5>
                <h5 class="font-normal inline-block float-right" style="width:80%">
                  <pre>{{ reply | unescapeXss}}</pre>
                </h5>
              </div>
              <div class="of_h consolMarginTop2" v-if="this.qnaStatus == '03'">
                <h5 class="inline-block" style="width:20%">첨부 파일</h5>
                <h5 class="font-normal inline-block float-right" style="width:20%">
                  <pre>{{ replyAttachFileNm }}</pre>
                </h5>
                <a v-show="replyAttachFileNm" @click.prevent="fnFileDown(selectRow.replyFileId, selectRow.replyAttachFileName)" :value="replyAttachFileNm" class="btnStyle7 vertical-middle minWidthAuto"
                style="min-width:auto; width:20%">다운로드</a>
              </div>
              <div class="of_h consolMarginTop" v-if="this.qnaStatus == '03'">
                <h5 class="inline-block" style="width:18%">답변 일자</h5>
                <h5 class="font-normal inline-block float-right" style="width:80%">
                  <pre>{{ replyDt }}</pre>
                </h5>
              </div>
            </div>
          </div>
          <div class="text-center mt20">
            <a @click="fnSave" v-if="this.status != 'detail'" class="btnStyle1 backBlack" data-toggle="modal"
              data-target="">등록</a>
            <a @click="fnCloseLayer" class="btnStyle1 backWhite ml10" data-toggle="modal" data-target="">취소</a>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import confirm from "@/modules/commonUtil/service/confirm.js";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import customerCenterApi from "@/modules/customerCenter/service/api.js";
import commonUtilApi from "@/modules/commonUtil/service/commonUtilApi";

import tokenSvc from '@/common/token-service';

export default {
  name: 'consoleQnA',
  data() {
    return {
      questBoardId: '',   
      qnaType: '',       // 문의 유형
      qnaTypeStr: '',    // 문의 유형 문자열
      email: '',         // 이메일
      hpNumber: '',      // 전화번호
      title: '',         // 제목
      content: '',       // 내용
      qnaStatus: "",
      qnaStatusStr: '',  // 문의 상태
      reply: '',         // 답변
      replyUser: "",     // 답변자
      replyDt: "",       // 답변 일자
      name: "",          // 이름
      qnaTypeArr: [],
      corpName: "",
      memberInfo: {},
      fileId : '',
      attachFileNm: '',  // 첨부 파일명
      replyFileId : '',
      replyAttachFileNm: '',  // 운영자 답변 첨부 파일명
      uploadedFileName: "파일선택",
      uploadedFileSize: "0",
      maxFileSize: 5, // MB 단위
      fileDel: false,  // 파일 삭제 여부
    }
  },
  props: {
    status: {
      type: String
    },
    selectRow: {
      type: Object,
    },
    popReset: {
      type: Number
    }
  },
  watch: {
    popReset() {
      this.fnDataReset();
    },
    hpNumber() {
      this.hpNumber = this.$gfnCommonUtils.hpNumberAddDash(this.hpNumber);
    }
  },
  mounted() {
    this.fnSelectQnaType();
    this.fnMemberInfo();
  },
  methods: {
    fnSelectQnaType() {
      const params = {
        codeTypeCd: "QNA_TYPE",
        useYN: "Y"
      };

      customerCenterApi.selectCodeList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.qnaTypeArr = Object.assign({}, result.data);
        } else {
          confirm.fnAlert(this.componentsTitle, result.message);
        }
      });
    },
    fnCloseLayer() {
      jQuery("#qnaPop").modal("hide");
    },
    // 회원정보
    fnMemberInfo() {
      var params = {
        userId: tokenSvc.getToken().principal.userId
      };
      customerCenterApi.selectMemberInfo(params).then(response => {
        var result = response.data;
        if (result.success) {
          this.memberInfo = result.data;
        }
      });
    },
    // 데이터 초기화
    fnDataReset() {
      this.questBoardId = this.status == "add" ? '' : this.selectRow.questBoardId;
      this.qnaType = this.status == "add" ? "" : this.selectRow.qnaType;
      this.qnaTypeStr = this.selectRow.questTypeStr;
      this.title = this.selectRow.title;
      this.hpNumber = this.status == "add" ? this.memberInfo.hpNumber : this.selectRow.hpNumber;    // 신규 문의 사항인 경우 로그인 회원의 전화번호
      this.email = this.status == "add" ? this.memberInfo.loginId : this.selectRow.email;           // 신규 문의 사항인 경우 로그인 회원의 이메일
      this.name = this.memberInfo.userName;
      this.content = this.selectRow.content;
      this.attachFileNm = this.status == "add" ? "" : this.selectRow.attachFileName;
      this.replyAttachFileNm = this.selectRow.replyAttachFileName;
      this.attachFile = this.status == "add" ? "" : this.selectRow.attachFile;
      this.qnaStatus = this.status == "add" ? "" :this.selectRow.questStatus;
      this.qnaStatusStr = this.selectRow.questStatusStr;
      this.reply = this.selectRow.reply;
      this.replyUser = this.selectRow.replyUser;
      this.replyDt = this.selectRow.replyDt;
      this.corpName = this.memberInfo.corpName;
    },
    fnSave() {
      if (this.qnaType == undefined || this.qnaType == '') {
        confirm.fnAlert("", "문의유형은 필수선택항목입니다.");
        return;
      }
      if (this.email == undefined || this.email == '') {
        confirm.fnAlert("", "이메일은 필수항목입니다.");
        return;
      }
      if (this.hpNumber == undefined || this.hpNumber == '') {
        confirm.fnAlert("", "전화번호는 필수항목입니다.");
        return;
      }

      if (this.title == undefined || this.title == '') {
        confirm.fnAlert("", "문의제목은 필수항목입니다.");
        return;
      }
      if (this.content == undefined || this.content == '') {
        confirm.fnAlert("", "문의내용은 필수항목입니다.");
        return;
      }

      eventBus.$on('callbackEventBus', this.fnSaveCallBack);
      confirm.fnConfirm("문의내역 저장", "저장하시겠습니까?", "확인");
    },

    async fnSaveCallBack() {
    var fd = new FormData();
            fd.append('questBoardId', this.questBoardId);
            fd.append('questType', this.qnaType);
            fd.append('corpName', this.corpName);
            fd.append('name', this.name);
            fd.append('email', this.email);
            fd.append('hpNumber', this.hpNumber);
            fd.append('title', this.title);
            fd.append('content', this.content);
            fd.append('userId', tokenSvc.getToken().principal.userId);
            if (this.$refs.attachFile.files.length > 0) {
              fd.append('attachFile', this.$refs.attachFile.files[0]);
            }
            fd.append('fileDel', this.fileDel);

      try {
        const response = await customerCenterApi.saveQnaInfo(fd);
        const result = response.data;
        if (result.success) {
          confirm.fnAlert("저장되었습니다. 답변은 메일로 받으실 수 있습니다.", "");
          this.fnCloseLayer();
          this.$parent.fnSearch();
        } else {
          confirm.fnAlert(result.message, "");
        }
      } catch (error) {
        confirm.fnAlert(error.message);
      }
    },

    // 첨부파일 다운로드
    fnFileDown: function(fileId, name) {
    if (fileId != "") {
      var params = {
        fileId: fileId,
        fileNm: name
      };
      console.log(params);
      commonUtilApi.downloadFile(params).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
        // Blob URL 해제
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }).catch(error => {
        console.error('File download failed:', error);
      });
    }
    },

    // 첨부파일 입력
    fnAttachCorpImg(num) {
      if (num == 0) {
        this.$refs.attachFile.click();
        this.fileDel = false;
      }
    },
    fnFileChange(num) {
          if (num == 0) {
            const attachFile = this.$refs.attachFile.files[0];
            this.fileDel = false;
            if (attachFile) {
              const attachFileNm = attachFile.name;
              const ext = attachFileNm.slice(attachFileNm.lastIndexOf(".") + 1).toLowerCase();
              const allowedExtensions = ['jpg', 'jpeg', 'gif', 'png', 'tiff', 'hwp', 'pdf', 'xls', 'xlsx', 'ppt', 'pptx', 'doc', 'docx'];
              const maxFileSizeMB = 5; // 5 MB로 설정

              if (attachFile.size / (1024 * 1024) > maxFileSizeMB) {
                confirm.fnAlert("첨부할 수 있는 파일 용량은 5MB를 넘을 수 없습니다.");
                // 파일 선택 창 초기화
                this.$refs.attachFile.value = null;
                this.attachFileNm = "";
                this.uploadedFileSize = "0";
              } if (!(allowedExtensions.includes(ext))) {
                confirm.fnAlert("첨부 가능 파일 형식은 jpg, jpeg, gif, png, tiff, hwp, pdf, xls, xlsx, ppt, pptx, doc, docx입니다.");
                // 파일 선택 창 초기화
                this.$refs.attachFile.value = null;
                this.attachFileNm = "";
                this.uploadedFileSize = "0";
              } else {
                this.attachFileNm = attachFileNm;
                this.uploadedFileSize = (attachFile.size / (1024 * 1024)).toFixed(2); // Update file size
              }
            }
          }
        },

    fnFileDel(num) {
      if (num == 0) {
        this.attachFileNm = "";
        this.fileDel = true;
        this.uploadedFileName = "파일선택";
        this.uploadedFileSize = "0";
        this.$refs.attachFile.value = null;
      }
    }
  },
}

</script>

<style>
.qnaTypeArr {
  display: flex !important;
}
.file-upload {
  margin-top: 10px;
  display: flex !important;
  flex-wrap: wrap;
}
.filecommentsection {
  font-size: 1.5rem;
  color: #6e6e6e;
}
.consolMarginTop2 {
  width: 100%;
  margin-top: 10px;
  align-items: center;
  display: flex;
}

.btnStyle7 {
    background: #F1F1F1;
    color: #222;
    border: 1px solid #ddd;
    border-radius: 2px;
    padding: 6px 10px;
    font-weight: 500;
    display: inline-block;
    transition: 0.3s;
    min-width: 120px;
    text-align: center;
}
</style>