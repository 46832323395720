<template>
  <b-modal id="enter-receiver-modal" title="내용 입력" hide-header-close centered size="md">
    <template #modal-title>
      <h5 class="modal-title">수신자 직접입력</h5>
      <div class="d-flex align-items-end justify-content-between w-100">
        <p class="title-desc">* 입력 후 선택 버튼을 누르면 수신자에 추가됩니다.<br/>
          <span>* 최대 30개까지 추가 가능합니다.</span></p>
        <b-button variant="outline-primary" class="btn-svg btn-svg-right ms-auto" @click="addReceiver">
          <span>수신자 추가</span>
          <IconPlus />
        </b-button>
      </div>
    </template>

    <div class="d-flex form-row">
      <label class="mb-1 pb-2 f-body5 c-gray500">휴대폰 번호<span class="require">*</span></label>
      <label class="mb-1 pb-2 f-body5 c-gray500">이름<span class="require">*</span></label>
    </div>

    <div v-for="(receiver, index) in receivers" :key="index">
      <div class="d-flex form-row input-row" :class="index > 0 ? 'mt-1 pt-3' : ''">
        <b-input v-model="receiver.phone" placeholder="010-1234-5678"></b-input>
        <b-input v-model="receiver.name" placeholder="이름 입력"></b-input>
      </div>
    </div>

    <template #modal-footer>
      <b-button variant="primary" :disabled="receivers.length === 0" @click="fnCallbackInputData">선택</b-button>
      <b-button variant="outline-primary" @click="closeModal">닫기</b-button>
    </template>
    <AlertModal title="주소록 검색" desc="수신자를 추가하였습니다." :callbackFunc="closeModal" />
  </b-modal>
</template>

<script>
import IconPlus from '@/components/service/icons/IconPlus.vue'
import AlertModal from '@/components/service/modal/AlertModal.vue'

export default {
  components: { IconPlus, AlertModal },
  name: "EnterReceiverModal",
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    directInputOpen: {
      type: Boolean,
      require: true,
      default: false,
    },
    requiredCuPhone: {
      type: Boolean,
      require: true,
      default: true,
    },
    requiredCuid: {
      type: Boolean,
      require: true,
      default: true,
    },
    contsVarNms : {
      type: Array,
      require: true
    },
    recvInfoLst : {
      type: Array,
      require: false,
      default: function() {
        return [];
      }
    },
    componentsTitle: {
      type: String,
      require: false,
      default: function() {
        return '수신자 직접입력';
      }
    },
  },
  data() {
    return {
      loopCnt: 3,
      headerList: [],
      receivers: [
        { phone: '', name: '' },
      ],
    }
  },
  watch: {
    directInputOpen(val){
      if(val){
        if(this.recvInfoLst.length > this.loopCnt) this.loopCnt = this.recvInfoLst.length;

        if(this.recvInfoLst.length > 0){
          for(let i = 0 ; i < this.recvInfoLst.length ; i++){
            this.recvInfoLst[i].phone = this.$gfnCommonUtils.hpNumberAddDash(this.recvInfoLst[i].phone);
          }
        }
      }else{
        if(this.recvInfoLst.length > 0){
          for(let i = 0 ; i < this.recvInfoLst.length ; i++){
            this.recvInfoLst[i].phone = this.$gfnCommonUtils.hpNumberRemoveDash(this.recvInfoLst[i].phone);
          }
        }
      }
    },
    contsVarNms: {
      handler: function(){
        let headerList = [];
        if(this.requiredCuid) headerList.push('cuid');
        if(this.requiredCuPhone) headerList.push('phone');
        headerList = headerList.concat(this.contsVarNms);
        this.headerList = headerList.filter((item, pos) => headerList.indexOf(item) === pos);
      }
    }
  },
  methods: {
    fnAddCu(){
      ++this.loopCnt;
    },
    //빈값확인
    fnIsEmpty(str){
      if(str) return false;
      else return true
    },
    //빈오브젝트확인
    fnIsEmptyObj(obj){
      if(typeof obj === 'undefined') return true;
      if(Object.keys(obj).length === 0) return true;
      return false;
    },
    fnOnlyNumber($event) {
      // var keyCode = $event.which;
      // if(keyCode != "8" && keyCode != "46") {
      //   if (!/\d/.test($event.key)){
      //     return $event.preventDefault();
      //   }
      // if((event.keyCode >= 48 && event.keyCode <= 57 ) || (event.keyCode >= 96 && event.keyCode <= 105 )){
      //   event.target.value = this.$gfnCommonUtils.hpNumberAddDash(event.target.value);
      // }
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 45 && keyCode !== 8 && (keyCode < 94 || keyCode > 105) && keyCode !== 46 && (keyCode < 37 || keyCode > 40) ) {
 					$event.preventDefault();
 			}
      $event.target.value = this.$gfnCommonUtils.hpNumberAddDash($event.target.value);
    },
    //직접입력 팝업 내 선택 버튼 클릭시
    fnCallbackInputData(){
      const recvInfoLst = this.receivers.filter(receiver => receiver.phone && receiver.name);

      if (recvInfoLst.length === 0) {
        confirm.fnAlert(this.componentsTitle, '수신자 정보를 입력해주세요.');
        return;
      }

        this.$parent.fnCallbackRecvInfoLst(recvInfoLst);

        // 모달 닫기
        this.closeModal();
    },
    addReceiver() {
      if (this.receivers.length < 30) {  // 최대 30개 제한
        this.receivers.push({ phone: '', name: '' });
      }
    },
    closeModal() {
      this.$bvModal.hide('enter-receiver-modal');
    },
    onSubmit() {
      this.$bvModal.show('alert-modal');
    }
  }
};
</script>

<style scoped lang="scss">
.form-row {
  display: flex;
  & > * {
    width: calc(50% - 10px);
    margin-right: 10px;
  }
  label + label {
    margin-right: 0;
    margin-left: 10px;
  }
  .form-control + .form-control {
    margin-right: 0;
    margin-left: 10px;
  }
  &.input-row + .input-row {
    margin-right: 0;
    margin-left: 10px;
  }
}
</style>
