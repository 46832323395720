import msgSendNumber from '../views/bm-msgSendNumber.vue'
import rcsSendNumber from '../views/bm-rcsSendNumber.vue'
//import kmcAuthSuccess from '../views/bm-kmcAuth-success'
import checkPlusSuccess from '../views/bm-checkPlus-success'

export default [
	{
		path: '/ac/sendInfo/msgSendNumber',
		name: 'msgSendNumber',
		meta: { menu: 'AC_SEND_INFO1' },
		component: msgSendNumber,
		props: true
	},
	{
		path: '/ac/sendInfo/rcsSendNumber',
		name: 'rcsSendNumber',
		meta: { menu: 'AC_SEND_INFO2' },
		component: rcsSendNumber,
		props: true
	},
]