import 'babel-polyfill'
import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import router from './router';
import store from './store';
//import { coreUiMixin } from "@/common/vue-mixins";
import common from '@/assets/js/common';
import './filters'
import moment from 'moment'
import vueMomentJs from 'vue-momentjs'
// import './common'
import { BootstrapVue } from 'bootstrap-vue';
import VueTimepicker from 'vue2-timepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
// import '@/assets/styles/global.scss';
import "@/assets/scss/landing/main.scss";
// import './assets/css/console.css'


Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.component('VueTimepicker', VueTimepicker);

Vue.use(VueCookies);
Vue.use(common);
Vue.use(vueMomentJs, moment);
moment.locale('ko')

Vue.$cookies.config("1d");
//Vue.mixin(coreUiMixin);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

