<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M8.75033 16.6882C12.6623 16.6882 15.8337 13.5168 15.8337 9.60482C15.8337 5.69282 12.6623 2.52148 8.75033 2.52148C4.83833 2.52148 1.66699 5.69282 1.66699 9.60482C1.66699 13.5168 4.83833 16.6882 8.75033 16.6882Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M11.1066 6.83176C10.5034 6.22855 9.67008 5.85547 8.74962 5.85547C7.82916 5.85547 6.99583 6.22855 6.39258 6.83176" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.8428 14.6973L17.3783 18.2328" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconMenuSearch',
};
</script>