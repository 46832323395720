<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2.48828" width="20" height="20" rx="4" fill="#4B5563"/>
    <path d="M7.875 12.4883H16.125" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 8.36328V16.6133" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

  <script>
    export default {
      name: 'IconOff',
    };
  </script>