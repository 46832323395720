import SendSmsMain from '../views/bm-sendSmsMain.vue'
import SendAlimTalkMain from '../views/bm-sendAlimTalkMain.vue'
import MultiSendList from '../views/bm-multiSendList.vue'
import SmartSendMain from '../views/bm-smartSendMain.vue'


export default [
  {
    path: '/uc/message/sendSms',
    name: 'sendSms',
    meta: { menu: 'UC_SND_SMS_MSG', title: '발송' },
    component: SendSmsMain,
    props: true
  },
  {
    path: '/uc/message/sendAlimTalk',
    name: 'sendAlimTalk',
    meta: { menu: 'UC_SND_ALIM_MSG', title: '발송' },
    component: SendAlimTalkMain,
    props: true
  },
  {
    path: '/uc/message/multiSendList',
    name: 'multiSendList',
    meta: { menu: 'UC_SND_INTG_MSG', title: '발송' },
    component: MultiSendList,
    props: true
  },
  {
    path: '/uc/message/smartSendMain',
    name: 'multiSendMain',
    meta: { menu: 'UC_SND_INTG_MSG' },
    component: SmartSendMain,
    props: true
  }
]