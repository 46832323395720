<template>
    <div>
        <BcApiKeyManage />
    </div>
</template>

<script>
import BcApiKeyManage from '../component/bc-apiKey.vue';

export default{
    components:{
        BcApiKeyManage
    }
}
</script>