import consoleNotice from '../views/bm-consoleNotice.vue'
import consoleNoticeDetail from '../views/bm-consoleNoticeDetail.vue'
import consoleFAQ from '../views/bm-consoleFAQ.vue'
import consoleQnA from '../views/bm-consoleQnA.vue'

import consoleNoticePopup from '../components/bp-consoleNotice.vue'

export default [
  {
      path: '/ac/consoleNotice',
      name: 'consoleNotice',
      component: consoleNotice,
      meta: { public: true },
	},
  {
      path: '/ac/consoleNotice/:noticeId',
      name: 'consoleNoticeDetail',
      component: consoleNoticeDetail,
      eta: { public: true },
      props: true,
  },
    {
      path: '/ac/consoleFAQ',
      name: 'consoleFAQ',
      component: consoleFAQ,
      meta: { public: true }
    },
    {
      path: '/ac/consoleQnA',
      name: 'consoleQnA',
      component: consoleQnA,
      meta: { public: true }
    },
  {
    path: '/ac/popup/consoleNoticePopup',
    name: 'consoleNoticePopup',
    component: consoleNoticePopup,
    props: true,
    meta: { public: true }
  }
]
