<template>
  <div>
    <BpBookingSendCancel />
  </div>
</template>

<script>
import BpBookingSendCancel from '../components/bp-booking-send-cancel.vue';

export default {
  components: {
    BpBookingSendCancel
  }
}
</script>