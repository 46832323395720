import httpClient from '@/common/http-client';

const signUpRedirect = (params) => {
    return httpClient.post('/api/auth/login', params, { headers: {"show-layer": "Yes"} });
};

const domainChk = (params) => {
    return httpClient.post('/api/public/signUp/domainChk', params, { headers: {"show-layer": "Yes"} });
};

const insertEmailCertify = (params) => {
    return httpClient.post('/api/public/signUp/insertEmailCertify', params, { headers: {"show-layer": "Yes"} });
};

const certifyMailByAuthKey = (params) => {
    return httpClient.post("/api/public/signUp/certifyMailByAuthKey", params, { headers: {"show-layer": "Yes"}});
}

const insertSignUp = (params) => {
    return httpClient.post('/api/public/signUp/insertSignUp', params, { headers: {"show-layer": "Yes"} });
};

const selectUseTerms = (params) => {
    return httpClient.post('/api/public/signUp/selectUseTerms', params, { headers: {"show-layer": "Yes"} });
};

const selectUseTermsPreVersion = (params) => {
    return httpClient.post('/api/public/signUp/selectUseTermsPreVersion', params, { headers: {"show-layer": "Yes"} });
};

const apiTest = () => {
    return httpClient.post('/api/public/signUp/apiTest', { headers: {"show-layer": "Yes"} });
}

const getKmcCheck = () => {
    return httpClient.post('/api/public/signUp/getKmcCheck', { headers: {"show-layer": "Yes"} });
}

const getKmcCheckSuccess = (params) => {
    return httpClient.post('/api/public/signUp/getKmcCheckSuccess', params, { headers: {"show-layer": "Yes"} });
}

const selectCorpCustList = (params) => {
    return httpClient.post('/api/public/signUp/selectCorpCustList', params, { headers: {"show-layer": "Yes"} });
}

const selectCustAddr = (params) => {
    return httpClient.post("/api/public/signUp/selectCustAddr", params, { headers: {"show-layer": "Yes"}});
}

const selectCorpCustTypeList = (params) => {
    return httpClient.post("/api/public/signUp/selectCorpCustTypeList", params, { headers: {"show-layer": "Yes"}});
}

const selectSalesManList = (params) => {
    return httpClient.post("/api/public/signUp/selectSalesManList", params, { headers: {"show-layer": "Yes"}});
}

const chkBank = (params) => {
    return httpClient.post("/api/public/signUp/chkBank", params, { headers: {"show-layer": "Yes"}});
}

const chkCard = (params) => {
    return httpClient.post("/api/public/signUp/chkCard", params, { headers: {"show-layer": "Yes"}});
}

export default {
    signUpRedirect,
    domainChk,
    insertSignUp,
    insertEmailCertify,
    selectUseTerms,
    selectUseTermsPreVersion,
    apiTest,
    getKmcCheck,
    getKmcCheckSuccess,
    selectCorpCustList,
    selectCustAddr,
    selectCorpCustTypeList,
    certifyMailByAuthKey,
    selectSalesManList,
    chkBank,
    chkCard,
};
