<template>
  <div>
    <BcChanKakao />
  </div>
</template>

<script>
import BcChanKakao from '../components/bc-chan-kakao.vue';

export default {
  components: {
    BcChanKakao
  }
}
</script>