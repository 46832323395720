<template>
  <div class="modal fade modalStyle in" id="prdConfirm" tabindex="-1" role="dialog" aria-hidden="true"  @click.self="fnClose">
      <div class="modal-dialog" style="width:400px">
        <div class="modal-content">
          <div class="moda-body pd60">
            <div>
              <h2 class="text-center">운영이관요청</h2>
              <div class="idinputwrap">
                <div class="">
                      <label><input type="radio" name="inquiryType" value="now" v-model="transType"><span>&nbsp;즉시이관</span></label>
                      &nbsp;&nbsp;&nbsp;<label><input type="radio" name="inquiryType" value="rsvd" v-model="transType"><span>&nbsp;예약이관</span></label>
                </div> 
              </div>	
              <div v-if="transType == 'now'">
                <p class="font-size11 text-left color1 mt10">즉시이관 요청을하시면 최대 5분이내 운영이관이 완료됩니다.<br>
                  이관요청을 하시겠습니까?</p>
              </div>
              <div v-if="transType == 'rsvd'">
                <p class="font-size11 text-left color1 mt10">현재 템플릿을 지정한 시간에 수정된 템플릿으로 대체합니다.<br>
                  (승인 일시보다 지정일이 빠른경우 즉시 대체됩니다.)</p>
                <div class="" style="margin-top: 10px;">
                  <div class="float-left" style="width:18%">
                    <Calendar @update-date="fnUpdateRsrvDate" calendarId="rsrvDate" classProps="datepicker" :initDate="rsrvDate"></Calendar>
                  </div>
                  <div class="float-right" style="width:36%">
                    <select class="" style="width:40%; height: 30px;" v-model="rsrvHH">
                    <option value="00">00</option>
                    <option v-for="hh in 23" :key="hh" :value="hh > 9 ? hh : '0'+hh">{{hh > 9 ? hh : '0'+hh}}</option>
                    </select>
                    : <select class="" style="width:40%; height: 30px;" v-model="rsrvMM">
                    <option value="00">00</option>
                    <option v-for="mm in 5" :key="mm" :value="mm+'0'">{{mm+'0'}}</option>
                    </select>
                  </div>
                </div>
            
              </div>
            </div>
            <div class="text-center mt40"  style="margin-top: 20px;">
              <div>
                <p class="font-size11 text-left color1 mt10"><br>설정하신 시간으로 운영이관을 하시겠습니까?</p>
              </div>
              <br>
              <button class="btnStyle3 black font14" data-toggle="modal"  @click.prevent="fnProcTransTmpltProc" data-target="#Register">확인</button>
              <button class="btnStyle3 white font14 ml10" @click.prevent="fnClose"  data-dismiss="modal">취소</button>			
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import confirm from "@/modules/commonUtil/service/confirm.js";
  import {eventBus} from "@/modules/commonUtil/service/eventBus";
  import templateApi from "@/modules/template/service/templateApi.js";
  import Calendar from "@/components/Calendar.vue";
  
  export default {
    name: 'authPhoneNumbPopup',
    components: {
		Calendar
	
  },
    props: {
      rowData1 : {
        type: Object,
        require: true,
      },
      popReset1 : {
        type : Number
      }
    },
    data(){
      return {
        transType : "now",
        rsrvDate : this.$gfnCommonUtils.getCurretDate(),
        rsrvHH : "00",
        rsrvMM : "0000",
        tmpltCode : "",
        startValidDate : ""
      }
    },
    mounted() {
    },
    watch: {

    },
    methods: {
      // 닫기
      fnClose(){
        jQuery("#prdConfirm").modal("hide");
      },
      fnUpdateRsrvDate(sltDate){
        this.rsrvDate = sltDate;
      },
      fnProcTransTmpltProc(){
        eventBus.$on('callbackEventBus', this.fnProcTransTmplt);
        confirm.fnConfirm(this.componentsTitle, "운영이관 요청 하시겠습니까?", "확인");

      },
      fnProcTransTmplt(){
        let params = {
          tmpltCode : this.rowData1.tmpltCode
          ,startValidDate : this.rsrvDate+""+this.rsrvHH+""+this.rsrvMM+"00"
          , transType : this.transType
        };
        templateApi.procTransRequestKkoTmplt(params).then(response =>{
          var result = response.data;
          confirm.fnAlert(this.componentsTitle, result.message);
          if(result.success) {
            this.fnClose();
          }
      });
    },
    }
  }
  </script>