<template>
	<div>
		<SendByMonth />
	</div>
</template>

<script>
import SendByMonth from '../components/bc-statisticsUser-sendByMonth.vue';

export default {
	components: {
		SendByMonth
	}
}
</script>