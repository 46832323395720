<template>
    <div class="modal modalStyle in" id="saleManPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="of_h">
                        <h2>영업사원</h2>
                        <hr>
                            <div class="sales_select">
                                <div class="sales_head">사원명</div>
                                <input type="text" class="sales_input" placeholder="사원명" v-model="srcSalesManNm" @keypress.enter="fnGetSalesMan">
                                <button class="sales_search" @click="fnGetSalesMan">검색</button>
                            </div>

                        <div  class="of_h mt10" style="height:450px; overflow:auto;">
                            <table class="chkCorp_table" id="selCorpTbl">
                                <colgroup>
									<col style="width:30%">
									<col style="width:70%">
								</colgroup>
								<thead>
									<tr>
                                        <th class="chkCorp_th">사원명</th>
                                        <th class="chkCorp_th">전화번호</th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="(row, idx) in data" :key="idx" @click="fnSelectSaleManInfo(row,idx)" :class="index == idx ? 'selected' : ''" @dblclick="fnSelRow(row)">
                                        <td class="chkCorp_th">{{ row.codeName1 }}</td>
                                        <td class="chkCorp_th">{{ row.codeVal4 }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>					
                    </div>
                    <div class="of_h consolMarginTop mt20">
                            <a @click="fnSelect" class="chkCorp_selected_btn" title="선택" data-toggle="modal">선택</a>
                            <a @click="fnCloseLayer" class="chkCorp_btn" title="닫기" data-dismiss="modal">닫기</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import confirm from "@/modules/commonUtil/service/confirm.js";
import signUpApi from "@/modules/signUp/service/api";

export default {
    name : 'selSalesManPopup',
    props : {
        popReset : {
            type : Number,
            require : false
        },
        salesMan : {
            type :String,
            require : false
        },
        selSalesMan : {
            type : Object,
            require : false
        }
    },
    data(){
        return {
            index : -1,
            data : [],
            selRowData : {},
            srcSalesManNm: ""
        }
    },
    watch : {
        popReset(){
            this.index = -1;
            
            this.srcSalesManNm = this.salesMan;
            this.selRowData = {};

            this.fnGetSalesMan();
        }
    },
    methods : {
        fnCloseLayer(){
            jQuery("#saleManPopup").modal("hide");
        },
        fnGetSalesMan(){
            if(this.$gfnCommonUtils.isEmpty(this.srcSalesManNm)){
                confirm.fnAlert("","영업사원의 이름을 입력해주세요.");
                return false;
            }
			var params = {
				codeTypeCd	: "SALES_MAN",
				useYN		: "Y",
				srcSalesManNm : this.srcSalesManNm
			};
			signUpApi.selectSalesManList(params).then(response =>{
                this.data = [];
				var result = response.data;
				if(result.success){
					if(result.data.length > 0){
						this.data = result.data;
					}
				}
			});
        },
        fnSelectSaleManInfo(rowData,idx){
            this.selRowData = rowData;
            this.index = idx;
        },
        fnSelRow(rowData){
            if(rowData.codeVal1 != undefined && rowData.codeVal1 != null && rowData.codeVal1 != ""){
                this.fnGetSelSalesManInfo(rowData);
            } else {
                alert("영업사원을 선택해주세요.");
            }
        },
        fnSelect(){
            var rowData = new Object();

             if(this.selRowData.codeVal1 != undefined && this.selRowData.codeVal1 != null && this.selRowData.codeVal1 != ""){
                rowData = this.selRowData;
                this.fnGetSelSalesManInfo(rowData);
            } else {
                alert("영업사원을 선택해주세요.");
            }
        },
        fnGetSelSalesManInfo(rowData){
            var vm = this;
            vm.$emit("update:selSalesMan", rowData);
            vm.fnCloseLayer();
        }
    }
}
</script>


<style>
.chkCorp_table tr.selected td {
    background-color: rgb(61, 61, 61);
    color: #ffffff;
}

.sales_select {
    display: flex;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    padding-bottom: 30px;
    font-size: 2rem;
}

input.sales_input {
    width: 50%;
    height: 40px;
    border-radius: 5px;
}

.sales_search {
    width: 17%;
    margin-left: 20px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #b3b3b3;
}

.sales_head {
    width: 30%;
    text-align: center;
}

#chkCorpPopup {
    height: 100% !important;
}

.chkCorp_table {
    width: 90%;
    font-size: 1.8rem;
    border: 1px solid;
    margin: 0 auto;
}

thead {
    border-bottom: 1px solid;
}

.chkCorp_th {
    text-align: center;
    border: 1px solid;
    height: 40px !important;
}

a.chkCorp_btn {
    font-size: 1.8rem;
    color: #626262;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #626262;
    padding: 10px 40px 10px 40px;
}

.chkCorp_selected_btn {
    background-color: rgb(61, 61, 61);
    color: #ffffff;
    font-size: 1.8rem;
    border-radius: 5px;
    border: 1px solid #626262;
    padding: 10px 40px 10px 40px;
}

.of_h.consolMarginTop.mt20 {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>