<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M17.5003 4.18945H2.50033C2.04009 4.18945 1.66699 4.56255 1.66699 5.02279V16.6895C1.66699 17.1497 2.04009 17.5228 2.50033 17.5228H17.5003C17.9606 17.5228 18.3337 17.1497 18.3337 16.6895V5.02279C18.3337 4.56255 17.9606 4.18945 17.5003 4.18945Z" stroke="currentColor" stroke-width="1.5"/>
    <path d="M10 7.93945V13.7728" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M13.333 10.8555V13.7721" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
    <path d="M6.66699 10.0215V13.7715" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconGraph',
  };
</script>