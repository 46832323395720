<template>
  <div>
    <BcWebSend />
  </div>
</template>

<script>
import BcWebSend from '../components/bc-web-send.vue';

export default {
  components: {
    BcWebSend
  }
}
</script>