<template>
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <g clip-path="url(#clip0_735_226558)">
    <path d="M12.8669 1.27313C12.7109 1.01746 12.4917 0.806268 12.2304 0.65991C11.9691 0.513551 11.6745 0.436955 11.375 0.437503H2.625C2.16087 0.437503 1.71575 0.621877 1.38756 0.950066C1.05937 1.27825 0.875 1.72337 0.875 2.1875V13.125C0.875 13.241 0.921094 13.3523 1.00314 13.4344C1.08519 13.5164 1.19647 13.5625 1.3125 13.5625H10.0625C10.1785 13.5625 10.2898 13.5164 10.3719 13.4344C10.4539 13.3523 10.5 13.241 10.5 13.125V4.8125H12.6875C12.8035 4.8125 12.9148 4.76641 12.9969 4.68436C13.0789 4.60231 13.125 4.49103 13.125 4.375V2.1875C13.1241 1.86485 13.0349 1.5486 12.8669 1.27313ZM9.625 12.6875H1.75V2.1875C1.75069 1.95565 1.8431 1.73349 2.00705 1.56955C2.17099 1.4056 2.39315 1.3132 2.625 1.3125H9.86125C9.70682 1.57827 9.62532 1.88012 9.625 2.1875V12.6875ZM12.25 3.9375H10.5V2.1875C10.5 1.95544 10.5922 1.73288 10.7563 1.56878C10.9204 1.40469 11.1429 1.3125 11.375 1.3125C11.6071 1.3125 11.8296 1.40469 11.9937 1.56878C12.1578 1.73288 12.25 1.95544 12.25 2.1875V3.9375Z" fill="#374151"/>
    <path d="M3.0625 4.15625H5.25C5.36603 4.15625 5.47731 4.11016 5.55936 4.02811C5.64141 3.94606 5.6875 3.83478 5.6875 3.71875C5.6875 3.60272 5.64141 3.49144 5.55936 3.40939C5.47731 3.32734 5.36603 3.28125 5.25 3.28125H3.0625C2.94647 3.28125 2.83519 3.32734 2.75314 3.40939C2.67109 3.49144 2.625 3.60272 2.625 3.71875C2.625 3.83478 2.67109 3.94606 2.75314 4.02811C2.83519 4.11016 2.94647 4.15625 3.0625 4.15625Z" fill="#374151"/>
    <path d="M8.3125 9.84375H3.0625C2.94647 9.84375 2.83519 9.88984 2.75314 9.97189C2.67109 10.0539 2.625 10.1652 2.625 10.2812C2.625 10.3973 2.67109 10.5086 2.75314 10.5906C2.83519 10.6727 2.94647 10.7188 3.0625 10.7188H8.3125C8.42853 10.7188 8.53981 10.6727 8.62186 10.5906C8.70391 10.5086 8.75 10.3973 8.75 10.2812C8.75 10.1652 8.70391 10.0539 8.62186 9.97189C8.53981 9.88984 8.42853 9.84375 8.3125 9.84375Z" fill="#374151"/>
    <path d="M8.3125 7.65625H3.0625C2.94647 7.65625 2.83519 7.70234 2.75314 7.78439C2.67109 7.86644 2.625 7.97772 2.625 8.09375C2.625 8.20978 2.67109 8.32106 2.75314 8.40311C2.83519 8.48516 2.94647 8.53125 3.0625 8.53125H8.3125C8.42853 8.53125 8.53981 8.48516 8.62186 8.40311C8.70391 8.32106 8.75 8.20978 8.75 8.09375C8.75 7.97772 8.70391 7.86644 8.62186 7.78439C8.53981 7.70234 8.42853 7.65625 8.3125 7.65625Z" fill="#374151"/>
    <path d="M8.3125 5.46875H3.0625C2.94647 5.46875 2.83519 5.51484 2.75314 5.59689C2.67109 5.67894 2.625 5.79022 2.625 5.90625C2.625 6.02228 2.67109 6.13356 2.75314 6.21561C2.83519 6.29766 2.94647 6.34375 3.0625 6.34375H8.3125C8.42853 6.34375 8.53981 6.29766 8.62186 6.21561C8.70391 6.13356 8.75 6.02228 8.75 5.90625C8.75 5.79022 8.70391 5.67894 8.62186 5.59689C8.53981 5.51484 8.42853 5.46875 8.3125 5.46875Z" fill="#374151"/>
  </g>
  <defs>
    <clipPath id="clip0_735_226558">
      <rect width="14" height="14" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
  export default {
    name: 'IconDocs',
  };
</script>