<template>
	<div>
		<article>
			<div class="clear" style="margin-top: 30px;">
				<h4 class="inline-block">RCS 발신번호 조회</h4>	
			</div>

			<div class="row consolMarginTop">
				<div class="col-xs-12">
					<div class="menuBox">						
						<p class="colo4"><i class="far fa-info-circle vertical-baseline"></i> RCS 발신번호명은 RCS Biz Center 에서 수정이 가능합니다.</p>
					</div>
				</div>
			</div>

			<div class="row mt15" >
				<div class="col-xs-12">
					<div class="menuBox">						
						<div class="of_h">
							<div>
								<h4 class="inline-block" style="width:12%">RCS 발신번호</h4>
								<input type="text" class="inputStyle" style="width:16%" v-model="srcCallNum" @keyup.enter="fnSearch(1)" placeholder="숫자만 입력">
								<h4 class="inline-block ml60" style="width:12%">RCS 발신번호명</h4>
								<input type="text" class="inputStyle" style="width:16%" v-model="srcCallNumNm" @keyup.enter="fnSearch(1)">
							</div>
							<div style="margin-top: 10px;">
								<h4 class="inline-block" style="width:12%">RCS 상태</h4>
								<select class="selectStyle2" style="width:16%" v-model="srcRcsState" @change="fnSearch(1)">
									<option value="">전체</option>
									<option value="승인대기">승인대기</option>
									<option value="검수시작">검수시작</option>
									<option value="검수완료">검수완료</option>
									<option value="승인">승인</option>
									<option value="반려">반려</option>
								</select>
								<a @click="fnSearch(1)" class="btnStyle1 float-right">검색</a>
							</div>		
						</div>						
					</div>
				</div>
			</div>
			<div class="float-right" style="margin-top: 10px;">
				<a @click="fnExcelDownLoad" class="btnStyle2 borderGray ml10" title="엑셀 다운로드" activity="READ">엑셀 다운로드 <i class="fal fa-arrow-to-bottom"></i></a>
			</div>
			<div class="row mt20">
				<div class="col-xs-12">		
					<div class="of_h">
						<!-- 15개씩 보기 -->
						<div class="of_h inline">
							<div class="float-left">전체 : <span class="color1"><strong>{{totCnt | formatComma}}</strong></span>건
								<SelectLayer @fnSelected="fnSelected" classProps="selectStyle2 width120 ml20"></SelectLayer>
							</div>
						</div>
						<!-- //15개씩 보기 -->
					</div>
				</div>			
			</div>
			
			<div class="row">
				<div class="col-xs-12">
					<!-- table -->
					<table class="table_skin1 bt-000 tbl-striped mt10">
						<colgroup>
							<col style="width:18%">
							<col style="width:18%">
							<col>
							<col>
							<col style="width:12%">
							<col style="width:12%">
							<col style="width:12%">
						</colgroup>
						<thead>
							<tr>
							<th class="text-center lc-1">RCS발신번호</th>
							<th class="text-center lc-1">RCS발신번호명</th>
							<th class="text-center lc-1">처리 상태</th>
							<th class="text-center lc-1">브랜드</th>
							<th class="text-center lc-1">요청일자</th>
							<th class="text-center lc-1">승인일자</th>
							<th class="text-center lc-1 end">관리</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(row, index) in data" :key="index">
								<td class="text-center">{{row.callNum | hpNumberAddDash}}</td>
								<td class="text-center">{{row.callNumNm}}</td>
								<td v-if="!(row.rcsState == '반려(수정)' || row.rcsState == '반려')" class="text-center">{{row.rcsState}}</td>
								<td v-if="row.rcsState == '반려(수정)' || row.rcsState == '반려'" class="text-center"><a @click="fnCallNumReasonSet(index)" data-toggle="modal" data-target="#reject"  class="color1" style="text-decoration:underline">{{row.rcsState}}</a></td>
								<td class="text-center">{{row.brandNm}}</td>
								<td class="text-center">{{row.regDt}}</td>
								<td class="text-center">{{row.approvalDt}}</td>
								<td v-if="!(row.rcsState == '승인' || row.rcsState == '반려(수정)')" class="end"><a @click="fnClearConfirm(index)" class="btnStyle1 borderLightGray small">삭제</a></td>
								<td v-if="(row.rcsState == '승인' || row.rcsState == '반려(수정)')" class="end"><a @click="fnCallNumSet(index)" data-toggle="modal" data-target="#callNumModify" class="btnStyle1 borderLightGray small">수정</a></td>
								<!-- <a v-if="row.approvalStatus === '승인' || row.approvalStatus === '반려(수정)'" @click="fnCallbackDetail(row)" class="btnStyle8 mr5">수정</a>
								<a v-if="row.approvalStatus === '승인' || row.approvalStatus === '반려(수정)'" @click="fnCallbackDeleteConfirm(row)" class="btnStyle8 mr5">삭제</a> -->
							</tr>
							<tr v-if="data.length == 0"> 
								<td class="text-center" colspan="10">검색된 내용이 없습니다.</td>
							</tr>
						</tbody>
					</table>
					<!-- //table -->
				</div>			
			</div>

			<!-- pagination -->
			<div id="pageContent">
				<PageLayer @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></PageLayer>
			</div>
			<!-- //pagination -->


			<!-- //본문 -->
		</article>
		<!-- reject Modal -->
		<div class="modal modalStyle" id="reject" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" style="width:650px">
				<div class="modal-content">
					<div class="modal-body">					
						<h2>반려</h2>
						<hr>
						<p class="text-center mt20">{{setHandleReason}}</p>		
						<div class="text-center mt20">
							<a class="btnStyle1 backWhite" data-dismiss="modal" title="취소">닫기</a>						
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- callNumModify Modal -->
		<div class="modal modalStyle" id="callNumModify" tabindex="-1" role="dialog" aria-hidden="true">
			<div class="modal-dialog" style="width:650px">
				<div class="modal-content">
				<div class="modal-body">
					<div class="of_h">
					<h2>발신번호 상세</h2>
					<hr>
					<div class="row">
						<div class="col-xs-12 consoleCon">
						<div class="of_h">
							<h5 class="inline-block" style="width:22%">발신번호 *</h5>
							<div class="float-right" style="width:76%">
							<input type="text" class="inputStyle" style="width:55%" v-model="setCallNum" readonly>
							<p class="color3 inline-block ml10">휴대폰번호 등록 불가</p>
							</div>
						</div>
						<div class="of_h consolMarginTop">
							<h5 class="inline-block" style="width:22%">발신 번호명 *</h5>
							<input type="text" class="inputStyle float-right" style="width:76%" v-model="setCallNumNm">
						</div>
						<div class="of_h consolMarginTop">
							<h5 class="inline-block vertical-middle" style="width:22%">통신서비스<br>가입증명원 *</h5>
							<div class="float-right" style="width:76%">
							<input type="file" class="btnStyle7 minWidthAuto float float-right" style="width:100%" ref="certiImgFile"/>
							</div>
						</div>
						</div>
					</div>
					</div>						
					<div class="text-center mt20">
					<a @click="fnApproval" class="btnStyle1 backBlack" activity="SAVE">수정요청</a> &nbsp;
					<a class="btnStyle1 backWhite" data-dismiss="modal" title="취소">닫기</a>						
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
import sendInfoApi from '../service/sendInfoApi'
import SelectLayer from '@/components/SelectLayer.vue';
import PageLayer from '@/components/PageLayer.vue';
import {eventBus} from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";
import tokenSvc from '@/common/token-service';
import axios from 'axios'
export default {
	components: {
		PageLayer,
		SelectLayer,
	},
	watch: {
		// setCallNumNm () {
		// 	this.setCallNumNm = '';
		// }
	},
	data() {
		return {
			// 검색 조건
			srcCallNum	: "",
			srcCallNumNm : "",
			srcRcsState	: "",
			srcSmsState	: "",
			brandId	: "",
			chatbotId	: "",
			// 리스트 
			data : {},
			pageInfo: {},
			row_data : {},
			listSize : 10,  // select 박스 value (출력 갯수 이벤트)
			pageNo : 1,  // 현재 페이징 위치
			totCnt : 0,  //전체 리스트 수
			offset : 0, //페이지 시작점
			idx : 0,
			//팝업
			setCallNum : '',
			setCallNumNm : '',
			setHandleReason : '',
			certiImgFile : '',
			mainMdn   : "",
			mainTitle : "",
		}
	},
	mounted() {
		this.fnSearch(1);
	},
	methods: {
		// select 박스 선택시 리스트 재출력
		fnSelected(listSize) {
			this.listSize = Number(listSize);
			this.$refs.updatePaging.fnAllDecrease();
		},
		// 검색
		fnSearch(pageNo) {
			var params = {
				"srcCallNum"	: this.srcCallNum,
				"srcCallNumNm"	: this.srcCallNumNm,
				"srcRcsState"	: this.srcRcsState,
				"srcSmsState"	: this.srcSmsState,
				"pageNo"		: (this.$gfnCommonUtils.defaultIfEmpty(pageNo, '1'))*1,
				"listSize"		: this.listSize
			}
			
			sendInfoApi.getRcsCallNumList(params).then(response =>{
				var result = response.data;
				if(result.success) {
					this.data = result.data; 
					this.totCnt = result.pageInfo.totCnt;
					this.offset = result.pageInfo.offset;
				}
			});
		},
		fnClear() {
			var params = {
				'callNum'  : this.data[this.idx].callNum,
				'callNumNm'  : this.data[this.idx].callNumNm,
				'brandId'  : this.data[this.idx].brandId,
				'chatbotId'  : this.data[this.idx].chatbotId,
			};

			sendInfoApi.delCallNum(params).then(response =>{
				var result = response.data;
				if( result.success ){
					confirm.fnAlert("", "삭제 처리되었습니다.");
					this.fnSearch(1);
				} else {
					confirm.fnAlert("", response.data.message);
				}
			});
		},
		// 닫기
		fnCloseLayer: function() {
			jQuery("#detailPop").modal("hide");
		},
		fnCallNumSet(idx) {
			this.idx = idx;
			this.setCallNumNm = this.data[this.idx].callNumNm;
			this.setCallNum = this.data[this.idx].callNum;
			this.brandId = this.data[this.idx].brandId;
			this.chatbotId = this.data[this.idx].chatbotId;
		},
		async fnApproval(){
		// 벨리데이션 처리
		/*  if( !this.fnValidate() ){
			return;
		} */
		var fd = new FormData();
		fd.append('sts'			    , 'U');
		fd.append('saveCorpId'		, tokenSvc.getToken().principal.corpId);
		fd.append('brandId'			, this.brandId);
		fd.append('chatbotId'		, this.chatbotId);
		fd.append('mainMdn'			, this.mainMdn);
		fd.append('mainTitle'		, this.mainTitle);

		// 첨부파일 정리
		fd.append('certiFile'		, this.$refs.certiImgFile.files[0]);

		// 챗봇(발신번호) 정리
		var list = [];
		var obj = JSON.stringify({
			"mdn"       : this.chatbotId,
			"rcsReply"  : this.row_data.rcsReply,
			"subTitle"  : this.setCallNumNm,
			"service"   : "a2p",
			"display"   : "01"
		});
		list.push(obj);
		fd.append('chatbots'		, list) ;

		await axios.post('/sendInfoApi/manage/saveRcsChatbotReqForApi',
			fd, {headers: {'Content-Type': 'multipart/form-data'}
		}).then( response => {
			var result = response.data;
			if(result.success) {
			confirm.fnAlert("", "수정요청 완료했습니다.");
			jQuery("#callNumModify").modal("hide");
			} else {
			confirm.fnAlert("", result.message);
			}
		}).catch(function () {
			confirm.fnAlert("", "저장에 실패했습니다.");
		});
		},
		fnClearConfirm(row_data){
			this.row_data = row_data;
			eventBus.$on('callbackEventBus', this.fnCallbackDelete);
			confirm.fnConfirm("", "삭제처리된 발신번호는 복구할 수 없습니다. 계속 진행하시겠습니까?", "확인");
		},
		fnCallbackDelete(){
			var params = {
				"brandId"		: this.row_data.brandId,
				"chatbotId"		: this.row_data.callNum,
				"saveCorpId"	: this.row_data.corpId,
			};

			sendInfoApi.deleteCallbackForApi(params).then(response =>{
				var result = response.data;
				if( result.success ){
					confirm.fnAlert("", "삭제처리되었습니다.");
				} else {
					confirm.fnAlert("", response.data.message);
				}
			});
		},
		//RCS 발신번호 엑셀 다운로드
		fnExcelDownLoad(){
			alert(11);
			const params = {
				"srcCallNum"	: this.srcCallNum,
				"srcCallNumNm"	: this.srcCallNumNm,
				"srcRcsState"	: this.srcRcsState,
				"srcSmsState"	: this.srcSmsState,
			}
			sendInfoApi.excelDownloadRcsCallnumList(params);
   		},
	}

}
</script>