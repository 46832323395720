import loginMain from '../views/bm-loginMain.vue'
import findUserId from '../views/bm-findUserId.vue'
import findUserPwd from '../views/bm-findUserPwd.vue'
import chkCertifyFail from '../views/bm-chkCertifyFail'
import setUserPwd from '../views/bm-setUserPwd'
import resetUserPwd from '../views/bm-resetUserPwd'

export default [
	{
		path: '/login',
		name: 'login',
		component: loginMain,
		meta: { public: true }
	},
	{
		path: '/login/findUserId',
		name: 'findUserId',
		component: findUserId,
		meta: { public: true }
	},
	{
		path: '/login/findUserPwd',
		name: 'findUserPwd',
		component: findUserPwd,
		meta: { public: true }
	},
	{
		path: '/login/chkCertifyFail',
		name: 'chkCertifyFail',
		component: chkCertifyFail,
		meta: { public: true }
	},
	{
		path: '/login/setUserPwd',
		name: 'setUserPwd',
		component: setUserPwd,
		meta: { public: true }
	},
	{
		path: '/login/resetUserPwd',
		name: 'resetUserPwd',
		component: resetUserPwd,
		meta: { public: true }
	}
	
]