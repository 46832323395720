<template>
  <div>
    <!-- Your template content here -->
  </div>
</template>

<script>
import signUpApi from "@/modules/sendInfo/service/sendInfoApi";
import { eventBus } from "@/modules/commonUtil/service/eventBus";
import confirm from "@/modules/commonUtil/service/confirm";

export default {
  data() {
    return {
      apiToken: this.$route.query.apiToken,
      certNum: this.$route.query.certNum,
      sMobileNo: '',
      sGender: '',
      sConnInfo: '',
      sName: ''
    };
  },
  created() {
    // Get으로 받았을 경우
    console.log('apiToken:', this.$route.query.apiToken);
    console.log('certNum:', this.$route.query.certNum);
  },
  mounted() {
    this.fnGetNiceCheckSuccess();
  },
  methods: {
    fnGetNiceCheckSuccess() {
      const params = {
        apiToken: this.apiToken,
        certNum: this.certNum
      };
      signUpApi.getKmcCheckSuccess(params)
        .then(response => {
          const result = response.data;
          if (result.success) {
            this.sName = result.data.sName;
            this.sConnInfo = result.data.sConnInfo;
            this.sGender = result.data.sGender;
            this.sMobileNo = result.data.sMobileNo;

            const openerDoc = window.opener.document;
            openerDoc.getElementById("phoneCerti").value = this.sMobileNo;
            openerDoc.getElementById("gender").value = this.sGender;
            // openerDoc.getElementById("coInfo").value = this.sConnInfo;
            openerDoc.getElementById("userNm").value = this.sName;
            openerDoc.getElementById('niceAuth').click();
            window.close();
            confirm.fnAlert("", "휴대폰 인증 되었습니다.");

          } else {
            eventBus.$on('callbackEventBus', this.fnClosePopup);
            confirm.fnAlert('본인인증 실패', result.message + '\n다시 시도하여주세요.', "CALLBACK", null);
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    },
    fnClosePopup() {
      window.close();
    }
  }
};
</script>