<template>
    <section class="publicAllSection">
            <div class="publicAllwrap">
                <div class="publicAllcontent">
                <div class="idsection">
                    <div class="idHead">e-UMS 로그인</div>
                    <div class="idinputwrap">
                            <label><input type="email" class="idpw_input" id="emain_input" placeholder="이메일 주소를 입력하세요."
                                name="userId" v-model="userId" :maxlength="100" v-focus @keyup.enter="ajaxlogin">
                            <img src="../../../assets/images/image/user.png" class="input_img"></label>
                            <label><input type="password" class="idpw_input" placeholder="비밀번호를 입력하세요."
                                ame="loginPwd" v-model="loginPwd" :maxlength="30" @keyup.enter="ajaxlogin"> 
                                <img src="../../../assets/images/image/password.png" class="input_img"></label>
                <!-- 에러메세지 출력 -->
                                <div class="message_area" v-if="errors.length">
                                    <p class="text_error">{{ errors[0] }}</p>
                                </div>
                            <div class="maintainLogin"><label><input type="checkbox" id="chkID"  value="아이디 저장" ref="chkSaveId"><span>아이디 저장</span></label></div>

                    </div>
					<div class="idinputwrap2">
                                <div class="loginhr_section">
                                    <div class="loginhr"></div>
                                     <p class="secondlogin">2차 인증</p>
                                     <div class="loginhr"></div>
                                </div>
                            <div class="rowContent2">
                                  <label><input type="radio" name="inquiryType" value="sms" v-model="authType"><span>문자 인증</span></label>
                                  <label><input type="radio" name="inquiryType" value="email" v-model="authType"><span>이메일 인증</span></label>
                            </div> 
                    </div>
                    <button type="submit" class="submitButton" data-toggle="modal" data-target="#smsAcc" @click.prevent="ajaxlogin">로그인</button>
                    <ul class="idfindul">
                        <router-link :to="'/login/findUserPwd'"><li>비밀번호 찾기</li></router-link>
                        <li>&nbsp;│&nbsp;</li>
                        <router-link :to="'/login/findUserId'"><li>아이디 찾기</li> </router-link>           
                        <li>&nbsp;│&nbsp;</li>
                        <router-link :to="'/sign/signUp'"><li>회원가입</li></router-link>
                    </ul>
                </div>
            </div>
        </div>
		<authPhoneNumbPopup :popReset="popReset" :rowData.sync="rowData"></authPhoneNumbPopup>
    </section>
</template>

<script>
import loginApi from '@/modules/login/service/api';
import confirm from '@/modules/commonUtil/service/confirm';
//import * as utils from '@/common/utils';
import authPhoneNumbPopup from '@/modules/login/components/bp-authChkPhoneNumb';
export default {
	components: {
		authPhoneNumbPopup
	},
	data: function() {
		return {
			errors: [],
			userId: '',
			loginPwd: null,
			status: '',
			findUserIdUrl: '/login/findUserId',
			findUserPwdUrl: '/login/findUserPwd',
			popReset : 0,
			rowData : {},
			email: "testadmin",
			authType: 'sms'
		};
	},
	created() {
		// 로그인 페이지 진입시
		this.$store.commit("login/isLogin", false);
		this.$store.commit("login/isErrorPage", false);
	},
	mounted() {
		this.$refs.chkSaveId.checked = true;

		if (localStorage.wtmpltUserId) {  
			this.userId = localStorage.wtmpltUserId;
		}

	},
	destroyed() {
	},
	methods: {
		chgChkUserId() {
			if (this.$refs.chkSaveId.checked == true) {
				localStorage.wtmpltUserId = this.userId;
			} else {
				delete localStorage.wtmpltUserId;
			}
		},
		toRegister(){
			this.$router.push({ name: 'register'});
		},
		formCheck: function() {
			this.errors = [];

			if (!this.userId) this.errors.push('아이디를 입력해 주세요.');
			if (!this.loginPwd) this.errors.push('비밀번호를 입력해 주세요.');

			return this.errors.length == 0;
		},
		fnSendMail: function() {
			var vm = this;
			var params = {
				email: this.email,
				location: "/login"
			};

			return new Promise(function(resolve, reject) {
				loginApi.sendCertifyMail(params).then(response => {
					var result = response.data;
					if (!result.success) {
						alert(result.message);
						reject(result.message); // 실패한 경우에 reject 호출
					} else {
						if (vm.authType == 'sms') {
							alert("문자 인증 성공하였습니다.");
						} else {
							alert("이메일 인증 성공하였습니다.");
						}
						resolve(); // 성공한 경우에 resolve 호출
					}
				}).catch(error => {
					reject(error); // 에러가 발생한 경우에 reject 호출
				});
			});
		},

		fnSetPhoneCertify(){
			var vm = this;
			if (!vm.formCheck()) return false;
			var params = {
				"userId": this.userId,
				"userPwd": this.loginPwd
			};
			if (vm.authType == 'sms') {

				loginApi.setPhoneCertify(params).then(function(response) {
					var result = response.data;
					
					if(result.success){
						vm.rowData  = {
							...result.data,
							authType:	vm.authType,
							email:   vm.userId
						}
				
						// 인증번호 요청 팝업
						vm.popReset++;
						jQuery("#smsConfirm").modal("show");

					} else {
						var msg = null;
						switch (result.code) {
							case 'CE_ID_PWD': // ID/PWD 불일치
								confirm.fnAlert("", "로그인 실패하였습니다. (5회 실패 시 계정 잠김)");
								break;
							case 'SS_NOT_AUTH': // 미인증 계정
								msg = 'Email 인증 후 이용할 수 있습니다.';
								break;
							case 'SS_NOT_USE': // 휴면 계정
								msg = '최종 접속일 기준 30일이 초과되어 휴면 처리 된 계정입니다.';
								break;
							case 'SS_LOCK': // 잠김 계정
								msg = '관리자 승인 후 이용할 수 있습니다.';
								break;
							case 'SS_DEL_USE': // 삭제 계정
								msg = '삭제 된 계정입니다.';
								break;
							case 'CE_TO_LOCK': // ID/PWD 불일치 횟수초과로 계정 잠김
								msg = '5회 이상 로그인 실패하여 해당 아이디에 대한 계정이 잠금처리되었습니다.\n관리자에게 문의하세요.';
								break;
							case 'SS_INVLD_ROLE': // 권한이 없는 사용자ID
								msg = 'Back Office 권한이 없는 사용자ID입니다.';
								break;
							case 'SE_INTERNAL': // 최근 접속일시 업데이트 실패
								msg = '데이터베이스와 통신에 실패하였습니다.';
								break;
							case 'CE_WRONG_AUTHNUM': // 인증번호요청
									msg = '인증번호가 잘못 되었습니다.';
									confirm.fnAlert('', msg);
									break;
							default:
								msg = result.message;
						}
						if (msg != null) {
							confirm.fnAlert("", msg);
						}
					}
				});
			} else {
				loginApi.setMailCertify(params).then(function(response) {
					var result = response.data;
					
					if(result.success){
						vm.rowData  = {
							...result.data,
							authType:	vm.authType,
							email:   vm.userId
						}

						// 인증번호 요청 팝업
						vm.popReset++;
						jQuery("#smsConfirm").modal("show");

					} else {
						var msg = null;
						switch (result.code) {
							case 'CE_ID_PWD': // ID/PWD 불일치
								confirm.fnAlert("", "로그인 실패하였습니다. (5회 실패 시 계정 잠김)");
								break;
							case 'SS_NOT_AUTH': // 미인증 계정
								msg = 'Email 인증 후 이용할 수 있습니다.';
								break;
							case 'SS_NOT_USE': // 휴면 계정
								msg = '최종 접속일 기준 30일이 초과되어 휴면 처리 된 계정입니다.';
								break;
							case 'SS_LOCK': // 잠김 계정
								msg = '관리자 승인 후 이용할 수 있습니다.';
								break;
							case 'SS_DEL_USE': // 삭제 계정
								msg = '삭제 된 계정입니다.';
								break;
							case 'CE_TO_LOCK': // ID/PWD 불일치 횟수초과로 계정 잠김
								msg = '5회 이상 로그인 실패하여 해당 아이디에 대한 계정이 잠금처리되었습니다.\n관리자에게 문의하세요.';
								break;
							case 'SS_INVLD_ROLE': // 권한이 없는 사용자ID
								msg = 'Back Office 권한이 없는 사용자ID입니다.';
								break;
							case 'SE_INTERNAL': // 최근 접속일시 업데이트 실패
								msg = '데이터베이스와 통신에 실패하였습니다.';
								break;
							case 'CE_WRONG_AUTHNUM': // 인증번호요청
									msg = '인증번호가 잘못 되었습니다.';
									confirm.fnAlert('', msg);
									break;
							default:
								msg = result.message;
						}
						if (msg != null) {
							confirm.fnAlert("", msg);
						}
					}
				});
			}
		},
		ajaxlogin: function() {
			var vm = this;

			vm.$refs.chkSaveId.focus();
			vm.errmsg = null;

			if (!vm.formCheck()) return false;
				var params = {
					"userId": vm.userId,
					"userPwd": vm.loginPwd,
				};
				// var params = vm.rowData;  --  추후 변경 임시 주석함 로그인시 호출 함수도fnSetPhoneCertify  로 변경해야함

				loginApi.login(params).then(function(response) {
					var rsp = response.data;
						console.log('rsp:', rsp);
						if (rsp.success) {
							vm.chgChkUserId();
							vm.$store.commit("login/isLogin", true);
							vm.$router.push({ path: rsp.data });
						} else {
							vm.$store.commit("login/isLogin", false);
							var msg = null,
							next = null;
							switch (rsp.code) {
								case 'CE_ID_PWD': // ID/PWD 불일치
									vm.errors.push('로그인 실패하였습니다. (5회 실패 시 계정 잠김)');
									msg = '로그인 실패하였습니다.';
									break;
								case 'SS_NOT_AUTH': // 미인증 계정
									msg = 'Email 인증 후 이용할 수 있습니다.';
									break;
								case 'SS_NOT_USE': // 중지 계정
									msg = '관리자 승인 후 이용할 수 있습니다.';
									break;
								case 'SS_LOCK': // 잠김 계정
									msg = '관리자 승인 후 이용할 수 있습니다.';
									break;
								case 'SS_DEL_USE': // 삭제 계정
									msg = '삭제 된 계정입니다.';
									break;
								case 'SS_PWD_EXPIRE': // 비밀번호 만료
									msg = '비밀번호를 변경하신지 90일이 지났습니다.\n비밀번호 변경 화면으로 이동합니다.';
									next = '/login?expire';
									break;
								case 'SS_INIT_PWD': // 최초 비밀번호 변경 필요
									msg = '새로운 비밀번호로 변경이 필요합니다.\n비밀번호 변경 화면으로 이동합니다.';
									next = function() {
										// vue-router를 사용하여 데이터 전달
										vm.$router.push({ name: 'changepwd', params: { userId: vm.userId } });
									};
									break;
								case 'CE_TO_LOCK': // ID/PWD 불일치 횟수초과로 계정 잠김
									msg = '5회 이상 로그인 실패하여 해당 아이디에 대한 계정이 잠금처리되었습니다.\n관리자에게 문의하세요.';
									break;
								case 'SE_INTERNAL': // 최근 접속일시 업데이트 실패
									msg = '데이터베이스와 통신에 실패하였습니다.';
									break;
								case 'SS_NOT_PROJECT': // 참여 프로젝트 없음
									msg = '참여되어 있는 프로젝트가 존재하지 않습니다.';
									break;
								case 'SS_NOT_USE_CORP': // 사용정지된 고객사
									msg = '사용정지 된 고객사의 사용자입니다.';
									break;
								case 'SS_NOT_EXIST_CORP': // 삭제된 고객사
									msg = '삭제 된 고객사의 사용자입니다.';
									break;
								case 'CE_WRONG_AUTHNUM': // 인증번호요청
									msg = '인증번호가 잘못 되었습니다.';
									confirm.fnAlert('', msg);
									break;
								default:
									vm.errmsg = '인증에 실패했습니다.';
							}

							if (msg != null) {
								confirm.fnAlert('', msg);
								if (next != null) {
									next();
								}
							}
						}
				});
		},
		signUp: function() {
			this.$router.push({name : "signUp"});
		}
	}
};
</script>
