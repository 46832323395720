<template>
    <article>
        <div class="menuBox">
            <div class="of_h">
                <div class="inline-block" style="width:10%; margin-right: 10px;">
                    <h4>발송일</h4>
                </div>
                <Calendar @update-date="fnUpdateStartDate2" calendarId="searchStartDate"
                    classProps="datepicker inputStyle maxWidth200" :initDate="searchData.searchStartDate"
                    @change="fnSearch(1)">
                </Calendar>
                <ul class="tab_s2 ml20" style="display: inline-block; margin-left: 20px;">
                    <li :class="searchDateInterval == 0 ? 'active' : ''">
                        <a @click="fnSetIntervalSearchDate(0);" title="오늘 날짜 등록일자 검색">오늘</a>
                    </li>
                </ul>
                <div class="inline-block" style="width: 8%; vertical-align: middle; margin-left: 160px;">
                    <h4>수신자번호</h4>
                </div>
                <div class="inline-block" style="width: 30%; margin-left: 10px;">
                    <input type="text" class="inputStyle vertical-top" v-model="callback">
                </div>
                <a @click="fnSearch()" class="btnStyle2 float-right" title="검색" activity="READ" style="margin-left: 20px;">검색</a>
            </div>   
        </div>

        <div class="mt10">
            <div class="of_h inline">
                <div class="float-left">전체 : <span class="color1"><strong>{{ totCnt | formatComma }}</strong></span>건
                    <SelectLayer @fnSelected="fnSelected" classProps="selectStyle2 width120 ml20"></SelectLayer>
                </div>
                <div class="float-right">
                    <a @click="fnExcelDownLoad" class="btnStyle2 borderGray" title="엑셀 다운로드" activity="READ">엑셀 다운로드 <i
                            class="fal fa-arrow-to-bottom"></i></a>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-xs-12">
                <table class="table_skin1_1 bt-000 tbl-striped consolMarginTop tableFixed"
                    style="overflow-wrap: break-word;">
                    <colgroup>
                        <col width="13%">
                        <col width="12%">
                        <col width="10%">
                        <col width="8%">
                        <col width="8%">
                        <col width="8%">
                        <col>
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="text-center lc-1">일시</th>
                            <th class="text-center lc-1">수신자번호</th>
                            <th class="text-center lc-1">식별값(cliKey)</th>
                            <th class="text-center lc-1">발송구분</th>
                            <th class="text-center lc-1">요청 채널</th>
                            <th class="text-center lc-1">최종 채널</th>
                            <th class="text-center lc-1 end">실패사유</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in failList" :key="item.cliKey">
                            <td class="text-center">{{ item.ymd }}</td>
                            <td class="text-center">{{ item.callback }}</td>
                            <td class="text-center">{{ item.cliKey }}</td>
                            <td class="text-center">{{ item.senderType }}</td>
                            <td class="text-center">{{ item.reqCh }}</td>
                            <td class="text-center">{{ item.finalCh }}</td>
                            <td class="text-center">{{ item.gwResultDesc }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div id="pageContent">
            <PageLayer @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo"
                ref="updatePaging"></PageLayer>
        </div>
    </article>
</template>

<script>
import Calendar from "@/components/Calendar.vue";
import SelectLayer from "@/components/SelectLayer.vue";
import PageLayer from "@/components/PageLayer.vue";
import confirm from "@/modules/commonUtil/service/confirm";
import tokenSvc from '@/common/token-service';
import useApi from '../service/useApi';

export default {
    components: {
        Calendar,
        SelectLayer,
        PageLayer
    },
    props: {
        searchData: {
            type: Object,
            require: false,
            default: function () {
                return {
                    'searchText': '',
                    'searchStartDate': this.$gfnCommonUtils.getCurretDate(),
                    'searchEndDate': this.$gfnCommonUtils.getCurretDate()
                }
            }
        }
    },
    data() {
        return {
            failList: [],
            searchDateInterval: 0,
            callback: '',
            totCnt: 0,
            offset: 0, //페이지 시작점
            listSize: 10,
            pageNo: 1
        }
    },
    mounted() {
        this.fnSetIntervalSearchDate(this.searchDateInterval);
        this.fnSearch(); // 컴포넌트 마운트 시 초기 검색
    },
    methods: {
        fnUpdateStartDate(date) {
            this.searchData.searchStartDate = date;
        },
        fnSetIntervalSearchDate(interval) {
            this.searchDateInterval = interval;
            this.searchData.searchEndDate = this.$gfnCommonUtils.getCurretDate();
            this.searchData.searchStartDate = this.$gfnCommonUtils.strDateAddDay(this.searchData.searchEndDate, -this.searchDateInterval);
            this.fnSearch(1);
        },
        fnUpdateStartDate(sltDate) {
            this.searchData.searchStartDate = sltDate;
        },
        fnUpdateEndDate(sltDate) {
            this.searchData.searchEndDate = sltDate;
        },
        fnUpdateStartDate2(sltDate) {
            this.searchData.searchStartDate = sltDate;
            this.fnSearch(1);
        },
        async fnSearch(pageNum) {
            this.pageNo = (this.$gfnCommonUtils.defaultIfEmpty(pageNum, '1')) * 1;
            this.fnSelectFailList();
        },
        fnSelected(listSize) {
            this.listSize = listSize;
            this.fnSearch(); // 페이지 크기 변경 시 검색
        },
        fnExcelDownLoad() {
            var params = this.searchData;
            useApi.excelDownloadFailList(params);
        },
        async fnSelectFailList() {
            if (this.searchData.searchStartDate && this.searchData.searchEndDate) {
                if (this.searchData.searchStartDate.replace(/[^0-9]/g, '') > this.searchData.searchEndDate.replace(/[^0-9]/g, '')) {
                    alert('시작일은 종료일보다 클 수 없습니다.');
                    return false;
                }
            }
            var params = Object.assign({}, this.searchData);
            params.pageNo = this.pageNo;
            params.listSize = this.listSize;

            params.corpId = tokenSvc.getToken().principal.corpId;
            params.YMD = this.searchData.searchStartDate;
            params.callback = this.callback;

            await useApi.selectFailList(params).then(response => {
                var result = response.data;
                if (result.success) {
                    this.totCnt = result.pageInfo.totCnt;
                    this.offset = result.pageInfo.offset;
                    this.failList = result.data;
                } else {
                    alert(result.message);
                }
            });
        }
    }
}
</script>
