<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.58736 16.2664C2.21762 14.7629 1.38433 12.7781 1.38433 10.6017C1.38433 5.90827 5.25787 2.10352 10.0361 2.10352C14.8143 2.10352 18.6879 5.90827 18.6879 10.6017C18.6879 13.9204 16.7506 16.7935 13.9268 18.1925C12.1992 19.0932 10.2282 19.6035 8.13595 19.6035C5.57233 19.6035 3.19078 18.8374 1.21582 17.5256C1.21582 17.5256 2.54023 17.3812 3.58702 16.2667L3.58736 16.2664ZM14.1001 14.8076C16.3384 12.5694 16.3384 8.9404 14.1001 6.70207C13.0622 5.66423 11.7252 5.10794 10.3666 5.03265V5.03232C10.3256 4.01296 10.8743 3.32455 10.877 3.32119L10.8767 3.32125L10.8771 3.32077C9.31558 3.62242 7.8245 4.37785 6.61537 5.58698C5.68808 6.51423 5.02783 7.6074 4.6342 8.77082C4.6915 8.61902 4.75537 8.46919 4.82579 8.32169C4.74458 8.52894 4.67116 8.74073 4.60479 8.9574C3.94927 10.9474 4.41195 13.225 5.99454 14.8076C8.23287 17.0459 11.8618 17.0459 14.1001 14.8076Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1377 10.1658V10.8778V11.3521C7.1377 11.8255 7.52145 12.2093 7.9949 12.2093C8.46832 12.2093 8.85203 11.8255 8.85203 11.3521V10.8771V10.1658C8.85203 9.69243 8.46832 9.30859 7.9949 9.30859C7.52145 9.30859 7.1377 9.69243 7.1377 10.1658Z" fill="currentColor"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3809 10.1658V10.8778V11.3521C11.3809 11.8255 11.7647 12.2093 12.238 12.2093C12.7114 12.2093 13.0952 11.8255 13.0952 11.3521V10.8771V10.1658C13.0952 9.69243 12.7114 9.30859 12.238 9.30859C11.7647 9.30859 11.3809 9.69243 11.3809 10.1658Z" fill="currentColor"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconChannel',
  };
</script>