<template>
    <div class="consoleMain" :style="mainStyles">
      <div class="noticeHead">
        자주 묻는 질문
      </div>

            <div class="tagSection_ref">
                <div class="search_section">
                  <input class="searchBox" v-model="keyword" @keyup.enter="faqSearch" placeholder="검색">
                </div>
            </div>

            <div class="tabSection2">
              <div class="tabMenu2">
                <button class="qnaButton" :class="{ active: faqTypeCd === '' }" @click.prevent="fnSetFaqTypeCd('')">전체</button>
                  <button
                    class="qnaButton"
                    v-for="(faqTypeInfo, idx) in faqTypeList"
                    :key="idx"
                    :class="{ active: faqTypeCd === faqTypeInfo.codeVal1 }"
                    @click.prevent="fnSetFaqTypeCd(faqTypeInfo.codeVal1)"
                  >
                  {{ faqTypeInfo.codeName1 }}
                </button>
            </div>
            </div>

            <div class="qnaSection">
                  <div 
                    class="accordion__item" 
                    v-for="(contant, idx) in contants" 
                    :key="idx"
                  >
                    <div 
                      class="accordion__title" 
                      @click="toggleAccordion(idx)"
                    >
                      <span class="accordion__Q">
                        Q{{ contant.rownum + offset > 9 ? '' : '' }}{{ contant.rownum + offset }}
                      </span>
                      <span>{{ contant.titleContent | unescapeXss }}</span>
                    </div>
                    <div 
                      class="accordion__value" 
                      v-show="activeIndex === idx"
                    >
                      <span class="accordion__A">A.</span>{{ contant.answerContent | unescapeXss }}
                    </div>
                  </div>
            </div>

          
            <!-- pagination -->
            <div id="pageContent">
              <PageLayer2 @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></PageLayer2>
            </div>
            <!-- //pagination -->
          
        </div>
</template>

<script>
import PageLayer2 from '@/components/PageLayer2.vue';
import customerCenterApi from "@/modules/customerCenter/service/api.js";

export default {
  name: 'faq',
  components : {
    PageLayer2,
  },
  props: {
    componentsTitle: {
      type: String,
      default: 'FAQ',
    },
  },
  
  data() {
    return {
      keyword: "", // 검색어를 담을 변수
      searchResults: [], // 검색 결과를 담을 변수
      faqTypeCd: '',
      faqTypeList: [],
      contants: [],
      listSize : 10,  // 한페이지 출력 리스트 수
      pageNo : 1,  // 현재 페이징 위치
      totCnt : 0,  //전체 리스트 수
      offset : 0, //페이지 시작점
      activeIndex: null
    }
  },

  async mounted() {
    await this.fnSelectFaqType();
    await this.fnSetFaqTypeCd(this.faqTypeCd);
  },

  methods: {
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    
     async fnSetFaqTypeCd(faqTypeCd) {
      this.faqTypeCd = faqTypeCd;
      await this.fnSelectFaqList();
    },

    async fnSelectFaqList() {
      const params = {
        faqType: this.faqTypeCd,
        pageNo: this.pageNo,
        listSize: this.listSize,
      };

      try {
        const response = await customerCenterApi.selectFaqList(params);
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          alert(this.componentsTitle, result.message);
        }
      } catch (error) {
        console.error('FAQ 리스트를 가져오는 중 오류 발생:', error);
      }
    },

    async fnSelectFaqType() {
      const params = {
        codeTypeCd: 'FAQ_TYPE',
        useYN: 'Y',
      };

      try {
        const response = await customerCenterApi.selectCodeList(params);
        const result = response.data;
        if (result.success) {
          this.faqTypeList = result.data;
        } else {
          alert(this.componentsTitle, result.message);
        }
      } catch (error) {
        console.error('FAQ 타입을 가져오는 중 오류 발생:', error);
      }
    },

    async faqSearch() {
      const params = {
        keyword: this.keyword,
        pageNo: this.pageNo,
        listSize: this.listSize
      };

     await customerCenterApi.faqSearch(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    async fnSearch(pageNo) {
      this.pageNo = pageNo || 1;
      await this.fnSelectFaqList();
    },
  },
};
</script>
