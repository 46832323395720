<template>
  <div>
    <BcChanRsc />
  </div>
</template>

<script>
import BcChanRsc from '../components/bc-chan-rsc.vue';

export default {
  components: {
    BcChanRsc
  }
}
</script>