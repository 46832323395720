<template>
    <div>
    <div id="multiSendMessage" class="tabContent active">
        <div class="boxSt">
            <div class="firstRow">
                <div class="boxFirstRow1">
                    <div class="boxContentTitle">검색조건</div>
                    <div class="searchOption">
                        <input type="text" id="searchCondition" class="searchCondition">
                        <label for="searchCondition"></label>
                    </div>
                </div>
            </div>
            <div class="secondRow">
                <div class="boxSecondRow1">
                    <div class="boxContentTitle">메시지 구분</div>
                    <label for="messageTotal"></label>
                    <input type="checkbox" id="messageTotal">전체
                    <label for="advertising"></label>
                    <input type="checkbox" id="advertising">광고성
                    <label for="informativity"></label>
                    <input type="checkbox" id="informativity">정보성
                </div>
            </div>
            <div class="thirdRow">
                <div class="boxthirdRow1">
                    <div class="boxContentTitle">템플릿 유형</div>
                    <label for="templateTotal"></label>
                    <input type="checkbox" id="templateTotal">전체
                    <label for="searchMessage"></label>
                    <input type="checkbox" id="searchMessage">문자
                    <label for="searchRcs"></label>
                    <input type="checkbox" id="searchRcs">RCS
                    <label for="searchKko"></label>
                    <input type="checkbox" id="searchKko">카카오톡
                </div>
            </div>
            <div class="fourthRow">
                <div class="boxfourthRow1">
                    <div class="boxContentTitle">템플릿 상태</div>
                    <label for="templateStatustotal"></label>
                    <input type="checkbox" id="total">전체
                    <label for="advertising"></label>
                    <input type="checkbox" id="advertising">광고성
                    <label for="informativity"></label>
                    <input type="checkbox" id="informativity">정보성
                </div>
            </div>
            <div class="fifthRow">
                <div class="boxfifthRow1">
                    <div class="boxContentTitle">등록일자</div>
                    <div class="sendOption">
                        <input type="date" id="searchStartDate" class="datePickerInput">
                        <span class="searchBlank">~</span>
                        <input type="date" id="searchEndDate" class="datePickerInput">
                    </div>
                </div>

                <div class="boxfifthRow2">
                    <div class="boxContentTitle">
                        <ul class="searchDay">
                            <li ><a title="당일 서비스 검색">오늘</a></li>
                            <li ><a title="7일 서비스 검색">일주일</a></li>
                            <li ><a title="15일 서비스 검색">15일</a>
                            </li>
                            <li ><a title="1개월 서비스 검색">1개월</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <a class="searchButton">검색</a>
        </div>
    </div>
    <!-- 검색 -->
    <!-- 리스트 -->
    <div id="ShippingList" class="tabContent active">
        <div class="shippingListHeader">
            <span class="totalCount">총 0건</span>
            <a href="#" class="excelDownload">템플릿 관리</a>
            <a @click.prevent="goToMessagePage" class="excelDownload">발송</a>
        </div>
        <table class="shippingListTable">
            <thead>
                <tr>
                    <th><input type="checkbox" id="totalCheck"></th>
                    <th>No.</th>
                    <th>템플릿ID</th>
                    <th>템플릿명</th>
                    <th>템플릿채널</th>
                    <th>메시지구분</th>
                    <th>메시지타입</th>
                    <th>상태</th>
                    <th>등록자</th>
                </tr>
            </thead>
            <tbody>
                <tr class="rowHeightW">
                    <td><input type="checkbox" id="totalCheck"></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>


                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>


                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>


                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>


                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>


                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>


                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>

        </table>
    </div>
</div>
</template>

<script>
export default{
    methods:{
        goToMessagePage(){
            this.$router.push('./multiSendMain')
        }
    }
}

</script>