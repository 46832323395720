import RcsTemplateSend from '../views/bm-rcsTemplateSend.vue'

export default [
    {
        path: '/uc/rcsTemplateSend',
        name: 'rcsTemplateSend',
        meta: {menu:'UC_SND_RCS_MSG',title:'발송'},
        component: RcsTemplateSend,
        props: true
    }
]