<template>
  <div>
    <spam />
  </div>
</template>

<script>
import spam from '../components/bc-sendLimit-spam.vue';

export default {
  components: {
    spam
  }
}
</script>