<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
    <path d="M40.5 10.9987V32.9987C40.5 34.007 40.141 34.8702 39.4229 35.5883C38.7049 36.3063 37.8417 36.6654 36.8333 36.6654H7.5C6.49166 36.6654 5.62847 36.3063 4.91041 35.5883C4.19236 34.8702 3.83333 34.007 3.83333 32.9987V10.9987C3.83333 9.99036 4.19236 9.12717 4.91041 8.40911C5.62847 7.69106 6.49166 7.33203 7.5 7.33203H36.8333C37.8417 7.33203 38.7049 7.69106 39.4229 8.40911C40.141 9.12717 40.5 9.99036 40.5 10.9987ZM7.5 14.6654H36.8333V10.9987H7.5V14.6654ZM7.5 21.9987V32.9987H36.8333V21.9987H7.5Z" fill="#7C7DFA"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconCard',
  };
</script>