<template>
  <div class="page-header" :style="isReady ? headerStyle : {}">
    <div class="inner">
      <span class="page-header-subtitle" v-if="subtitle">{{ subtitle }}</span>
      <h3 class="page-header-title">
        {{ title }}
      </h3>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageHeader",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    imageName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isReady: false,
    };
  },
  computed: {
    headerStyle() {
      return {
        backgroundImage: `url(${require("@/assets/images/landing/" +
          this.imageName +
          ".png")})`,
        backgroundImageTablet: `url(${require("@/assets/images/landing/" +
          this.imageName +
          "-1024.png")})`,
        backgroundImageMobile: `url(${require("@/assets/images/landing/" +
          this.imageName +
          "-375.png")})`,
      };
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.isReady = true;
    });
  },
};
</script>
<style lang="scss" scoped>
@use "@/assets/scss/landing/abstracts/variables" as p;

.page-header {
  @include p.flex(column, center, null);
  width: 100%;
  height: 160px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: var(--background-mobile);
  @include p.tablet {
    @include p.flex-center;
    height: 240px;
    background-image: var(--background-tablet);
    background-size: cover;
  }
  @include p.desktop {
    height: 455px;
    background-image: var(--background-desktop);
  }
  .inner {
    padding: 0 00px;
    @include p.tablet {
      max-width: none;
      padding: 0 0px;
    }
    @include p.desktop {
      padding-left: 0px;
    }
    .page-header-subtitle {
      display: block;
      padding-bottom: 8px;
      font-weight: 400;
      font-size: 0.75rem;
      color: p.color(white);
      text-shadow: 0px 4px 16px rgba(109, 110, 250, 0.6);
      line-height: 160%;
      @include p.tablet {
        font-weight: 500;
        font-size: 1rem;
        line-height: 140%;
      }
      @include p.desktop {
        font-weight: 400;
        font-size: 1.5rem;
      }
    }
    .page-header-title {
      font-weight: 700;
      font-size: 1.75rem;
      color: p.color(white);
      text-shadow: 0px 4px 16px rgba(109, 110, 250, 0.6);
      line-height: 140%;
      @include p.tablet {
        font-size: 2.5rem;
      }
      @include p.desktop {
        font-weight: 800;
        font-size: 5rem;
      }
    }
  }
}
</style>
