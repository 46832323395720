<template>
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <path d="M12.1362 10.6536C11.9653 10.4827 11.6885 10.4827 11.5176 10.6536L10.1265 12.0443L9.46851 11.3863C9.29761 11.2154 9.02075 11.2154 8.84985 11.3863C8.67896 11.5572 8.67896 11.834 8.84985 12.0049L9.81714 12.9722C9.90259 13.0577 10.0145 13.1004 10.1265 13.1004C10.2384 13.1004 10.3503 13.0577 10.4358 12.9722L12.1362 11.2722C12.3071 11.1013 12.3071 10.8245 12.1362 10.6536Z" fill="#374151"/>
  <path d="M10.2286 1.42508C9.98163 1.08051 9.58215 0.875 9.16003 0.875H3.04785C2.32409 0.875 1.73535 1.46375 1.73535 2.1875V11.8125C1.73535 12.5363 2.32409 13.125 3.04785 13.125H7.72064C7.96246 13.125 8.15814 12.9293 8.15814 12.6875C8.15814 12.4457 7.96246 12.25 7.72064 12.25H3.04785C2.80646 12.25 2.61035 12.0539 2.61035 11.8125V2.1875C2.61035 1.94632 2.80646 1.75 3.04785 1.75H9.16003C9.30316 1.75 9.43347 1.81729 9.51721 1.93414L11.2787 4.39999C11.3322 4.47562 11.3604 4.56384 11.3604 4.65506V9.21356C11.3604 9.45538 11.556 9.65106 11.7979 9.65106C12.0397 9.65106 12.2354 9.45538 12.2354 9.21356V4.65506C12.2354 4.38184 12.1516 4.11908 11.9918 3.89307L10.2286 1.42508Z" fill="#374151"/>
  <path d="M4.69141 8.47852H9.27875C9.52057 8.47852 9.71625 8.28284 9.71625 8.04102C9.71625 7.79919 9.52057 7.60352 9.27875 7.60352H4.69141C4.44958 7.60352 4.25391 7.79919 4.25391 8.04102C4.25391 8.28284 4.44958 8.47852 4.69141 8.47852Z" fill="#374151"/>
  <path d="M4.69336 10.7715H8.0575C8.29932 10.7715 8.495 10.5758 8.495 10.334C8.495 10.0922 8.29932 9.89648 8.0575 9.89648H4.69336C4.45154 9.89648 4.25586 10.0922 4.25586 10.334C4.25586 10.5758 4.45154 10.7715 4.69336 10.7715Z" fill="#374151"/>
  <path d="M4.69141 6.18555H9.27875C9.52057 6.18555 9.71625 5.98965 9.71625 5.74805C9.71625 5.50644 9.52057 5.31055 9.27875 5.31055H4.69141C4.44958 5.31055 4.25391 5.50644 4.25391 5.74805C4.25391 5.98965 4.44958 6.18555 4.69141 6.18555Z" fill="#374151"/>
</svg>
</template>

<script>
  export default {
    name: 'IconPaper',
  };
</script>