import httpClient from '@/common/http-client';

/* common */
const selectValidUseChGrp = (params) => {
  return httpClient.post('/ucApi/sendMessage/selectValidUseChGrp', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectApiKey = (params) => {
  return httpClient.post('/commonApi/getApiKey', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};


/* NICE 인증 */
const getNiceCheck = () => {
  return httpClient.post('/sendInfoApi/manage/getNiceCheck', { headers: {"show-layer": "Yes", "activity": "READ" } });
};
const getNiceCheckSuccess = (params) => {
  return httpClient.post('/sendInfoApi/manage/getNiceCheckSuccess', params, { headers: {"show-layer": "Yes", "activity": "READ" } });
};
const getNiceCheckFail = (params) => {
  return httpClient.post('/sendInfoApi/manage/getNiceCheckFail', params, { headers: {"show-layer": "Yes", "activity": "READ" } });
};

const getKmcCheck = () => {
  return httpClient.post('/sendInfoApi/manage/getKmcCheck', { headers: {"show-layer": "Yes", "activity": "READ" } });
};
const getKmcCheckSuccess = (params) => {
  return httpClient.post('/sendInfoApi/manage/getKmcCheckSuccess', params, { headers: {"show-layer": "Yes"} });
};

/* 문자 발신번호*/
const saveCallNumPro = (params) => {
  return httpClient.post('/sendInfoApi/manage/saveCallNumPro', params, { headers: {"show-layer": "Yes", "activity": "SAVE" } });
};
const getCallNumProxy = (params) => {
  return httpClient.post('/sendInfoApi/manage/getCallNumProxy', params, { headers: {"show-layer": "Yes", "activity": "READ" } });
};
const getCallNumList = (params) => {
  return httpClient.post('/sendInfoApi/manage/getCallNumList', params, { headers: {"show-layer": "Yes", "activity": "READ" } });
};
const delCallNum = (params) => {
  return httpClient.post('/sendInfoApi/manage/delCallNum', params, { headers: {"show-layer": "Yes", "activity": "SAVE" } });
};
const updateCallNumNm = (params) => {
  return httpClient.post('/sendInfoApi/manage/updateCallNumNm', params, { headers: {"show-layer": "Yes", "activity": "SAVE" } });
};
const excelDownloadCallnumList = (params) => {
  return httpClient.post('/sendInfoApi/manage/excelDownloadCallnumList', params, { headers: {"show-layer": "Yes", "activity":"READ"}, responseType: 'arraybuffer' })
  .then((response) => fnExcelDownCallback(response));
};
/* RCS 문자 발신번호*/
const selectApprovalBrandList = (params) => {
  return httpClient.post('/sendInfoApi/manage/selectApprovalBrandList', params, { headers: { "show-layer": "Yes", "activity": "READ"}});
};
const getRcsCallNumList = (params) => {
  return httpClient.post('/sendInfoApi/manage/getRcsCallNumList', params, { headers: {"show-layer": "Yes", "activity": "READ" } });
};
const deleteCallbackForApi = (params) => {
  return httpClient.post('/sendInfoApi/manage/deleteCallbackForApi', params, { headers: { "show-layer": "Yes", "activity": "SAVE"}});
};
const excelDownloadRcsCallnumList = (params) => {
  return httpClient.post('/sendInfoApi/manage/excelDownloadRcsCallnumList', params, { headers: {"show-layer": "Yes", "activity":"READ"}, responseType: 'arraybuffer' })
  .then((response) => fnExcelDownCallback(response));
};


export default {
  getNiceCheck,
  getNiceCheckSuccess,
  getNiceCheckFail,
  saveCallNumPro,
  getCallNumProxy,
  getCallNumList,
  delCallNum,
  updateCallNumNm,
	selectValidUseChGrp,
	selectApiKey,
  selectApprovalBrandList,
  getRcsCallNumList,
  deleteCallbackForApi,
  getKmcCheck,
  getKmcCheckSuccess,
  excelDownloadCallnumList,
  excelDownloadRcsCallnumList
};

function fnExcelDownCallback(response){
  try {
    let blob = new Blob([response.data], { type: response.headers['content-type'] })
    let fileName = fnGetFileName(response.headers['content-disposition'])
    fileName = decodeURI(fileName)

    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else { // not IE
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.target = '_self'
      if (fileName) link.download = fileName
      link.click()
    }
  } catch (e) {
    console.error(e)
  }
}

function fnGetFileName (contentDisposition) {
  let fileName = contentDisposition
    .split(';')
    .filter((ele) => {
      return ele.indexOf('filename') > -1
    })
    .map((ele) => {
      return ele
        .replace(/"/g, '')
        .split('=')[1]
    })
  return fileName[0] ? fileName[0] : null
}

function getFileName (contentDisposition) {
    let fileName = contentDisposition
      .split(';')
      .filter((ele) => {
        return ele.indexOf('filename') > -1
      })
      .map((ele) => {
        return ele
          .replace(/"/g, '')
          .split('=')[1]
      })
    return fileName[0] ? fileName[0] : null
}

function fnDownCallback(response){
  try {
    let blob = new Blob([response.data], { type: response.headers['content-type'] });
    let fileName = fnGetFileName(response.headers['content-disposition']);
    fileName = decodeURI(fileName);

    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else { // not IE
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_self';
      if (fileName) link.download = fileName;
      link.click();
    }
  } catch (e) {
    console.error(e);
  }
}
