<template>
	<div>
		<SendByDay />
	</div>
</template>

<script>
import SendByDay from '../components/bc-statisticsUser-sendByDay.vue';

export default {
	components: {
		SendByDay
	}
}
</script>