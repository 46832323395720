
import cashMain from '../views/bm-cashMain.vue'
import cashHistory from '../views/bm-cashHistory.vue'
import serviceHist from '../views/bm-cashDept.vue'

export default [
    {
        path: '/ac/cash/cashMain',
        name: 'cashMain',
        props: true,
        meta: { menu: 'AC_SETTLE_MNG' },
        component: cashMain
    },
    {
        path: '/ac/cash/history',
        name: 'cashHistory',
        props: true,
        meta: { menu: 'AC_SETTLE_MNG1' },
        component: cashHistory
    },
    {
        path: '/ac/cash/serviceHist',
        name: 'serviceHist',
        props : true,
        meta: { menu: 'AC_SETTLE_MNG2'},
        component: serviceHist
    }
]