<template>
    <div class="modal modalStyle in" id="chkCorpPopup" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="of_h">
                        <h2>사업자 확인</h2>
                        <hr>
                        <div style="height:450px; overflow:auto;">
                            <table class="chkCorp_table" id="selCorpTbl">
                                <colgroup>
									<col style="width:50%">
									<col style="width:50%">
								</colgroup>
								<thead>
									<tr>
                                        <th class="chkCorp_th">고객사/대표자 명</th>
                                        <th class="chkCorp_th">사업회사명</th>
									</tr>
								</thead>
								<tbody>
                                    <tr v-for="(row, idx) in data" :key="idx" @click="fnSelectCorpInfo(row,idx)" :class="index == idx ? 'selected' : ''" @dblclick="fnSelRow(row)">
                                        <td class="chkCorp_th">{{ row.ceoName }}</td>
                                        <td class="chkCorp_th">{{ row.corpName }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>					
                    </div>
                    <div class="of_h consolMarginTop mt20">
                            <a @click="fnSelect" class="chkCorp_selected_btn" title="선택" data-toggle="modal">선택</a>
                            <a @click="fnCloseLayer" class="chkCorp_btn" title="닫기" data-dismiss="modal">닫기</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import signUpApi from "@/modules/signUp/service/api"

export default {
    name : 'chkCorpPopup',
    props : {
        selCorp : {
            type : Object,
            require : false
        },
        popReset : {
            type : Number,
            require : false
        },
        dataList : {
            type : Array,
            require : false
        }
    },
    data(){
        return {
            index : -1,
            data : [],
            selRowData : {}
        }
    },
    watch : {
        popReset(){
            this.index = -1;
            this.data = this.dataList;
            this.selRowData = {};
        }
    },
    methods : {
        fnCloseLayer(){
            jQuery("#chkCorpPopup").modal("hide");
        },
        fnSelectCorpInfo(rowData,idx){
            this.selRowData = rowData;
            this.index = idx;
        },
        fnSelRow(rowData){
            if(rowData.custNo != undefined && rowData.custNo != null && rowData.custNo != ""){
                this.fnGetSelectCustInfo(rowData);
            } else {
                alert("고객사를 선택해주세요.");
            }
        },
        fnSelect(){
            var rowData = new Object();

            if(this.selRowData.custNo != undefined && this.selRowData.custNo != null && this.selRowData.custNo != ""){
                rowData = this.selRowData;
                this.fnGetSelectCustInfo(rowData);
            } else {
                alert("고객사를 선택해주세요.");
            }
        },
        fnGetSelectCustInfo(rowData){
            var vm = this;

            var params = {
                custNo : rowData.custNo
            };

            signUpApi.selectCorpCustList(params).then(function(response) {
                var result = response.data;
                if(result.success){
                    vm.$emit("update:selCorp", rowData);
                    vm.fnCloseLayer();
                } else {
                    alert(result.message);
                }
            });
        }
    }
}
</script>

<style>
.chkCorp_table tr.selected td {
    background-color: rgb(61, 61, 61);
    color: #ffffff;
}

#chkCorpPopup {
    height: 100% !important;
}

.chkCorp_table {
    width: 90%;
    font-size: 1.8rem;
    border: 1px solid;
    margin: 0 auto;
}

thead {
    border-bottom: 1px solid;
}

.chkCorp_th {
    text-align: center;
    height: 40px !important;
}

a.chkCorp_btn {
    font-size: 1.8rem;
    color: #626262;
    border-radius: 5px;
    background-color: white;
    border: 1px solid #626262;
    padding: 10px 40px 10px 40px;
}

.chkCorp_selected_btn {
    background-color: rgb(61, 61, 61);
    color: #ffffff;
    font-size: 1.8rem;
    border-radius: 5px;
    border: 1px solid #626262;
    padding: 10px 40px 10px 40px;
}

.of_h.consolMarginTop.mt20 {
    display: flex;
    width: 80%;
    margin: 0 auto;
    justify-content: space-evenly;
    margin-top: 20px;
    margin-bottom: 20px;
}
</style>