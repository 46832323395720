<template>
	<div class="consoleMain" :style="mainStyles">
		<article>
			<div class="contentHeader mb20">
				<h2>이용현황</h2>
			</div>
			<!-- 본문 -->
			<div class="contentBody">
				<ul class="tab_s3">
					<li :class="{active:(selMainTab == 1)}" @click="selMainTab=1"><a>이용상세</a></li>
					<li :class="{active:(selMainTab == 2)}" @click="selMainTab=2"><a>이용현황</a></li>
					<li :class="{active:(selMainTab == 3)}" @click="selMainTab=3"><a>실패현황</a></li>
				</ul>
			</div>
			<!-- 이용내역 탭 -->
			<useDetail v-if="selMainTab==1"></useDetail>
			<!-- 서비스요금 탭 -->
			<useStatus v-if="selMainTab==2"></useStatus>
			<useFail v-if="selMainTab == 3"></useFail>
		</article>
	</div>
</template>


<script>
import useDetail from "@/modules/use/components/bc-use-detail.vue";
import useStatus from "@/modules/use/components/bc-use-status.vue";
import useFail from "@/modules/use/components/bc-use-fail.vue";
import { mapState } from 'vuex';
export default {
	computed: {
    ...mapState(['isSidebarOpen']),
    mainStyles() {
      return {
        width: this.isSidebarOpen ? '82%' : '100%',
        transition: 'width 0.5s ease',
        marginLeft: this.isSidebarOpen ? '18%' : '0',
      };
    },
  },
	components: {
		useDetail,
		useStatus,
		useFail
	},
	data() {
		return {
			selMainTab	: 1
		}
	},
	mounted() {
	},
	methods: {
	}
}
</script>