<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <path d="M2.76492 11.6103H6.49371C6.73532 11.6103 6.93121 11.4144 6.93121 11.1728C6.93121 10.9312 6.73532 10.7353 6.49371 10.7353H2.76492C2.20523 10.7353 1.75 10.282 1.75 9.72467V5.07196H10.3052V5.80511C10.3052 6.04672 10.5011 6.24261 10.7427 6.24261C10.9843 6.24261 11.1802 6.04672 11.1802 5.80511V3.90601C11.1802 2.86395 10.3323 2.01608 9.29025 2.01608H8.82178V1.74414C8.82178 1.50253 8.62589 1.30664 8.38428 1.30664C8.14267 1.30664 7.94678 1.50253 7.94678 1.74414V2.01608H4.10733V1.74414C4.10733 1.50253 3.91144 1.30664 3.66983 1.30664C3.42822 1.30664 3.23233 1.50253 3.23233 1.74414V2.01608H2.76492C2.11348 2.01608 1.53795 2.34747 1.19802 2.85047C0.994175 3.15192 0.875 3.51508 0.875 3.90558V9.72467C0.875 10.7644 1.72287 11.6103 2.76492 11.6103ZM10.3041 4.19696H1.75V3.90601C1.75 3.76616 1.77841 3.63286 1.82979 3.51155C1.98384 3.14807 2.34427 2.89236 2.76321 2.89236H3.23233V3.16559C3.23233 3.4072 3.42822 3.60309 3.66983 3.60309C3.91144 3.60309 4.10733 3.4072 4.10733 3.16559V2.89236H7.94678V3.16559C7.94678 3.4072 8.14267 3.60309 8.38428 3.60309C8.62589 3.60309 8.82178 3.4072 8.82178 3.16559V2.89236H9.29111C9.84973 2.89236 10.3041 3.34695 10.3041 3.90558V4.19696Z" fill="#374151"/>
    <path d="M12.6154 6.99356L12.165 7.44396C11.6208 6.93169 10.8902 6.61523 10.0857 6.61523C8.41 6.61523 7.04688 7.97836 7.04688 9.65402C7.04688 11.3297 8.41 12.693 10.0857 12.693C11.7613 12.693 13.1247 11.3297 13.1247 9.65402C13.1247 9.09978 12.973 8.58134 12.7128 8.13351L13.234 7.61221C13.4049 7.44131 13.4049 7.16446 13.234 6.99356C13.0631 6.82266 12.7863 6.82266 12.6154 6.99356ZM12.2497 9.65402C12.2497 10.8473 11.279 11.818 10.0857 11.818C8.89258 11.818 7.92188 10.8473 7.92188 9.65402C7.92188 8.46094 8.89258 7.49023 10.0857 7.49023C10.6489 7.49023 11.1581 7.71168 11.5435 8.06555L9.97244 9.63672L9.30594 8.97021C9.13504 8.79932 8.85818 8.79932 8.68729 8.97021C8.51639 9.14111 8.51639 9.41797 8.68729 9.58887L9.66312 10.5647C9.74515 10.6467 9.85645 10.6929 9.97244 10.6929C10.0884 10.6929 10.1997 10.6467 10.2818 10.5647L12.0633 8.78305C12.1814 9.05008 12.2497 9.34376 12.2497 9.65402Z" fill="#374151"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconCalendar',
  };
</script>