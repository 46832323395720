<template>
  <div>
    <signUp />
  </div>
</template>

<script>
import signUp from '../components/signUp.vue';

export default {
  components: {
    signUp
  }
}
</script>