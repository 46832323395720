<template>
  <div class="uc-multi-send-templat-list list-view">
    <TemplateTabs />

    <div class="tab-contents">
      <!-- 검색영역 Start -->
      <div class="search-section card border-0">
        <div class="search-section-forms">
          <div class="d-flex align-items-center flex-wrap mb-4">
           <label>검색조건</label>  
            <b-dropdown 
              id="template-dropdown" 
              variant="secondary" 
              class="template-dropdown"
              text="">
              <template #button-content>
                <span>{{ searchData.searchCondi === 'templateTitle' ? '템플릿명' : '템플릿ID' }}</span>
                <IconArrowDown />
              </template>
              <b-dropdown-item @click="searchData.searchCondi = 'templateTitle';">템플릿명</b-dropdown-item>
              <b-dropdown-item @click="searchData.searchCondi = 'templateCode';">템플릿ID</b-dropdown-item>
            </b-dropdown>
            <div class="search-group">
              <SearchInput v-model="searchData.searchText" @search="fnSelectMultiSendTemplateList"/>
            </div>
          </div>
          
          <!-- Hideable section -->
          <transition name="slide-fade">
            <div v-show="!hideMenu" class="check-menu align-items-center mb-4">
             
              <label>메세지 구분</label>
              <b-form-group>
                <b-form-checkbox-group
                id="service-checkbox-group-message"
                v-model="msgKindCdSelected"
                :options="messageOptions"
                name="message-1"
                @input="fnSearchMsgKindCdChkAll"
                ></b-form-checkbox-group>
              </b-form-group>
              <i class="vertical-divider"></i>

              <label>템플릿유형</label>
              <b-form-group>
                <b-form-checkbox-group
                  id="service-checkbox-group-template"
                  v-model="templateSelected"
                  :options="templateOptions"
                  name="template-1"
                  @input="fnSearchMsgChChkAll"
                ></b-form-checkbox-group>
              </b-form-group>
              <i class="vertical-divider"></i>

              <label>템플릿 상태</label>
              <b-form-group>
                <b-form-checkbox-group
                  id="service-checkbox-group-status"
                  v-model="templateStatusSelected"
                  :options="templateStatusOptions"
                  name="status-1"
                  @input="fnSearchTmpltStatusCdChkAll"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </transition>
          
          <button type="button" class="btn btn-search-condition" @click="toggleMenu">
            <span>{{ hideMenu ? '검색 조건 펼치기' : '검색 조건 접기' }}</span>
            <IconArrowDown :class="{ rotated: hideMenu }" />
          </button>
        </div>
        <b-button @click="fnSearch()" variant="dark" class="btn-submit">검색</b-button>
      </div>
      <!-- 검색영역 End -->

      <!-- 리스트 영역 Start -->
      <div class="table-section card">
        <div class="d-flex align-items-center">
          <p class="list-count">전체 <span class="text-primary">{{totCnt | formatComma}}건</span></p>
         
          <b-dropdown id="pageCount-dropdown" variant="secondary" class="pageCount-dropdown">
            <template #button-content>
              <span>{{ pageCount }}개씩 보기</span>
              <IconArrowDown />
            </template>
             <b-dropdown-item-button @click="handlePageCountChange(10)">10개씩 보기</b-dropdown-item-button>
             <b-dropdown-item-button @click="handlePageCountChange(20)">20개씩 보기</b-dropdown-item-button>
            <b-dropdown-item-button @click="handlePageCountChange(30)">30개씩 보기</b-dropdown-item-button> 
          </b-dropdown>


          <b-button variant="secondary" class="btn-svg btn-svg-right ml-auto" @click="navigateToManage">
            <span>템플릿 등록</span>
            <IconArrowRight />
          </b-button>
          <i class="vertical-divider"></i>
          <b-button variant="outline-primary" @click="openDeleteAlertModal">삭제</b-button>
         
          <b-button @click="fnExcelDownLoad" variant="outline-primary" class="btn-svg btn-svg-right ml-2">
            <span>엑셀 다운로드</span>
            <IconArrowLineDown />
          </b-button>
        
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>

                <th scope="col" class="text-center">
                  <b-form-checkbox
                    id="selectAll"
                    name="selectAll"
                    v-model="selectAllChecked"
                    @click="checkedAll($event.target.checked)"
                    size="lg"
                  ></b-form-checkbox>
                </th>
               

                <th scope="col">
                  <div class="d-flex align-items-center">
                    NO.
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center"> <!-- NOTE: 정렬시 class명 추가하면 스타일 변경됨 -->
                    템플릿 ID
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    템플릿명
                    <IconSort class="down" /> <!-- NOTE: 정렬시 class명 추가하면 스타일 변경됨 -->
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    템플릿 채널
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    메시지 구분
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    메시지 타입
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    상태
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    등록자
                    <IconSort />
                  </div>
                </th>
                <th scope="col">
                  <div class="d-flex align-items-center">
                    등록일자
                    <IconSort />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in datas" :key="data.rownum">
               
                <td class="text-center">
                  <b-form-checkbox
                    v-model="data.selected"
                    :id=index
                    :value="data.tmpltCode"
                    @change="selected($event)"
                    size="lg"
                  ></b-form-checkbox>
                </td>

                <td>{{totCnt-offset-data.rownum+1 | formatComma}}</td>
                <td>
                  <span class="text-underline"  @click="navigateToManage(data)">{{data.tmpltCode}}</span>
                </td>
                <td>{{$gfnCommonUtils.unescapeXss(data.tmpltTitle)}}</td>
                <td>{{fnJsonArrayToChannelLit(data.checkedChannel)}}</td>
                <td>{{data.msgKindName}}</td>
                <td>{{data.msgTypeName}}</td>
                <td>{{data.tmpltStatusName}}</td>
                <td>{{data.regNm}}</td>
                <td>{{data.regDt}}</td>
              </tr>

              <tr v-if="datas.length == 0">
									<td class="text-center" colspan="11">검색된 내용이 없습니다.</td>
								</tr>
            </tbody>
          </table>

          <Pagination @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="pageCount" :pageNum="pageNo" ref="updatePaging" />
        </div>
      </div>
      <!-- 리스트 영역 End -->
    </div>

    <AlertModal title="템플릿 삭제" desc="삭제할 항목을 선택해주세요." />
  </div>
</template>

<script>
import IconArrowDown from '@/components/service/icons/IconArrowDown.vue'
import TemplateTabs from '@/components/service/template/TemplateTabs.vue'
import SearchInput from '@/components/service/form/SearchInput.vue'
import IconSort from '@/components/service/icons/IconSort.vue';
import IconArrowRight from '@/components/service/icons/IconArrowRight.vue';
import Pagination from '@/components/service/Pagination.vue';
import AlertModal from '@/components/service/modal/AlertModal.vue'
import IconArrowLineDown from '@/components/service/icons/IconArrowLineDown.vue';

import templateApi from "@/modules/template/service/templateApi.js";
// import PageLayer from '@/components/PageLayer.vue';
// import SelectLayer from '@/components/SelectLayer.vue';
// import Calendar from "@/components/Calendar.vue";
import tokenSvc from '@/common/token-service';
import confirm from "@/modules/commonUtil/service/confirm.js";
import {eventBus} from "@/modules/commonUtil/service/eventBus";
import { mapState } from 'vuex';

export default {
  components: { IconArrowDown, SearchInput, Pagination, IconSort, TemplateTabs, IconArrowRight, AlertModal, IconArrowLineDown, },
  name: "multiSendTemplateList",
  props: {
		searchData : {
			type: Object,
			require: false,
			default: function() {
				return {
					'searchCondi'				: 'templateTitle',
					'searchText'				: '',
					'searchTmpltStatus'	: '',
					//'searchStartDate'		: this.$gfnCommonUtils.getCurretDate(),
					//'searchEndDate'			: this.$gfnCommonUtils.getCurretDate()
				}
			}
		},
		componentsTitle: {
			type: String,
			require: false,
			default: function() {
				return '통합 템플릿 리스트';
			}
		},
	},
	computed: {
		...mapState(['isSidebarOpen']),
		mainStyles() {
		return {
			width: this.isSidebarOpen ? '82%' : '100%',
			transition: 'width 0.5s ease',
			marginLeft: this.isSidebarOpen ? '18%' : '0', // 사이드바의 특정 너비가 있다면 조정
		};
		}
  	},
  data() {
    return {
      hideMenu: false, // 메뉴 숨김 상태
      messageSelected: [],
      listAllChecked: false,
			listChkBox: [],
			listSize : 10,  // select 박스 value (출력 갯수 이벤트)
			pageNo : 1,  // 현재 페이징 위치
			totCnt : 0,  //전체 리스트 수
			offset : 0, //페이지 시작점
			searchDateInterval: 7,
			datas: [],
      msgKindCdSelected: [], // 선택된 메시지 종류
      messageOptions: [
        { text: '전체', value: 'message-all' },
        { text: '정보성', value: 'message-info' },
        { text: '광고성', value: 'message-ad' },
      ],
      templateSelected: [],
      templateOptions: [
        { text: '전체', value: 'template-all' },
        { text: '문자', value: 'template-message' },
        { text: 'RCS', value: 'template-rcs' },
        { text: '카카오톡', value: 'template-kakao' },
      ],
      templateStatusSelected: [],
      templateStatusOptions: [
        { text: '전체', value: 'status-all' },
        { text: '완료', value: 'status-complete' },
        { text: '저장', value: 'status-save' },
      ],
      pageCount: 10,
    }
  },
  mounted() {
		//this.fnSetIntervalSearchDate(this.searchDateInterval);
		this.fnSearchMsgKindCdChkAll();
		this.fnSearchMsgChChkAll();
		this.fnSearchTmpltStatusCdChkAll();
		this.fnSearch();
	},
	methods: {
    toggleMenu() {
      this.hideMenu = !this.hideMenu;
    },
    navigateToManage(data) {
      this.$router.push({ name: 'multiSendTemplateManage', params: {'tmpltCodeP': data.tmpltCode }});
    },
    openDeleteAlertModal() {
      this.$bvModal.show('alert-modal');
    },
    checkedAll(checked) {
      // selectAllChecked 상태에 따라 모든 항목을 선택 또는 해제
      this.allChecked = checked
            for (let i in this.datas) {
                this.datas[i].selected = this.allChecked;
            }
    },
    selected (e) {
            for (let i in this.datas) {
                if(! this.datas[i].selected) {
                    this.allChecked = false;
                    return;
                } else {
                    this.allChecked = true;
                }
            }
        },
        getSelected(){
          let tmpltCodes = [];
            for (let i in this.datas) {
                if(this.datas[i].selected) {
                  tmpltCodes.push(this.datas[i].tmpltCode);
                }
            }
            // return tmpltCodes;
        },

		fnSearchMsgKindCdChkAll(){
      if (this.msgKindCdSelected.includes('message-all')) {
        // '전체' 선택 시 모든 옵션 선택
        this.msgKindCdSelected = ['message-all', 'message-info', 'message-ad'];
      } else {
        // '전체'가 해제되면 '정보성'과 '광고성'도 모두 해제
        this.msgKindCdSelected = this.msgKindCdSelected.filter(
          (value) => value !== 'message-info' && value !== 'message-ad'
        );
      }

      // 선택된 값에 따른 검색 조건 설정
      this.searchData.searchMsgKindCd = this.msgKindCdSelected.includes(
        'message-all'
      )
        ? ['A', 'I']
        : this.msgKindCdSelected.includes('message-info') &&
          this.msgKindCdSelected.includes('message-ad')
        ? ['A', 'I']
        : this.msgKindCdSelected.includes('message-info')
        ? ['I']
        : this.msgKindCdSelected.includes('message-ad')
        ? ['A']
        : [];
    },
		fnSearchMsgChChkAll(){
      if (this.templateSelected.includes('template-all')) {
        // '전체' 선택 시 모든 옵션 선택
        this.templateSelected = ['template-all', 'template-message', 'template-rcs','template-kakao'];
      } else {
        // '전체'가 해제되면 '정보성'과 '광고성'도 모두 해제
        this.templateSelected = this.templateSelected.filter(
          (value) => value !== 'template-message' && value !== 'template-rcs' && value !== 'template-kakao'
        );
      }

      // 선택된 값에 따른 검색 조건 설정
      this.searchData.searchMsgCh = this.templateSelected.includes('template-all')
        ? ['SMSMMS', 'KAKAO', 'RCS']
          : (() => {
             this.searchData.searchMsgCh = [];

            if (this.templateSelected.includes('template-message')) {
              this.searchData.searchMsgCh.push('SMSMMS');
            }
            if (this.templateSelected.includes('template-rcs')) {
              this.searchData.searchMsgCh.push('RCS');
            }
            if (this.templateSelected.includes('template-kakao')) {
              this.searchData.searchMsgCh.push('KAKAO');
            }

            return this.searchData.searchMsgCh;
          })();
		},
		fnSearchTmpltStatusCdChkAll(){
      if (this.templateStatusSelected.includes('status-all')) {
        // '전체' 선택 시 모든 옵션 선택
        this.templateStatusSelected = ['status-all', 'status-complete', 'status-save'];
        this.searchData.searchTmpltStatus = ['COMPLETE', 'SAVE'];
      } else {
        // '전체' 해제 시 모든 옵션 해제
        if (this.templateStatusSelected.includes('status-complete') && 
            this.templateStatusSelected.includes('status-save')) {
          // 모든 개별 옵션이 선택되면 전체 선택
          this.searchData.searchTmpltStatus = ['COMPLETE', 'SAVE'];
        } else {
          // 전체가 해제되면 모든 옵션 해제
          this.templateStatusSelected = this.templateStatusSelected.filter(
            value => value !== 'status-all'
          );
          
          // 개별 선택에 따른 searchTmpltStatus 설정
          this.searchData.searchTmpltStatus = [];
          
          if (this.templateStatusSelected.includes('status-complete')) {
            this.searchData.searchTmpltStatus.push('COMPLETE');
          }
          if (this.templateStatusSelected.includes('status-save')) {
            this.searchData.searchTmpltStatus.push('SAVE');
          }
        }
      }

      console.log("최종 상태={}",this.searchData.searchTmpltStatus);
		},
		//검색일자변경
		fnSetIntervalSearchDate(interval){
			this.searchDateInterval = interval;
			this.searchData.searchEndDate = this.$gfnCommonUtils.getCurretDate();
			this.searchData.searchStartDate = this.$gfnCommonUtils.strDateAddDay(this.searchData.searchEndDate, -this.searchDateInterval);
		},
		fnUpdateStartDate(sltDate) {
			this.searchData.searchStartDate = sltDate;
		},
		fnUpdateEndDate(sltDate) {
			this.searchData.searchEndDate = sltDate;
		},
		//템플릿 삭제
		fnDelete(){
			//유효성 검사
			if (this.listChkBox == null || this.listChkBox.length == 0) {
				confirm.fnAlert(this.componentsTitle, '삭제할 항목을 선택해주세요.');
				return;
			}
			eventBus.$on('callbackEventBus', this.fnProcDeleteMultiSendTemplate);
			confirm.fnConfirm(this.componentsTitle, "선택한 템플릿을 삭제하시겠습니까?", "확인");
		},
		//통합 템플릿 삭제 처리
		async fnProcDeleteMultiSendTemplate(){
			var params = {'tmpltCodes' : this.listChkBox};
			await templateApi.deleteMultiSendTemplate(params).then(response =>{
				var result = response.data;
				if (result.success) {
					confirm.fnAlert(this.componentsTitle, '삭제되었습니다.');
					this.listChkBox = [];
					this.fnSearch();
				} else {
					confirm.fnAlert(this.componentsTitle, result.message);
				}
			});
		},
		//엑셀 다운로드
		fnExcelDownLoad(){
			var params = this.searchData;
			templateApi.excelDownloadMultiSendTemplate(params);
		},
		// 검색
		async fnSelectMultiSendTemplateList() {
			//유효성 검사
			if (this.searchData.searchStartDate && this.searchData.searchEndDate) {
				if (this.searchData.searchStartDate.replace(/[^0-9]/g, '') > this.searchData.searchEndDate.replace(/[^0-9]/g, '')) {
					alert('시작일은 종료일보다 클 수 없습니다.');
					return false;
				}
			}

			var params = Object.assign({}, this.searchData);
			params.pageNo = this.pageNo;
			params.listSize = this.listSize;
			params.tmpltStatus = "SAVE";
			params.loginId = tokenSvc.getToken().principal.userId;
			params.roleCd = tokenSvc.getToken().principal.roleCd

      console.log("params={}",params);

			await templateApi.selectMultiSendTemplateList(params).then(response =>{
				var result = response.data;
				if (result.success) {
					this.datas = result.data;
					this.totCnt = result.pageInfo.totCnt;
					this.offset = result.pageInfo.offset;
				} else {
					alert(result.message);
				}
			});
		},
		// 채널 jsonArray -> 채널명
		fnJsonArrayToChannelLit(json){
			const vm = this;
			let chList = JSON.parse(json);
			let chStr = '';

			chList.forEach(element => {
				chStr += (vm.$gfnCommonUtils.isEmpty(chStr) ? '' : ', ') + element;
			});

			// SMS, MMS 문자열 출력 치환
			chStr = chStr.replace('SMS' , '문자');
			chStr = chStr.replace('MMS' , '문자');

			return chStr;
		},
		// 리스트 전체 체크박스
		fnListChkAll(){
			var vm = this;
			if (this.listAllChecked) {
				this.datas.forEach(function(data) {
					vm.listChkBox.push(data.tmpltCode);
				});
			} else {
				this.listChkBox = []
			}
		},
    handlePageCountChange(count) {
    this.pageCount = count;
    this.fnSelected(count); 
  },
		// select 박스 선택시 리스트 재출력
		fnSelected(listSize) {
			this.listSize = Number(listSize);
			this.$refs.updatePaging.fnAllDecrease();
		},
		fnSearch(pageNum) {
			this.pageNo = (this.$gfnCommonUtils.defaultIfEmpty(pageNum, '1'))*1;
			this.fnSelectMultiSendTemplateList();
		}
	},
  watch: {
    // datas의 각 항목 선택 상태가 변경될 때 selectAllChecked를 업데이트
    datas: {
      handler(newDatas) {
        this.selectAllChecked = newDatas.every((data) => data.selected);
      },
      deep: true,
    },
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as typography;
@import '@/assets/scss/service/message.scss';
@import '@/assets/scss/service/template.scss';

.template-dropdown {
  min-width: 160px;
  margin: 0 16px;
}
.search-group {
  width: 355px;
  margin-right: 20px;
}
.form-group {
  margin-left: 24px;
  margin-bottom: 0;
}
/* 애니메이션 추가 */
.slide-fade-enter-active, .slide-fade-leave-active {
  transition: all 0.1s ease;
}
.slide-fade-enter, .slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.check-menu {
  display: flex;
}
.vertical-divider {
  width: 1px;
  height: 16px;
  margin: 0 20px;
  background: var(--border-color);
}
.list-view .pageCount-dropdown {
  margin-right: auto;
}
.table-responsive {
  tr {
    cursor: pointer;
  }
}
</style>
