<template>
    <section class="publicAllSection">
        <div class="publicAllwrap"  v-if=" findedLoginId == '' ">
            <div class="publicAllcontent">
                <div class="idsection">
                    <div class="idHead">e-UMS 아이디 찾기</div>
                    <div class="idinputwrap">
                        <span class="pw_email">고객사명</span>
                        <label>
                            <input type="" class="pw_input" placeholder="고객사명을 입력해주세요." v-model="findUserName" @keypress.enter="fnFindLoginId()">
                        </label>
                        <h1 class="pw_email" id="pw_number">휴대폰 번호</h1>
                        <label>
                            <input type="" class="pw_input" placeholder="휴대폰번호(-) 없이 입력해주세요." v-model="findHpNumber" @keypress.enter="fnFindLoginId">
                        </label>

                    </div>
                    <button class="submitButton" @click="fnFindLoginId">아이디 찾기</button>
                    
                </div>
            </div>
        </div>
        <div class="publicAllwrap"  v-if=" findedLoginId != '' ">
            <div class="publicAllcontent">
                <div class="idsection">
                    <div class="idHead">e-UMS 아이디 찾기</div>
                    <div class="idinputwrap">
                        <p class="text-center font-size13 mt60">입력하신 정보로 찾은 아이디입니다.</p>
                        <p class="text-center font-size13 mt5 color1">{{this.findedLoginId}}</p>
                        <p class="text-center font-size13 mt30">개인정보를 위해 아이디 앞자리는 ***로 표시됩니다.</p>
                    </div>
                    <div class="text-center mt40"><a v-bind:href="loginUrl" class="btnStyle3 font14 minWidth120">로그인</a></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import loginApi from '@/modules/login/service/api';
import confirm from "@/modules/commonUtil/service/confirm"

export default {
	data: function() {
		return {
			findUserName : "",
			findHpNumber : "",
			findedLoginId : "",
			loginUrl : "/login"
		};
	},
	watch:{
		findHpNumber(){
			this.findHpNumber = this.$gfnCommonUtils.hpNumberAddDash(this.findHpNumber);
		}
	},
	mounted() {
	},
	methods: {
		fnFindLoginId(){
			if( this.findUserName === '' ){
				confirm.fnAlert("", "고객사명을 입력해주세요.");
				return;
			} else if( this.findHpNumber === '' ){
				confirm.fnAlert("", "휴대폰 번호를 입력해주세요.");
				return;
			}

			var params = {
				"findUserName"		: this.findUserName,
				"findHpNumber"    	: this.$gfnCommonUtils.hpNumberRemoveDash(this.findHpNumber)
			}
			
			loginApi.findLoginId(params).then(response =>{
				console.log(response);
				var result = response.data;
				this.data = response.data.data;
				if( result.success ){
					this.findedLoginId = result.data;
				} else {
					confirm.fnAlert("", result.message);
				}
			});
		}
	}
};
</script>