<template>
    <div>
    <div id="shippingHistory" class="tabContent active">
        <div class="boxSt">
            <div class="firstRow">
                <div class="boxFirstRow1">
                    <div class="boxContentTitle">발송일자</div>
                    <div class="sendOption">
                        <input type="date" id="searchStartDate" class="datePickerInput">
                        <span class="searchBlank">~</span>
                        <input type="date" id="searchEndDate" class="datePickerInput">
                    </div>
                </div>

                <div class="boxFirstRow1">
                    <div class="boxContentTitle">
                        <ul class="searchDay">
                            <li><a title="당일 서비스 검색">오늘</a></li>
                            <li><a title="7일 서비스 검색">일주일</a></li>
                            <li><a title="15일 서비스 검색">15일</a>
                            </li>
                            <li><a title="1개월 서비스 검색">1개월</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <a class="backgroundNonButton" id="searchButton">검색</a>
        </div>
    </div>
    <!-- 검색 -->
    <!-- 리스트 -->
    <div id="ShippingList" class="tabContent active">
        <div class="shippingListHeader">
            <span class="totalCount">총 0건</span>
            <a href="#" class="excelDownload">엑셀다운로드</a>
        </div>
        <table class="shippingTable">
            <thead>
                <tr>
                    <th rowspan="2">발송일자</th>
                    <th colspan="4">세부항목 성공률</th>
                    <th colspan="4">요약</th>
                </tr>
                <tr>

                    <th>문자</th>
                    <th>RCS</th>
                    <th>카카오톡</th>
                    <th>푸시</th>
                    <th>성공</th>
                    <th>실패</th>
                    <th>처리중</th>
                    <th>전체</th>
                </tr>
            </thead>
            <tbody>
                <tr class="rowHeightW">
                    <td>2024-04-15</td>
                    <td>10%</td>
                    <td>15%</td>
                    <td>20%</td>
                    <td>25%</td>
                    <td>30%</td>
                    <td>35%</td>
                    <td>40%</td> 
                    <td>45%</td>
                </tr>
                
                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> 
                    <td></td>
                </tr>
                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> 
                    <td></td>
                </tr>
                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> 
                    <td></td>
                </tr>
                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> 
                    <td></td>
                </tr>

                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> 
                    <td></td>
                </tr>
                <tr class="rowHeightW">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td> 
                    <td></td>
                </tr>
            </tbody>
        </table>
        
    </div>
</div>
</template>

<script>
export default {

}
</script>