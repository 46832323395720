<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="#374151" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 11.875V11.25C10.4945 11.25 10.9778 11.1034 11.3889 10.8287C11.8 10.554 12.1205 10.1635 12.3097 9.70671C12.4989 9.24989 12.5484 8.74723 12.452 8.26228C12.3555 7.77732 12.1174 7.33187 11.7678 6.98223C11.4181 6.6326 10.9727 6.3945 10.4877 6.29804C10.0028 6.20157 9.50011 6.25108 9.04329 6.4403C8.58648 6.62952 8.19603 6.94995 7.92133 7.36108C7.64662 7.7722 7.5 8.25555 7.5 8.75" stroke="#374151" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10 15.3125C10.5178 15.3125 10.9375 14.8928 10.9375 14.375C10.9375 13.8572 10.5178 13.4375 10 13.4375C9.48223 13.4375 9.0625 13.8572 9.0625 14.375C9.0625 14.8928 9.48223 15.3125 10 15.3125Z" fill="#374151"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconQuestionLine',
    props: {
    },
  };
</script>