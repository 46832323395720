<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M3.93972 13.7697C2.55967 12.7142 1.66699 11.0337 1.66699 9.14093C1.66699 5.94572 4.21083 3.35547 7.34883 3.35547C9.97258 3.35547 12.181 5.16639 12.8343 7.62768C13.3484 7.36209 13.9299 7.21243 14.5458 7.21243C16.6378 7.21243 18.3337 8.93926 18.3337 11.0694C18.3337 12.6511 17.3987 14.0103 16.061 14.6055" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M10.833 18.3555C12.2137 18.3555 13.333 17.2362 13.333 15.8555C13.333 14.4748 12.2137 13.3555 10.833 13.3555C9.4523 13.3555 8.33301 14.4748 8.33301 15.8555C8.33301 17.2362 9.4523 18.3555 10.833 18.3555Z" fill="currentColor" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M9.26775 7.4217C8.81537 6.96929 8.19037 6.68945 7.5 6.68945C6.11929 6.68945 5 7.80874 5 9.18945C5 9.87983 5.27983 10.5048 5.73225 10.9572" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconAPIKey',
  };
</script>