<template>
  <div class="service-home-slide d-flex flex-column justify-content-between">
    <div class="d-flex align-items-center mb-2">
      <p class="title f-title1 mb-0" style="color: black !important">{{ title }}</p>
      <router-link
        to="/ac/consoleQnA"
        style="font-size: 14px; color: blue; text-decoration: underline; font-weight: 500; margin-left: 10px;"
      >
        +더보기
      </router-link>
    </div>

    <b-carousel id="home-carousel" no-wrap controls no-interval>
      <b-carousel-slide v-for="(item, index) in faqs" :key="index">
        <div class="slide-content">
          <p v-if="item.title" class="mb-2 content-title f-body1 c-gray700" style="margin-bottom: 0px !important">
            {{ item.title }}
            <span v-if="item.reply && item.reply.trim() !== ''" class="ps-2 text-primary">(답변완료)</span>
            <span v-else class="ps-2 c-states-red">(미답변)</span>
          </p>
        </div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import homeApi from '@/modules/acHome/service/api';

export default {
  name: "QnaSlide",
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      faqs: []
    };
  },
  created() {
    this.fnGetFaqList();
  },
  methods: {
    async fnGetFaqList() {
            let params = {};
            await homeApi.selectFaqList(params).then(response => {
                var result = response.data;
                if (result.success) {
                    this.faqs = result.data;
                } else {
                  alert(`Error: ${result.message}`);
                }
            });
        }
  }
};
</script>

<style scoped lang="scss">
@use "~@/assets/scss/service/base/typography" as *;

.content-description {
  @include multiline-ellipsis(1);
}
</style>
