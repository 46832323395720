import httpClient from '@/common/http-client';

const selectMemberInfo = (params) => {
    return httpClient.post('/api/myPage/selectMemberInfo', params, { headers: {"show-layer": "Yes"} });
};
const selectCodeList = (params) => {
	return httpClient.post('/api/home/selectCodeList', params, { headers: {"show-layer": "Yes"} });
};
const noticeSearch = (params) => {
	return httpClient.post('/api/home/noticeSearch', params, { headers: {"show-layer": "Yes"} });
};
const selectNoticeList = (params) => {
	return httpClient.post('/api/home/selectNoticeList', params, { headers: { "show-layer": "Yes" } });
};
const faqSearch = (params) => {
	return httpClient.post('/api/home/faqSearch', params, { headers: {"show-layer": "Yes"} });
};
const selectFaqList = (params) => {
	return httpClient.post('/api/home/selectFaqList', params, { headers: {"show-layer": "Yes"} });
};
const selectQnaList = (params) => {
	return httpClient.post('/api/myPage/selectQnaList', params, { headers: {"show-layer": "Yes"} });
};
const saveQnaInfo = (params) => {
  return httpClient.post('/api/myPage/saveQnaInfo', params, {
			headers: {
			"Content-Type": "multipart/form-data",
			"show-layer": "Yes"
    }
  });
};
const deleteQnaInfo = (params) => {
	return httpClient.post('/api/myPage/deleteQnaInfo', params, { headers: {"show-layer": "Yes"} });
};

export default {
	selectMemberInfo,
	selectCodeList,
	selectNoticeList,
	noticeSearch,
	faqSearch,
	selectFaqList,
	selectQnaList,
	saveQnaInfo,
	deleteQnaInfo
};