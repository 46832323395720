<template>
	<div>
		<AddressManage />
	</div>
</template>

<script>
import AddressManage from '../components/bc-address-manage.vue';

export default {
	components: {
		AddressManage
	}
}
</script>