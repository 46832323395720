<template>
    <div class="consoleMain" :style="mainStyles">
        <h2 class="consoleMainTitle">발송</h2>
        <MessageSendHeader></MessageSendHeader>
        <MultiSendList v-if="tabButton == 1"></MultiSendList>
    </div>
</template>

<script>
import MultiSendList from '../views/multiSendList.vue';
import MessageSendHeader from '../components/messageSendHeader.vue';
import { mapState } from 'vuex';

export default {
    components: {
        MultiSendList,
        MessageSendHeader
    },
    data() {
        return {
            tabButton: 1
        }
    },
    computed: {
    ...mapState(['isSidebarOpen']),
    mainStyles() {
      return {
        width: this.isSidebarOpen ? '82%' : '100%',
        transition: 'width 0.5s ease',
        marginLeft: this.isSidebarOpen ? '18%' : '0', // 사이드바의 특정 너비가 있다면 조정
      };
    }
  }
};
</script>