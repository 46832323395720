<template>
  <div id="publicLayout">
    <!-- pop창에서는 헤더,탑버튼,푸터 연결을 제외함 -->
    <!-- <PublicHeader v-if="$route.fullPath.indexOf('/public/popup') < 0"></PublicHeader> -->
    <!-- <GoTopButton v-if="$route.fullPath.indexOf('/public/popup') < 0"></GoTopButton> -->
    <router-view :key="$route.fullPath"></router-view>
    <!-- <PublicFooter v-if="$route.fullPath.indexOf('/public/popup') < 0"></PublicFooter> -->
    <confirm></confirm>
  </div>
</template>

<script>

import Vue from 'vue';
// import PublicHeader from "../components/PublicHeader";
// import PublicFooter from "../components/PublicFooter";
// import GoTopButton from "../components/goTopButton";
import confirm from '@/modules/commonUtil/service/confirm';
import common from '@/assets/js/common';

  Vue.use(confirm);
  Vue.use(common);

export default {
  name: "publicLayout",
  components: {
    // PublicHeader,
    // GoTopButton,
    // PublicFooter
  },
  mounted() {
    let recaptchaScript = document.createElement('script')
    // recaptchaScript.setAttribute('src', '/se2/js/user.js')
    document.getElementById('publicLayout').appendChild(recaptchaScript);
  },

};
</script>