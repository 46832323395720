<template>
    <section class="publicPageSection">
            <div class="publicAllwrap">
                <div class="publicAllcontent">
                <div class="idsection2">
                    <div class="idHead">e-UMS 회원가입</div>
                    <div class="idinputwrap">
                        <div class="joinrow2">
                            <h1 class="pw_email">이름 *</h1>
                                <input type="text" class="pw_input" id="userNm" placeholder="휴대폰 인증 후 자동 입력됩니다." v-model="userNm" disabled>
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">휴대폰 번호 *</h1>
                            <div class="half_input">
									<input type="text" id="phoneCerti" class="number_input" placeholder="휴대폰 인증 후 자동 입력됩니다." v-model="phoneCerti" disabled>
									<input type="hidden" id="gender" v-model="gender" disabled> 
									<input type="hidden" id="coInfo" v-model="coInfo" disabled>
								<button id="kmc" style="display:none;" @click="fnGetKmcCheckInfo"></button>
								<button class="joininput_button" id="kmc" @click="fnPopup">휴대폰 인증</button>
								<form name="form_chk" method="post">
									<input type="hidden" name="tr_cert" v-model="tr_cert">
									<input type="hidden" name="tr_url" v-model="tr_url">
									<input type="hidden" name="tr_ver" value = "V2">
								</form>
							</div>
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">아이디 *</h1>
                                <div class="id_input">
                                    <p>{{ loginId }}</p>
                                </div>
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">비밀번호 *</h1>
                                <input type="password" class="pw_input" placeholder="비밀번호 [대/소문자,숫자,특수문자 조합(길이8~16자리)]" v-model="password" maxlength="16" >
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">비밀번호 확인 *</h1>
                                <input type="password" class="pw_input" placeholder="비밀번호 확인" v-model="passwordChk" maxlength="16">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">사업자번호 *</h1>
                            <div class="half_input">
                                    <input type="text" class="number_input" placeholder="사업자번호 ( - 없이 입력 )" v-model="regno" @keypress.enter="fnSelCorp">

									<!-- 위에서 조회되서 선택한 사업자 번호가 있을 경우 hidden 구역에 입력됨 -->
                                    <input type="hidden" v-model="selRegno" disabled> 						
									<button class="joininput_button" @click.prevent="fnSelCorp">확인</button> 
									<!-- fnSelCorp : 핸드폰 인증, 사업자번호 유효성 검사 + 사업자 번호 고객사 리스트 조회 기능 -->
                            </div>
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">고객번호 *</h1>
                                <input type="text" class="pw_input" placeholder="고객사 신규 고객일 경우 자동 생성됩니다." v-model="custNo" disabled>
                        </div>

                        <div class="joinrow2">
							<h1 class="pw_email">고객유형 *</h1>
                            <select class="pw_input" title="고객유형선택란" v-model="custKdCd">
										<option class="select_option" value="II">개인</option>
										<option class="select_option" value="GEX">개인사업자</option>
										<option class="select_option" value="GC">법인사업자</option>
							</select>
							<input type="hidden" class="inputStyle" placeholder="고객유형" v-model="custKdCd">
                        </div>
                        
                        <div class="joinrow2">
                            <h1 class="pw_email">생년월일/법인번호 *</h1>
                                <input type="text" class="pw_input" placeholder="생년월일 또는 법인번호를 입력해주세요." v-model="custrnmNo">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">사업체명 *</h1>
                                <input type="text" class="pw_input" placeholder="사업체명을 입력해주세요." v-model="corpNm">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">대표자명 *</h1>
                                <input type="text" class="pw_input" placeholder="대표자명을 입력해주세요." v-model="ceoNm">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">업태 *</h1>
                                <input type="text" class="pw_input" placeholder="업태를 입력해주세요." v-model="busiType">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">업종 *</h1>
                                <input type="text" class="pw_input" placeholder="종목을 입력해주세요." v-model="busiClass">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">사업장 주소 *</h1>
                            <div class="half_input">
                                <input type="text" id="zipCode" class="number_input" placeholder="우편번호를 입력해주세요." v-model="zipCode">
								<input type="button" @click="kakaoPostcode()" class="joininput_button" value="주소 검색"><br>
                            </div>
                                <input type="text" id="woplaceAddress" class="pw_input" placeholder="주소를 입력해주세요." v-model="woplaceAddress">
                                <input type="text" id="woplaceAddressDetail" class="pw_input" placeholder="상세 주소를 입력해주세요." v-model="woplaceAddressDetail">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">유선번호 *</h1>
                                <input type="text" class="pw_input" placeholder="유선번호를 입력해주세요.( - 없이 입력)" v-model="wireTel">
                        </div>
                        <div class="joinrow2">
                            <h1 class="pw_email">사업자등록증 *</h1>
								<div class="half_input">   
                                    <input type="text" class="number_input" placeholder="사업자등록 1부" v-model="attachFileNm" disabled>
                                    <input type="file" id="attachCorpImg" @change="fnFileChange(0)" ref="attachFile" style="display:none;">
									<button @click="fnAttachCorpImg(0)" class="joininput_button" >파일첨부</button>
                                </div>      
						</div>	
                        
                        <div class="joinrow2">
                            <h1 class="pw_email">재직증명서 *</h1>
								<div class="half_input">
									<input type="text" class="number_input" placeholder="재직증명서 1부" v-model="attachFileNm1" disabled>
									<input type="file" id="attachCorpImg1" @change="fnFileChange(1)" ref="attachFile1" style="display:none;">						
									<button @click="fnAttachCorpImg(1)" class="joininput_button" >파일첨부</button>
								</div>					
                        </div>
                       
						<div class="joinrow2">
                            <h1 class="pw_email">영업사원</h1>
                            <div class="half_input">
                                    <input type="text" class="number_input" placeholder="영업사원을 입력해주세요." v-model="salesMan" @keypress.enter="fnSalesManPopup" @change="salesManId=''">
                                <button class="joininput_button" @click="fnSalesManPopup">조회</button> 
                            </div>
                        </div>
                    </div>
                                 
                    <button class="submitButton" @click.prevent="signUp">회원가입 완료</button>

                </div>
            </div>
            
        </div>

        <chkCorpPopup :popReset="popReset1" :dataList="dataList" :selCorp.sync="selCorp"></chkCorpPopup>
		<selSalesManPopup :popReset="popReset2" :salesMan="salesMan" :selSalesMan.sync="selSalesMan"></selSalesManPopup>
		
    </section>
</template>

<script>
import confirm from "../../commonUtil/service/confirm"
import {eventBus} from "../../../modules/commonUtil/service/eventBus";
import signUpApi from "../../signUp/service/api"
import mainApi from "../../main/service/mainApi";

import chkCorpPopup from "../components/bp-selectCorpPopup.vue"
import selSalesManPopup from "../components/bp-selectSaleMan.vue"

import axios from 'axios'

export default {
	components: {
		//modal
		chkCorpPopup,
		selSalesManPopup,
	},
	props: {
				authKey : String,
				require : true
	},
	data() {
		return {
			loginId : "god0937@naver.com",						// 아이디
			userNm : "조아라",						// 이름
			password : "",						// 비밀번호
			passwordChk : "",					// 비밀번호 체크
			phoneCerti : "01064230937",					// 휴대폰
			phoneCertiChk : true,				// 휴대폰 인증 TF
			regno : "",							// 사업자번호
			corpNm : "",						// 사업자명
			ceoNm : "",							// 대표자명	
			busiType : "",						// 업태
			busiClass : "",						// 종목

			zipCode : "",						// 우편번호
			woplaceAddress : "",				// 주소
			woplaceAddressDetail : "",			// 상세주소

			wireTel : "",						// 유선 전화번호
			attachFileNm : "",					// 사업자등록증
			attachFileNm1 : "",					// 재직증명서

			custKdCd : "",						// 고객 유형
			gender : "",						// 성별
			coInfo : "",						// ci 값 (본인인증토큰)
			custNo : '',						// 고객 번호 (핸드폰 번호 X)
			custrnmNo : '',						// 생년월일
			
			promotionYn : "Y",					// 홍보성 정보 동의 여부

			selCorp : {},						// 선택한 고객사 정보
			selAddr : {},						// 선택한 주소 정보

			selRegno : '',						// 선택한 사업자 등록번호(input hidden) -> 신규인지 아닌지 확인용
			selCorpCnt : 1,						// 사업자 번호로 조회 후 선택한 고객사의 수

			dataList : [],
			
			custTypeArr : [],					// 고객 유형 select box
			
			selSalesMan : {},					// 선택한 영업사원
			salesMan : "",
			salesManId : "",
			feeType : 'PRE',					// 초기에 선불로 등록

			// KMC 인증 관련
			tr_cert : '',
			tr_url : '',
			KMCIS_window : null,

			popReset1 : 0,				// 팝업 초기화할 num
			popReset2 : 0,				// 팝업 초기화할 num
		}
	},

	watch: {
		// 핸드폰번호 사업자번호 숫자만 입력 가능하도록 처리
		phoneCerti : function() {
			return this.phoneCerti = this.$gfnCommonUtils.hpNumberAddDash(this.phoneCerti);
		},
		regno : function() {
			return this.regno = this.regno.replace(/[^0-9]/g, '');
		},
		wireTel : function() {
			return this.wireTel = this.wireTel.replace(/[^0-9]/g, '');
		},
		selCorp() {
			this.fnSetCorpInfo();
		},
		selSalesMan() {
			this.fnSetSalesMan();
		}
	},
	mounted() {
		this.fnChkMailCertify();
		this.fnGetKmcCheck();
	},
	methods: {
		fnChkMailCertify(){
			var params = {
				authKey : this.authKey
			};
			
			//메일 발송 api
			signUpApi.certifyMailByAuthKey(params).then(response => {
				var result = response.data;
				var vm = this;
				if(result.success){
					this.loginId = result.data.email;
					this.promotionYn = result.data.promotionYn;
					this.fnGetKmcCheck();		// KMC 본인인증 인증키 조회
					this.fnGetCode();	
				}	
					//회원가입 메인으로 넘어가기전 이메일 인증 유무 코드 
					else {
						vm.$router.push({name : "chkCertifyFail"});
					}
				});
			},
		
		fnGetCode(){
			var params = {
				codeTypeCd	: "CORP_CUST_TYPE",
				useYN		: "Y"
			};
			mainApi.selectCodeList(params).then(response =>{
				var result = response.data;
				if(result.success){
					if(result.data.length > 0){
						this.custTypeArr = result.data;
					}
				}
			});
		},

		signUp () {
			// 기초 validation 처리
			var defaultVali = true;
			defaultVali = this.defaultVali();
			if(!defaultVali) return false;

			// 신규 고객사 validation
			if (this.selRegno == ""){
				var custVali = true;
				custVali = this.custVali();
				if(!custVali) return false;
			} 

			var regno =  this.$gfnCommonUtils.isEmpty(this.selRegno) ? this.regno : this.selRegno;
			
			// 사업자 번호 유효성 검사
			var regnoVali = this.regnoVali(regno);
			if(!regnoVali) return false;

			this.signUpSubmit();

			confirm.fnAlert("", "가입 신청이 완료되었습니다.");
		},

		// 기초 validation 처리
		defaultVali() {
			// 비밀번호 정책 validation
			// var reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$/;
			var numReg = /^(?=.*?[a-z])(?=.*?[0-9]).{10,16}$/;
			var engReg = /^(?=.*?[a-z])(?=.*?[A-Z]).{10,16}$/;
			var speReg = /^(?=.*?[a-z])(?=.*?[?!@#$%^&*+=-_|,.]).{10,16}$/;

			var numReg2 = /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[?!@#$%^&*+=-_|,.]).{8,16}$/;
			var engReg2 = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[?!@#$%^&*+=-_|,.]).{8,16}$/;
			var speReg2 = /^(?=.*?[a-z])(?=.*?[0-9])(?=.*?[A-Z]).{8,16}$/;

			if(this.userNm == ""){
				confirm.fnAlert("", "이름을 입력해주세요.");
				return false;

			} else if(this.password == "" || this.passwordChk == ""){
				confirm.fnAlert("", "비밀번호를 입력해주세요.");
				return false;

			} else if (!numReg.test(this.password) && !engReg.test(this.password)&& !speReg.test(this.password)
						&& !numReg2.test(this.password) && !engReg2.test(this.password) && !speReg2.test(this.password)){
							confirm.fnAlert("", "비밀번호는 대/소문자, 숫자, 특수문자 중 2가지 이상을 조합하여 10~16자리\n또는 3가지 이상을 조합하여 8~16자리로 구성해주세요.\n(소문자 필수 입력)");
				return;

			} else if (this.password != this.passwordChk) {
				confirm.fnAlert("", "비밀번호가 일치하지 않습니다.");
				this.password = "";
				this.passwordChk = "";
				return false;

			} else if (this.phoneCerti == "" || this.phoneCertiChk == false) {
				alert("담당자 휴대폰 인증을 진행해 주세요.");
				return false;

			} else if (this.regno == "") {
				confirm.fnAlert("", "사업자 등록번호를 입력해주세요.");
				return false;
			} else if (this.attachFileNm == "") {
				confirm.fnAlert("", "사업자등록증 파일을 첨부해주세요.");
				return false;
			} else if (this.attachFileNm1 == "") {
				confirm.fnAlert("", "재직증명서 파일을 첨부해주세요.");
				return false;
			} else if (this.salesManId == "" && this.salesMan != "") {
				alert("영업사원명을 수정하신 경우, 영업사원 조회 팝업에서 다시 선택해주세요.\n영업사원을 기본값으로 등록하시려면 빈칸으로 입력해주세요.");
				return false;
			} else {
				return true;
			}
		},

		// 신규 고객사 validation
		custVali(){
			if(this.custKdCd == ""){
				confirm.fnAlert("", "고객 유형을 선택해주세요.");
				return false;
			}
			if(this.custrnmNo != ""){
				if(this.custKdCd == "II" || this.custKdCd == "GEX"){
					var regex = /^(19[0-9][0-9]|20\d{2})(0[0-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
					if(!regex.test(this.custrnmNo)) {
						confirm.fnAlert("", "개인 사업자의 생년월일(YYYYMMDD) 형식으로 입력해주세요.");
						return false;
					}
				}
			}
			if (this.corpNm == "") {
				confirm.fnAlert("", "사업자명을 입력해주세요.");
				return false;	
			}
			if (this.ceoNm == "") {
				confirm.fnAlert("", "대표자명을 입력해주세요.");
				return false;
			}
			if (this.busiType == "") {
				confirm.fnAlert("", "업태를 입력해주세요.");
				return false;
			}
			if (this.busiClass == "") {
				confirm.fnAlert("", "종목을 입력해주세요.");
				return false;
			}
			if (this.zipCode == "") {
				confirm.fnAlert("", "우편번호를 입력해주세요.");
				return false;
			}
			if (this.woplaceAddress == "") {
				confirm.fnAlert("", "주소를 입력해주세요.");
				return false;
			}
			if (this.woplaceAddressDetail == "") {
				confirm.fnAlert("", "상세 주소를 입력해주세요.");
				return false;
			}
			if (this.wireTel == "") {
				confirm.fnAlert("", "유선 전화번호를 입력해주세요.");
				return false;
			}
			return true;
		},

		// 사업자 등록번호 validation
		regnoVali(value) {
			var valueMap = value.replace(/-/gi, '').split('').map(function(item) {
				return parseInt(item, 10);
			});

			if (valueMap.length === 10) {
				// var multiply = new Array(1, 3, 7, 1, 3, 7, 1, 3, 5);
				// var checkSum = 0;

				// for (var i = 0; i < multiply.length; ++i) {
				// 	checkSum += multiply[i] * valueMap[i];
				// }

				// checkSum += parseInt((multiply[8] * valueMap[8]) / 10, 10);

				// return Math.floor(valueMap[9]) === (10 - (checkSum % 10));
			} else {
				confirm.fnAlert("","사업자번호(10자리)를 정확히 입력해주세요.");
				return false;
			}
			return true;
		},

		kakaoPostcode() {
			var self = this; // 'this'를 캡처하기 위해 변수에 할당

			new daum.Postcode({
				oncomplete: function(data) {
					var addr = ''; // 주소 변수
					// 사용자가 선택한 주소 타입에 따라 해당 주소 값을 가져온다.
					if (data.userSelectedType === 'R') { // 사용자가 도로명 주소를 선택했을 경우
						addr = data.roadAddress;
					} else { // 사용자가 지번 주소를 선택했을 경우(J)
						addr = data.jibunAddress;
					}
					// 우편번호와 주소 정보를 해당 데이터에 업데이트
					self.zipCode = data.zonecode;
					self.woplaceAddress = addr;
					// 커서를 상세주소 필드로 이동한다.
					document.getElementById("woplaceAddressDetail").focus();
				}
			}).open();
		},

		async signUpSubmit() {
                var fd = new FormData();				
					fd.append('loginId', this.loginId);		// 아이디						
					fd.append('userNm', this.userNm);		// 이름				
					fd.append('password', this.password);	// 비밀번호						
					fd.append('phoneCerti', this.$gfnCommonUtils.hpNumberRemoveDash(this.phoneCerti));		// 핸드폰 번호			

					fd.append('regno', this.$gfnCommonUtils.isEmpty(this.selRegno) ? this.regno : this.selRegno);	// 사업자번호
					fd.append('corpNm', this.corpNm); // 사업자명
					fd.append('ceoNm', this.$gfnCommonUtils.isEmpty(this.ceoNm) ? this.rtnCustNm : this.ceoNm);		// 대표자명

					fd.append('busiType', this.busiType);	// 업태				
					fd.append('busiClass', this.busiClass);		// 업종		

					fd.append('zipCode', this.zipCode);			// 우편번호				
					fd.append('woplaceAddress', this.woplaceAddress);		// 주소	
					fd.append('woplaceAddressDetail', this.woplaceAddressDetail);	 // 상세주소

					fd.append('wireTel', this.wireTel);			// 유선번호			

					fd.append('attachFile', this.$refs.attachFile.files[0]);		// 첨부파일 : 사업자등록증
					fd.append('attachFile1', this.$refs.attachFile1.files[0]);		// 첨부파일 : 재직증명서

					fd.append('custKdCd', this.custKdCd);	// 	고객유형	
					fd.append('gender', this.gender);	// 성별
					fd.append('coInfo', this.coInfo);		// 본인인증토큰 				
				
					fd.append('custNo', this.custNo);		// 고객번호			

					fd.append('custrnmNo', this.custrnmNo);			// 생년월일			
							
					fd.append('promotionYn', this.promotionYn);		// 프로모션 동의
					
					fd.append('salesMan', this.salesManId);		// 영업사원					
					fd.append('feeType', this.feeType);			// 선불,후불

				
                try {
                    const response = await axios.post('/api/public/signUp/insertSignUp', fd, {
						headers: {
							'Content-Type': 'multipart/form-data'
						}
                    });
                    const result = response.data;
                    if (!result.success) {
                        confirm.fnAlert(result.message);

                    } else {
                        eventBus.$on('callbackEventBus', this.fnEmitLoginPage.bind(this));
                        const status = result.data[0];
                        if (status === 'REQ') {
                            confirm.fnAlert("가입 완료하였습니다.");
                        }
                    }
                } catch (error) {
                    confirm.fnAlert(error.message);
                }
            },

		fnEmitLoginPage(){
			this.$router.push({name : "login"});
		},

		fnSelCorp(){
			if(!this.phoneCertiChk){
				alert("휴대폰 본인인증을 완료해주세요.");
				return false;
			}

			if(this.regno == ""){
				alert("사업자번호는 필수 입력입니다.");
				return false;
			}

			// 입력한 regno(사업자번호) params에 담고 아래에서 selectCorpCustList로 고객사 리스트 검색함.
			var params = {
				regno : this.regno
			}
			
			var vm = this;

			// 백단에서 고객사 리스트 있는지 조회함
			signUpApi.selectCorpCustList(params).then(response => {
				var result = response.data;
				if(result.success){
					var cnt = result.data.length;

					// 고객사 리스트에 있는 경우
					if(cnt > 0){
						vm.popReset1 += 1;
						vm.selCorpCnt = cnt; // selCorpCnt : 선택한 고객사 수 의미 -> cnt 표시
						vm.selCorp = {}; 	// 선택한 고객사
						vm.dataList = result.data;

						jQuery("#chkCorpPopup").modal("show"); // 팝업창으로 고객사 리스트 보여줌

					// 고객사 리스트에 없는 경우
					} else {
						confirm.fnAlert("", "신규 고객사 정보를 입력해주세요.");
						vm.selCorpCnt = 0;
						vm.selCorp = {};
					}
				} else {
					if(!result.data){
						//실서버가 아닌 경우 무조건 신규처리
						confirm.fnAlert("", result.message + "\n\n실 서버가 아닌 장비에서 가입 진행중이므로 신규 고객사로 가입 진행합니다.");
						vm.selCorpCnt = 0;
						vm.selCorp = {};
					}else{
						confirm.fnAlert("", result.message);
					}
				}
			});
		},

		// selCorp(선택한 고객사) 객체의 각 속성들이 정의되어 있지않으면 빈 문자열("")을 할당한다. (아이디,핸드폰 번호 등 필수 정보 제외)
		fnSetCorpInfo(){
			this.custNo					= this.selCorp.custNo == undefined ? "" : this.selCorp.custNo;							// 고객번호
			this.custrnmNo				= this.selCorp.custrnmNo == undefined ? "" : this.selCorp.custrnmNo;					// 생년월일
			this.ceoNm					= this.selCorp.ceoName == undefined ? "" : this.selCorp.ceoName;							// 대표자 명
			this.corpNm					= this.selCorp.corpName == undefined ? "" : this.selCorp.corpName;					// 사업자명
			this.selRegno				= this.selCorp.regno == undefined ? "" : this.selCorp.regno;						// 선택한 사업자 번호(hidden)
			this.zipCode				= this.selCorp.zipcode == undefined ? "" : this.selCorp.zipcode;					// 우편번호
			this.woplaceAddress			= this.selCorp.woplaceAddress == undefined ? "" : this.selCorp.woplaceAddress;		// 주소1
			this.woplaceAddressDetail	= this.selCorp.woplaceAddressDetail == undefined ? "" : this.selCorp.woplaceAddressDetail;		// 상세주소
			this.wireTel				= this.selCorp.wireTel == undefined ? "" : this.selCorp.wireTel;						// 유선 전화번호
			this.busiType				= this.selCorp.busitype == undefined ? "" : this.selCorp.busitype;							// 업태
			this.busiClass				= this.selCorp.busiclass == undefined ? "" : this.selCorp.busiclass;						// 업종
			this.custKdCd				= this.selCorp.custKdCd == undefined ? "" : this.selCorp.custKdCd;						// 고객유형
		},

		// 본인인증 팝업
		fnPopup(){
				window.open('', 'popupChk', 'width=400, height=705, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
				document.form_chk.action = "https://www.kmcert.com/kmcis/web/kmcisReq.jsp";
				document.form_chk.target = "popupChk";
				document.form_chk.submit();
		},

		// 본인인증시 사용될 데이터 조회
		fnGetKmcCheck(){
			let tr_certVal = '';
			let tr_urlVal = '';

			signUpApi.getKmcCheck().then((response) => {
				var result = response.data;
				if(result.success){
					tr_certVal = result.data.tr_cert;
					tr_urlVal = result.data.tr_url;
					this.tr_cert = tr_certVal;
					this.tr_url = tr_urlVal;
				} else {
					confirm.fnAlert("", result.message);
				}
			});
		},

		// 본인인증 성공시 본인인증시 사용한 핸드폰 번호 입력
		fnGetKmcCheckInfo(){
			confirm.fnAlert("본인인증 성공", "본인인증에 성공하였습니다.");
			this.phoneCerti = this.$gfnCommonUtils.hpNumberAddDash(jQuery("#phoneCerti").val());
			this.gender = jQuery("#gender").val();
			this.coInfo = jQuery("#coInfo").val();
			this.userNm = jQuery("#userNm").val();
			this.phoneCertiChk = true;
		},

		// 사업자등록증 & 재직증명서 입력
		fnAttachCorpImg(num){
			if(num == 0) {
				jQuery("#attachCorpImg").click();
			} else if(num == 1) {
				jQuery("#attachCorpImg1").click();
			}
		},

		fnFileChange(num) {
				if (num === 0) {
						var attachFileNm = this.$refs.attachFile.files[0].name;
						// 확장자 valid
						var ext = attachFileNm.slice(attachFileNm.lastIndexOf(".") + 1).toLowerCase();
						if (!(ext && /^(jpg|jpeg|pdf|gif|png|tiff)$/.test(ext))) {
						confirm.fnAlert("", "필수 첨부서류들을 압축하여 등록해주세요.\n(가능 확장자 : jpg, jpeg, pdf, gif, png, tiff)");
					
						return false;
					}
					this.attachFileNm = attachFileNm;

				} else if (num === 1) {
						var attachFileNm1 = this.$refs.attachFile1.files[0].name;
						// 확장자 valid
						var ext1 = attachFileNm1.slice(attachFileNm1.lastIndexOf(".") + 1).toLowerCase();
						if (!(ext1 && /^(jpg|jpeg|pdf|gif|png|tiff)$/.test(ext1))) {
						confirm.fnAlert("", "필수 첨부서류들을 압축하여 등록해주세요.\n(가능 확장자 : jpg, jpeg, pdf, gif, png, tiff)");
					
						return false;
					}
					this.attachFileNm1 = attachFileNm1;
				}
				},

			// 영업사원 조회 팝업
			fnSalesManPopup(){
				if(this.$gfnCommonUtils.isEmpty(this.salesMan)){
					confirm.fnAlert("", "영업사원의 이름을 입력해주세요.");
				return false;
				}

				this.popReset2 += 1;
				jQuery("#saleManPopup").modal("show");
			},
			
			fnSetSalesMan(){
				this.salesManId = this.selSalesMan.codeVal1;
				this.salesMan = this.selSalesMan.codeName1;
			},
		
		}
   }

</script>