<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M3.33301 15.8548V19.1882H16.6663V2.52148H3.33301V5.85482" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 13.3555H4.16667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 10.8555H4.16667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M2.5 8.35547H4.16667" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.99967 9.60482C10.9201 9.60482 11.6663 8.85863 11.6663 7.93815C11.6663 7.01768 10.9201 6.27148 9.99967 6.27148C9.0792 6.27148 8.33301 7.01768 8.33301 7.93815C8.33301 8.85863 9.0792 9.60482 9.99967 9.60482Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3337 15.4388C13.3337 13.5978 11.8413 12.1055 10.0003 12.1055C8.15937 12.1055 6.66699 13.5978 6.66699 15.4388" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconAddress',
  };
</script>