<template>
    <div class="consoleMain" :style="mainStyles">
      <div class="noticeHead">
        공지사항
      </div>
      <div class="tagSection">
        <div class="tagComboBox">
          <select v-model="selected" @change="handleSelectionChange" class="selectBox">
              <option :value="''" :class="{ active: selected === '' }">전체</option>
              <option v-for="(noticeTypeInfo, idx) in noticeTypeList" :key="idx" :value="noticeTypeInfo.codeVal1">
                  {{ noticeTypeInfo.codeName1 }}
              </option>
          </select>
        </div>

        <div class="search_section">
          <input class="searchBox" v-model="keyword" @keyup.enter="noticeSearch" placeholder="검색">
        </div>
      </div>

      <div class="noticeSection">
        <table class="noticeTable">
          <thead>
            <tr class="noticetr">
              <th class="noticetrNum">번호</th>
              <th class="noticetrTag"></th>
                <th class="noticetrHead">제목</th>
              <th class="noticetrDate">작성일</th>
              <th class="noticetrWriter">작성자</th>
            </tr>
          </thead>
          <tbody>
            <tr 
              v-for="(contant, idx) in contants" 
              :key="idx" 
              :class="{
                notice_contenttr: true, 
                notice_contentToptr: isTopNotice(contant)
              }"
            >
              <td>{{totCnt-offset-contant.rownum+1}}</td>
              <td class="noticetrTag">
                <span :class="{'noticeTag_guide': contant.noticeType === 'INFO', 
                                'noticeTag_check': contant.noticeType === 'CHECK',
                                'noticeTag_error': contant.noticeType === 'ERROR',
                                'noticeTag_event': contant.noticeType === 'EVENT'}">
                                {{ getNoticeTypeLabel(contant.noticeType) }}</span>
              </td>
              <td class="noticetdHead">
                <router-link :to="{ name: 'consoleNoticeDetail', params: { noticeId: contant.noticeId }}">
                         {{ contant.title }}
                </router-link>
              </td>
              <td>{{contant.regDt}}</td>
              <td>운영자</td>
            </tr>
          </tbody>
        </table>

        <!-- pagination -->
        <div id="pageContent">
          <PageLayer2 @fnClick="fnSearch" :listTotalCnt="totCnt" :selected="listSize" :pageNum="pageNo" ref="updatePaging"></PageLayer2>
        </div>
        <!-- //pagination -->
      </div>
    </div>
</template>


<script>
import PageLayer2 from '@/components/PageLayer2.vue';
import customerCenterApi from "@/modules/customerCenter/service/api.js";

export default {
  name: 'notice',
  components: {
    PageLayer2,
  },

  data() {
    return {
      selected: "", // 초기 선택값을 빈 문자열로 설정
      keyword: "", // 검색어를 담을 변수
      searchResults: [], // 검색 결과를 담을 변수
      noticeTypeCd: '',
      noticeTypeList: [],
      contants: [],
      listSize: 10,  // 한페이지 출력 리스트 수
      pageNo: 1,  // 현재 페이징 위치
      totCnt: 0,  //전체 리스트 수
      offset: 0, //페이지 시작점
    };
  },

  mounted() {
    this.fnSelectNoticeType();
    this.fnSetNoticeTypeCd(this.noticeTypeCd);
    this.fnSelectNoticeList();
  },

  watch: {
    selected(newVal) {
      this.fnSetNoticeTypeCd(newVal);
    }
  },

  methods: {
    async goToDetail(noticeId) {
      // 공지사항 상세 페이지로 이동 : 글의 ID값 받아서 이동시킴
      this.$router.push({ name: 'consoleNoticeDetail', params: { noticeId: noticeId } });
    },

    // 받은 TYPE 값을 한글로 변환시켜서 RETURN
    getNoticeTypeLabel(type) {
      switch(type) {
        case 'INFO':
          return '안내';
        case 'CHECK':
          return '점검';
        case 'ERROR':
          return '장애';
        case 'EVENT':
          return '행사';
        default:
          return type;
      }
    },

    handleSelectionChange(event) {
      this.fnSetNoticeTypeCd(event.target.value);
    },

    fnSetNoticeTypeCd(noticeTypeCd) {
      this.noticeTypeCd = noticeTypeCd;
      this.$refs.updatePaging.fnAllDecrease();
      this.fnSelectNoticeList();
    },

    async noticeSearch() {
      const params = {
        keyword: this.keyword,
        pageNo: this.pageNo,
        listSize: this.listSize
      };

     await customerCenterApi.noticeSearch(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    async fnSelectNoticeList() {
      const params = {
        noticeType: this.noticeTypeCd,
        pageNo: this.pageNo,
        listSize: this.listSize
      };

      await customerCenterApi.selectNoticeList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.contants = result.data;
          this.totCnt = result.pageInfo.totCnt;
          this.offset = result.pageInfo.offset;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    fnSelectNoticeType() {
      const params = {
        codeTypeCd: "NOTICE_TYPE",
        useYN: "Y"
      };

      customerCenterApi.selectCodeList(params).then(response => {
        const result = response.data;
        if (result.success) {
          this.noticeTypeList = result.data;
        } else {
          alert(this.componentsTitle, result.message);
        }
      });
    },

    isTopNotice(contant) {
      const now = new Date();
      const startDate = new Date(contant.topStartDt);
      const endDate = new Date(contant.topEndDt);
      
      return contant.topYn === 'Y' && startDate <= now && endDate >= now;
    },

    fnSearch(pageNo) {
      this.pageNo = (this.$gfnCommonUtils.defaultIfEmpty(pageNo, '1')) * 1;
      this.fnSelectNoticeList();
    },
  }
}

</script>

<style>
tr.notice_contentToptr {
  background-color: #fffde7 !important;
}
</style>