<template>
<div class="tabContainer">
    <ul class="tabList">
        <li :class="{active: (tabButton == 1)}" @click="tabButton = 1"><a>원스텝메시지</a></li>
        <li :class="{active: (tabButton == 2)}" @click="tabButton = 2"><a>문자</a></li>
        <li :class="{active: (tabButton == 3)}" @click="tabButton = 3"><a>RCS</a></li>
        <li :class="{active: (tabButton == 4)}" @click="tabButton = 4"><a>카카오톡</a></li>
    </ul>
    <div class="tabLine"></div>
</div>    
</template>

<script>
export default{
data(){
    return {
            tabButton: 1
        }
}
}
</script>
