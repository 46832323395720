<template>
  <div class="message-tabs-wrap">
    <div class="message-tabs">
      <div :class="['tab', isActive('/uc/message/multiSendList')]">
        <router-link to="/uc/message/multiSendList">원스텝메시지</router-link>
      </div>
      <div :class="['tab', isActive('/uc/message/sendSms')]">
        <router-link to="/uc/message/sendSms">문자</router-link>
      </div>
      <div :class="['tab', isActive('/uc/rcsTemplateSend')]">
        <router-link to="/uc/rcsTemplateSend">RCS</router-link>
      </div>
      <div :class="['tab', isActive('/uc/message/sendAlimTalk')]">
        <router-link to="/uc/message/sendAlimTalk">카카오톡</router-link>
      </div>
    </div>
    <p class="breadcrumb f-body5 c-gray700">발송 > {{breadCrumbTitle}}</p>
  </div>
</template>

<script>
import '@/assets/scss/service/messageTabs.scss'

export default {
  name: "MessageTabs",
  computed: {
    breadCrumbTitle() {
      switch (this.$route.path) {
        case '/uc/message/multiSendList':
          return '원스텝메시지';
        case '/uc/message/sendSms':
          return '문자';
        case '/uc/message/smartSendMain':
          return '원스텝메시지';
        case '/uc/rcsTemplateSend':
          return 'RCS';
        case '/uc/message/sendAlimTalk':
          return '알림톡';
        case '/uc/messageStatus':
          return '발송현황';
        default:
          return ''
      }
    },
  },
  methods: {
    isActive(link) {
      return this.$route.path.startsWith(link) ? 'active' : '';
    }
  }
}
</script>