<template>
  <div>
    <sendLimitAmount />
  </div>
</template>

<script>
import sendLimitAmount from '../components/bc-sendLimit-sendLimitAmount';

export default {
  components: {
    sendLimitAmount
  }
}
</script>