<template>
<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
  <g clip-path="url(#clip0_735_226544)">
    <path d="M13.5898 7H13.1523V4.94922C13.1523 4.72267 12.9687 4.53906 12.7422 4.53906H12.332V0.847656C12.332 0.621109 12.1484 0.4375 11.9219 0.4375H1.25781C1.03127 0.4375 0.847656 0.621109 0.847656 0.847656V4.53906H0.410156C0.183609 4.53906 0 4.72267 0 4.94922V13.1523C0 13.3789 0.183609 13.5625 0.410156 13.5625H12.7422C12.9687 13.5625 13.1523 13.3789 13.1523 13.1523V11.1016H13.5898C13.8164 11.1016 14 10.918 14 10.6914V7.41016C14 7.18361 13.8164 7 13.5898 7ZM11.5117 2.85721C10.7094 2.69368 10.0759 2.06018 9.91232 1.25781H11.5117V2.85721ZM3.26736 1.25781C3.10384 2.06018 2.47034 2.69368 1.66797 2.85721V1.25781H3.26736ZM1.66797 3.68938C2.92343 3.50897 3.91913 2.51328 4.09953 1.25781H9.08015C9.26056 2.51328 10.2563 3.50897 11.5117 3.68938V4.53906H9.01639C8.8205 3.37663 7.80707 2.48828 6.58984 2.48828C5.37262 2.48828 4.35919 3.37663 4.1633 4.53906H1.66797V3.68938ZM8.17856 4.53906H5.00113C5.18367 3.83229 5.82678 3.30859 6.58984 3.30859C7.35291 3.30859 7.99602 3.83229 8.17856 4.53906ZM12.332 12.7422H0.820312V5.35938H12.332V7H9.32422C8.19341 7 7.27344 7.91997 7.27344 9.05078C7.27344 10.1816 8.19341 11.1016 9.32422 11.1016H12.332V12.7422ZM13.1797 10.2812H9.32422C8.64575 10.2812 8.09375 9.72925 8.09375 9.05078C8.09375 8.37231 8.64575 7.82031 9.32422 7.82031H13.1797V10.2812Z" fill="#374151"/>
    <path d="M10.1445 8.64062H9.32422C9.09767 8.64062 8.91406 8.82423 8.91406 9.05078C8.91406 9.27733 9.09767 9.46094 9.32422 9.46094H10.1445C10.3711 9.46094 10.5547 9.27733 10.5547 9.05078C10.5547 8.82423 10.3711 8.64062 10.1445 8.64062Z" fill="#374151"/>
  </g>
  <defs>
    <clipPath id="clip0_735_226544">
      <rect width="14" height="14" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>

<script>
  export default {
    name: 'IconWallet',
  };
</script>