import httpClient from '@/common/http-client';

/* common */
const selectValidUseChGrp = (params) => {
  return httpClient.post('/ucApi/sendMessage/selectValidUseChGrp', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectApiKey = (params) => {
  return httpClient.post('/commonApi/getApiKey', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};


/* SMS */
const saveSmsTmplt = (params) => {
  return httpClient.post('/ucApi/template/saveSmsTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const selectSmsTmpltList = (params) => {
  return httpClient.post('/ucApi/template/selectSmsTmpltList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectSmsTmpltInfo = (params) => {
  return httpClient.post('/ucApi/template/selectSmsTmpltInfo', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const deleteSmsTmplt = (params) => {
  return httpClient.post('/ucApi/template/deleteSmsTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const excelDownloadSmsTmplt = (params) => {
  return httpClient.post('/ucApi/template/excelDownloadSmsTmplt', params, { headers: {"show-layer": "Yes", "activity":"READ"}, responseType: 'arraybuffer' })
  .then((response) => fnExcelDownCallback(response));
};
const procDownloadLibraryFile = (params) => {
  return httpClient.post('/ucApi/customer/procDownloadLibraryFile', params, { headers: {"show-layer": "Yes"}, responseType: 'arraybuffer' })
  .then((response) => fnDownCallback(response));
};

/* RCS */
const corpBrandCnt = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/corpBrandCnt', params, { headers: {"show-layer": "Yes", "activity" : "READ"} });
};

const selectRcsTemplateList = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/selectRcsTemplateList', params, {headers: {"show-layer":"Yes", "activity" : "READ"}});
};
const excelDownloadRcsTmplt = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/excelDownloadRcsTmplt', params, { headers: {"show-layer": "Yes", "activity":"READ"}, responseType: 'arraybuffer' })
  .then((response) => fnExcelDownCallback(response));
};
// const rcsTemplateListRenewal = (params) => {
//   return httpClient.post('/ucApi/template/rcsTemplateListRenewal', params, {headers: {"show-layer":"Yes", "activity" : "READ"}});
// };

const rcsTemplateInit = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/rcsTemplateInit', params, {headers: {"show-layer":"Yes", "activity" : "READ"}});
};

const rcsTemplateUpdateInit = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/rcsTemplateUpdateInit', params, {headers: {"show-layer":"Yes", "activity" : "READ"}});
};

const rcsTemplateDeleteApi = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/rcsTemplateDeleteApi', params, {headers: {"show-layer":"Yes", "activity" : "SAVE"}});
};

const rcsTemplateCancelApi = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/rcsTemplateCancelApi', params, {headers: {"show-layer":"Yes", "activity" : "SAVE"}});
};

const rcsTemplateApi = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/rcsTemplateApi', params, {headers: {"show-layer":"Yes", "activity" : "READ"}});

};

const selectRcsTemplateRejectReason = (params) => {
  return httpClient.post('/ucApi/rcsTemplate/selectRcsTemplateRejectReason', params, {headers: {"show-layer":"Yes", "activity" : "READ"}});
};


/* 알림톡 */
const selectSenderKeyList = (params) => {
  return httpClient.post('/ucApi/template/selectSenderKeyList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectKkoTmpltCatGrpList = (params) => {
  return httpClient.post('/ucApi/template/selectKkoTmpltCatGrpList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectKkoTmpltCatList = (params) => {
  return httpClient.post('/ucApi/template/selectKkoTmpltCatList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const procApprvRequestKkoTmplt = (params) => {
  return httpClient.post('/ucApi/template/procApprvRequestKkoTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const procApprvRequestKkoImgTmplt = (params) => {
  return httpClient.post('/ucApi/template/procApprvRequestKkoImgTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const selectAlimTalkTmpltList = (params) => {
  return httpClient.post('/ucApi/template/selectAlimTalkTmpltList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const selectAlimTalkTmpltTransList = (params) => {
  return httpClient.post('/ucApi/template/selectAlimTalkTmpltTransList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const procDeleteRequestKkoTmplt = (params) => {
  return httpClient.post('/ucApi/template/procDeleteRequestKkoTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const procTransRequestKkoTmplt = (params) => {
  return httpClient.post('/ucApi/template/procTransRequestKkoTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const excelDownloadAlimTalkTmplt = (params) => {
  return httpClient.post('/ucApi/template/excelDownloadAlimTalkTmplt', params, { headers: {"show-layer": "Yes", "activity":"READ"}, responseType: 'arraybuffer' })
  .then((response) => fnExcelDownCallback(response));
};
const selectAlimTalkInfo = (params) => {
  return httpClient.post('/ucApi/template/selectAlimTalkInfo', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};
const procInspectRequestKkoTmplt = (params) => {
  return httpClient.post('/ucApi/template/procInspectRequestKkoTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const procUpdateRequestKkoTmplt = (params) => {
  return httpClient.post('/ucApi/template/procUpdateRequestKkoTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const procUpdateRequestKkoImgTmplt = (params) => {
  return httpClient.post('/ucApi/template/procUpdateRequestKkoImgTmplt', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};
const selectKkoTmpltRejResn = (params) => {
  return httpClient.post('/ucApi/template/selectKkoTmpltRejResn', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

/* 원스텝 */
const selectMultiSendTemplateList = (params) => {
  return httpClient.post('/multiSendTemplateApi/selectMultiSendTemplateList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const multiSendTemplateInfo = (params) => {
  return httpClient.post('/multiSendTemplateApi/selectMultiSendTemplateInfo', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const insertMultiSendTemplate = (params) => {
  return httpClient.post('/multiSendTemplateApi/insertMultiSendTemplate', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const updateMultiSendTemplate = (params) => {
  return httpClient.post('/multiSendTemplateApi/updateMultiSendTemplate', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const deleteMultiSendTemplate = (params) => {
  return httpClient.post('/multiSendTemplateApi/deleteMultiSendTemplate', params, { headers: {"show-layer": "Yes", "activity":"SAVE"} });
};

const selectBrandList = (params) => {
  return httpClient.post('/multiSendTemplateApi/selectBrandList', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const selectProjectUseChannelInfo = (params) => {
return httpClient.post('/multiSendTemplateApi/selectProjectUseChannelInfo', params, { headers: { "show-layer": "Yes", "activity":"READ" } });
};

/* RCS 팝업 */
const rcsTemplatePopInit = (params) => {
  return httpClient.post('/ucApi/rcsTemplateSend/rcsTemplatePopInit', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const rcsTemplateSearch = (params) => {
  return httpClient.post('/ucApi/rcsTemplateSend/rcsTemplateSearch', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const rcsTemplateContent = (params) => {
  return httpClient.post('/ucApi/rcsTemplateSend/rcsTemplateContent', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const rcsTemplateDetail = (params) => {
  return httpClient.post('/ucApi/rcsTemplateSend/rcsTemplateDetail', params, { headers: {"show-layer": "Yes", "activity":"READ"} });
};

const excelDownloadMultiSendTemplate = (params) => {
    return httpClient.post('/multiSendTemplateApi/excelDownloadMultiSendTemplate', params, { headers: {"show-layer": "No", "activity":"READ"}, responseType: 'arraybuffer' }).then(function(response) {
        try {
          let blob = new Blob([response.data], { type: response.headers['content-type'] })
          let fileName = getFileName(response.headers['content-disposition'])
          fileName = decodeURI(fileName)

          if (window.navigator.msSaveOrOpenBlob) { // IE 10+
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else { // not IE
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.target = '_self'
            if (fileName) link.download = fileName
            link.click()
          }
        } catch (e) {
          console.error(e)
        }
    });
}

const excelDownloadSmartTemplate = (params) => {
    return httpClient.post('/smartTemplateApi/excelDownloadSmartTemplate', params, { headers: {"show-layer": "No", "activity":"READ"}, responseType: 'arraybuffer' }).then(function(response) {
        try {
          let blob = new Blob([response.data], { type: response.headers['content-type'] })
          let fileName = getFileName(response.headers['content-disposition'])
          fileName = decodeURI(fileName)

          if (window.navigator.msSaveOrOpenBlob) { // IE 10+
            window.navigator.msSaveOrOpenBlob(blob, fileName)
          } else { // not IE
            let link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.target = '_self'
            if (fileName) link.download = fileName
            link.click()
          }
        } catch (e) {
          console.error(e)
        }
    });
}

export default {
	selectValidUseChGrp,
	selectApiKey,
	saveSmsTmplt,
	selectSmsTmpltList,
	selectSmsTmpltInfo,
	deleteSmsTmplt,
	excelDownloadSmsTmplt,
	excelDownloadMultiSendTemplate,
  excelDownloadSmartTemplate,
  procDownloadLibraryFile,
  selectRcsTemplateList,
  corpBrandCnt,
  rcsTemplateInit,
	rcsTemplateUpdateInit,
	rcsTemplateDeleteApi,
	rcsTemplateCancelApi,
	rcsTemplateApi,
  selectRcsTemplateRejectReason,
  selectSenderKeyList,
	selectKkoTmpltCatGrpList,
	selectKkoTmpltCatList,
	selectAlimTalkTmpltList,
  selectAlimTalkTmpltTransList,
	procApprvRequestKkoTmplt,
	procUpdateRequestKkoTmplt,
	procDeleteRequestKkoTmplt,
  procTransRequestKkoTmplt,
	excelDownloadAlimTalkTmplt,
	procInspectRequestKkoTmplt,
	selectAlimTalkInfo,
  procUpdateRequestKkoImgTmplt,
  procApprvRequestKkoImgTmplt,
	selectKkoTmpltRejResn,
  selectMultiSendTemplateList,
	multiSendTemplateInfo,
	insertMultiSendTemplate,
	updateMultiSendTemplate,
	deleteMultiSendTemplate,
	selectBrandList,
	selectProjectUseChannelInfo,
  rcsTemplatePopInit,
  rcsTemplateSearch,
  rcsTemplateContent,
  rcsTemplateDetail,
  excelDownloadRcsTmplt
};

function fnExcelDownCallback(response){
  try {
    let blob = new Blob([response.data], { type: response.headers['content-type'] })
    let fileName = fnGetFileName(response.headers['content-disposition'])
    fileName = decodeURI(fileName)

    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName)
    } else { // not IE
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.target = '_self'
      if (fileName) link.download = fileName
      link.click()
    }
  } catch (e) {
    console.error(e)
  }
}

function fnGetFileName (contentDisposition) {
  let fileName = contentDisposition
    .split(';')
    .filter((ele) => {
      return ele.indexOf('filename') > -1
    })
    .map((ele) => {
      return ele
        .replace(/"/g, '')
        .split('=')[1]
    })
  return fileName[0] ? fileName[0] : null
}

function getFileName (contentDisposition) {
    let fileName = contentDisposition
      .split(';')
      .filter((ele) => {
        return ele.indexOf('filename') > -1
      })
      .map((ele) => {
        return ele
          .replace(/"/g, '')
          .split('=')[1]
      })
    return fileName[0] ? fileName[0] : null
}

function fnDownCallback(response){
  try {
    let blob = new Blob([response.data], { type: response.headers['content-type'] });
    let fileName = fnGetFileName(response.headers['content-disposition']);
    fileName = decodeURI(fileName);

    if (window.navigator.msSaveOrOpenBlob) { // IE 10+
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else { // not IE
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.target = '_self';
      if (fileName) link.download = fileName;
      link.click();
    }
  } catch (e) {
    console.error(e);
  }
}
