<template>
  <div>
    <BpMessageStatusDetail />
  </div>
</template>

<script>
import BpMessageStatusDetail from '../components/bp-message-status-detail.vue';

export default {
  components: {
    BpMessageStatusDetail
  }
}
</script>