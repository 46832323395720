<template>
  <div>
    <BcMoReceptionStatus />
  </div>
</template>

<script>
import BcMoReceptionStatus from '../components/bc-mo-reception-status.vue';

export default {
  components: {
    BcMoReceptionStatus
  }
}
</script>