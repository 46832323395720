<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <path d="M6.99815 4.33008C7.28875 4.33008 7.55651 4.48769 7.69759 4.74178L8.67615 6.50451C8.80427 6.73531 8.81031 7.01447 8.69223 7.25059L7.74951 9.13607C7.74951 9.13607 8.02271 10.5406 9.16607 11.684C10.3095 12.8273 11.7093 13.0958 11.7093 13.0958L13.5945 12.1533C13.8308 12.0351 14.1101 12.0412 14.341 12.1696L16.1087 13.1524C16.3626 13.2935 16.52 13.5611 16.52 13.8516V15.881C16.52 16.9145 15.56 17.6609 14.5808 17.3305C12.5696 16.6519 9.44775 15.3598 7.46903 13.381C5.49031 11.4023 4.1982 8.28043 3.51959 6.26927C3.18918 5.29003 3.93562 4.33008 4.96907 4.33008H6.99815Z" stroke="currentColor" stroke-width="1.5" stroke-linejoin="round"/>
    <path d="M16.0943 4.57031L12.7002 7.96444" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12.7002 4.57031L16.0943 7.96441" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
  export default {
    name: 'IconAlarm',
  };
</script>