<template>
    <div>
        <acQna />
    </div>
</template>
<script>
import acQna from '../components/bc-acQna.vue'
export default {
    components : {
        acQna
    },
    data(){
        return {
            
        }
    }
}
</script>