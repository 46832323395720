<template>
	<div>	
		<provisionViewService :popReset="popReset" />
		<provisionViewPri :popReset="popReset" />

		<section class="publicPageSection">
			<div class="publicAllwrap">
				<div class="publicAllcontent">
					<div class="idsection2">
						<div class="idHead">e-UMS 회원가입</div>
						<div class="idinputwrap">
							<span class="pw_email">이메일</span>
							<label>
								<input type="text" id="email" class="pw_input" placeholder="이메일 주소를 입력해주세요." v-model="email">
							</label>
							<div class="pw_comment">
								<span>이메일 인증 유효 시간은&nbsp;<span class="point_blue">24시간</span>&nbsp;입니다.</span>
								<span>남은 인증 유효 시간&nbsp;<span class="point_red">{{ remainingTime }}</span></span>
							</div>
						</div>
						<div class="idinputwrap">
							<div class="joinContent">
								<div class="joinrow">
									<label for="agree0">
										<input type="checkbox" id="agree0" value="전체 동의" v-model="agree0" @change="checkAll(agree0)">
										<p class="check_all">전체 이용약관에 동의합니다.</p>
									</label>
								</div>
								<div class="join_hr"></div>
								<div class="joinrow">
									<label for="agree1">
										<input type="checkbox" id="agree1" value="서비스 이용약관 동의" v-model="agree1">
										<div class="pointBlue">[필수]</div>&nbsp;서비스 이용약관 동의에 동의합니다.
									</label>
									<a href="#self" data-toggle="modal" @click.prevent="svcPopView">
										<div class="regul_show">약관보기</div>
									</a>
								</div>
								<div class="joinrow">
									<label for="agree2">
										<input type="checkbox" id="agree2" value="개인정보수집 및 이용동의" v-model="agree2">
										<div class="pointBlue">[필수]</div>&nbsp;개인정보 수집, 이용에 동의합니다.
									</label>    
									<a href="#self" data-toggle="modal" @click.prevent="priPopView">
										<div class="regul_show">
											약관보기
										</div>
									</a>
								</div>
								<div class="joinrow">
									<label for="agree3">
										<input type="checkbox" id="agree3" value="홍보성 정보 수신 동의" v-model="agree3">
										[선택]&nbsp;홍보성 정보 수신에 동의합니다.
									</label>
									<a href="#self" data-toggle="modal" @click.prevent="priPopView">
										<div class="regul_show2">
											약관보기
										</div>
									</a>
								</div>
							</div>    
						</div>
						<button type="submit" class="submitButton" @click.prevent="emailSendAndRestartTimer">인증 메일 발송</button>
						<div class="mail_repeat">
							메일을 못 받으셨나요?&nbsp;&nbsp;&nbsp;
							<span class="point_boldblue" @click.prevent="emailSendAndRestartTimer">메일 재발송 클릭</span>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import confirm from "@/modules/commonUtil/service/confirm";
import signUpApi from "@/modules/signUp/service/api"
import provisionViewPri from "./provisionViewService.vue";
import provisionViewService from "./provisionViewPri.vue";

export default {
	components: {
		provisionViewService,
		provisionViewPri
	},

	data() {
		return {
			agree0: false,
			agree1: false,
			agree2: false,
			agree3: false,
			email: "",
			popReset: 0,

			// 메일 발송 버튼 타이머
			timer: null,
			remainingTime: '23:59:59'
		}
	},

	methods: {
		emailSend() {
			// 약관 동의 validation 처리 확인
			let termsVali = this.termsValidation();
						if(!termsVali) return false;

			// 이메일 입력 처리 확인
			let emailVali = this.emailValidation();
			if(!emailVali) return false;

			this.insertEmailCertify();
		},

		// 메일 발송 타이머
		startTimer() {
						this.timer = setInterval(() => {
							let timeArray = this.remainingTime.split(':').map(Number);
							let totalSeconds = timeArray[0] * 3600 + timeArray[1] * 60 + timeArray[2];
							totalSeconds--;

							let hours = Math.floor(totalSeconds / 3600);
							let minutes = Math.floor((totalSeconds % 3600) / 60);
							let seconds = totalSeconds % 60;

							this.remainingTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

							if (totalSeconds <= 0) {
							clearInterval(this.timer);
							this.remainingTime = '00:00:00';
							}
						}, 1000); // 1초마다 감소
					},

		emailSendAndRestartTimer() {
				this.emailSend(); // 기존 이메일 전송 기능 실행
				if (!this.timer) {
						this.startTimer(); // 타이머가 없을 때만 시작
					} else {
						clearInterval(this.timer); // 타이머가 이미 실행 중이면 중지
						this.remainingTime = '23:59:59'; // 남은 시간 초기화
						this.startTimer(); // 타이머 재시작
					}
			},

		termsValidation() {
			if(!this.agree1){
				confirm.fnAlert("", "서비스 이용약관 동의는 필수입니다.");
				return false;
			} else if(!this.agree2) {
				confirm.fnAlert("", "개인정보수집 및 이용동의는 필수입니다.");
				return false;
			} else {
				return true;
			}
		},

		emailValidation() {
			var email = this.email;
			
			if(this.$gfnCommonUtils.isEmpty(email)){
				confirm.fnAlert("", "인증메일 발송을 위한 email을 입력해 주세요.");
				return false;
			} else {
				var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
				if(!regExp.test(this.email)) {
					confirm.fnAlert("", '입력한 이메일 형식이 올바르지 않습니다.');
					return false;
				}
				return true;
			}
		},

		checkAll(check) {
			if (check) {
				this.agree1 = true;
				this.agree2 = true;
				this.agree3 = true;
			} else {
				this.agree1 = false;
				this.agree2 = false;
				this.agree3 = false;
			}
		},

		insertEmailCertify() {
			var promotionYn = "N";
			if(this.agree3){
				promotionYn = "Y";
			}
			
			var params = {
				"email" : this.email,
				promotionYn : promotionYn
			}

			signUpApi.insertEmailCertify(params).then(function(response) { 
				var result = response.data.success;		  
				if(result) {
					confirm.fnAlert("", "메일이 발송되었습니다.");
				} else {
					var message = response.data.message;
					confirm.fnAlert("", message);
				}
			});
		},


		svcPopView() {
			this.popReset++;
			jQuery("#provisionView1").modal('show');
		},

		priPopView() {
			var vm = this;
			var params = {
				"srcTermsCd": "PRI"
			}
			signUpApi.selectUseTerms(params).then(function(response) {
				var result = response.data;
				if (result.success) {
					var resultData = result.data;
					vm.contents = resultData.useTerms.termsContent;
					vm.$nextTick(() => {
						jQuery("#provisionView2").modal('show');
					});
				}
			});
		},

		mounted() {
				this.startTimer();
			},
	}
}
</script>
